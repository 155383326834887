import React, { Fragment, useContext, useState, useEffect, } from 'react';
import { MyDatePicker } from "../../../components/MyDatePicker";
import { MyAutocomplete } from "../../../components/MyAutocomplete";
import { NuevasTareasMain } from "../../NuevasTareas/Main"
import { makeStyles } from '@material-ui/core/styles';
import { MyPaper } from "../../../components/MyPaper";


import { Export } from './styles';
import { MyDataTable } from '../../../components/MyDataTable';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Divider,
  Tooltip,
} from '@material-ui/core';

import { MySelect } from "../../../components/MySelect";
import swal from 'sweetalert';

import { UserContext } from '../../../contexts/UserContext';
import { useFetch } from '../../../hooks/useFetch';
import { useInputValue } from '../../../hooks/useInputValue';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import moment from "moment";
import { Loading } from '../../../components/Loading/index';
import { navigate } from '@reach/router';


function getModalStyle() {

  return {
    top: `${15}%`,
    left: `${15}%`,
  };
}
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const useInitial = (getData, setAllData, setTableData, setMotivos, user, complementSource) => {
  const [title, setTitle] = useState(user.value.role === 3 ? "Reporte Usuarios Asignados a Mi" : "Reporte Papeletas");
  const [empleados, setEmpleados] = useState([]);
  useEffect(() => {
    (async function () {
      const [empleados, _data, selects] = await Promise.all([
        user.value.role === 3 ? getData({ source: "/hijos", bg: true }) : getData({ source: "/employees", bg: true }),
        getData({ source: "/desplazamientos" + complementSource, bg: true }),
        getData({ source: "/desplazamientos/selects", bg: false }),
      ]);
      if (empleados) {
        let empleadosAux = [];
        let data = []
        user.value.role === 3 ? data = empleados.data : data = empleados.data.empleados
        data.map((empleado) => {
          let aux = {
            value: user.value.role === 1 ? empleado.value : empleado.id,
            label: user.value.role === 1 ? empleado.label : empleado.apellido_paterno + " " + empleado.apellido_materno + " " + empleado.nombres,
            cargo: empleado.cargo,
            area_equipo: empleado.area_equipo,
          }
          empleadosAux.push(aux)
        })
        setEmpleados(empleadosAux);
      }
      if (_data) {
        setAllData(_data.data);
        setTableData(_data.data);
      }
      if (selects.status === 200) {
        setMotivos(selects.data.motivosSalida);
      }

    })();
  }, [getData]);
  return {
    title,
    empleados,
  };
};


export const DesplazamientoAssigned = () => {
  const fechaAfter = useInputValue(moment().subtract(30, 'days'));
  const fechaBefore = useInputValue(moment(Date()));
  const before = moment(Date()).format("DD-MM-YYYY")
  const after = moment().subtract(30, 'days').format("DD-MM-YYYY")
  const complement = '/all?per_page=1999'.concat('&filter[starts_after]=', after, '&filter[starts_before]=', before);
  const [complementSource, setComplementSource] = useState(complement);
  const [dataModal, setDataModal] = useState(undefined);
  const [type, setType] = useState('');

  const [open, setOpen] = useState(false);
  const { BASE_URL, user } = useContext(UserContext);
  const { loading, getData } = useFetch();
  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [reload, setReload] = useState('null');
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(undefined);
  const [estados, setEstados] = useState([
      { id: 0, name: 'PENDIENTE' },
      { id: 1, name: 'APROBADO' },
      { id: 2, name: 'RECHAZADO' },
      { id: 3, name: 'ANULADO' },
      { id: 4, name: 'CERRADO' }
    ]);
  const [motivos, setMotivos] = useState([]);
  const [empleado, setEmpleado] = useState('');


  const empleadoSelected = useInputValue("");
  const estadoSelected = useInputValue("");
  const motivoSelected = useInputValue("");
  
  
  const handleModal = (data, type) => {
    setType(type)
    setDataModal(data);
    setOpen(true);
  };
  const {
    title,
    empleados
  } = useInitial(getData, setAllData, setTableData, setMotivos, user, complementSource);


  useEffect(() => {
    if (motivoSelected.value != '') {
      console.log(motivoSelected, motivos)

    }
  }, [motivoSelected]);

  useEffect(() => {
    if (estadoSelected.value != '') {
      console.log(estadoSelected, estados)

    }
  }, [estadoSelected]);

  useEffect(() => {
    if (empleado.value) {
      setComplementSource('');
      let id = ''
      if (empleado && empleado.value != '') {
        const empleado_id = '&filter[empleado]='.concat(empleado.value)
        id = empleado_id
      }

      let fechaAfter_starts = ''
      let fechaBefore_starts = ''
      if (fechaAfter.value && fechaAfter.value) {
        fechaAfter_starts = '&filter[starts_after]=' + moment(fechaAfter.value).format("YYYY-MM-DD");
        if (fechaBefore.value) {
          fechaBefore_starts = '&filter[starts_before]=' + moment(fechaBefore.value).format("YYYY-MM-DD");
        }
      }
      const complement = '/all?per_page=1999' + id + fechaAfter_starts + fechaBefore_starts
      setComplementSource(complement)

    }
  }, [empleado]);

  useEffect(() => {
    if (fechaBefore.value) {
      fechaBefore.setError("");
      if (fechaAfter.value) {
        const start = moment(fechaAfter.value);
        const end = moment(fechaBefore.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaBefore.setError(
            "La fecha hasta no puede ser menor que de inicio"
          );
          fechaBefore.setValue(null);
        }
      }
    }

  }, [fechaBefore.value]);

  useEffect(() => {
    if (fechaAfter.value) {
      fechaAfter.setError("");
      if (fechaBefore.value) {
        const start = moment(fechaAfter.value);
        const end = moment(fechaBefore.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaAfter.setError(
            "La fecha hasta no puede ser menor que de inicio"
          );
          fechaAfter.setValue(null);
        }
      }
    }

  }, [fechaBefore.value]);


  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Numero',
      options: {
        filter: false,
      },
    },
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'motivo_salida',
      label: 'Motivo de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'asunto',
      label: 'Asunto',
      options: {
        filter: true,
      },
    },
    {
      name: 'detalle_motivo',
      label: 'Detalle de Motivo',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_salida',
      label: 'Fecha de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_salida',
      label: 'Hora de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_retorno',
      label: 'Fecha de Retorno',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_retorno',
      label: 'Hora de Retorno',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_reserva',
      label: 'Fecha de Reserva',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_llegada_ie',
      label: 'Hora de Llegada (IE)',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_salida_ie',
      label: 'Hora de Salida (IE)',
      options: {
        filter: true,
      },
    },
    {
      name: 'ficha_sintomatologica_url',
      label: 'Ficha',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: true,
      },
    },

  ];



  const getFilter = () => {
    let filterTable = allData
    if (empleado.value.length > 0) {
      filterTable = filterTable.filter(item => item.empleado == empleado.value)
    }
    if (motivoSelected.value.length > 0) {
      filterTable = filterTable.filter(item => item.motivo_salida == motivoSelected.value)
    }
    if (estadoSelected.value.length > 0) {
      filterTable = filterTable.filter(item => item.estado == estadoSelected.value)
    }
    setTableData(filterTable)

  }

  const handleSubmit = async () => {
    setComplementSource('');
    let motivo = ''
    if (motivoSelected.value != '') {
      motivo = '&filter[motivo_salida]='.concat(motivoSelected.value)
    }
    let estado = ''
    if (estadoSelected.value != '') {
      estado = '&filter[estado]='.concat(estados[estadoSelected.value].name)
    }
    let id = ''
    if (empleado && empleado.value != '') {
      const empleado_id = '&filter[empleado]='.concat(empleado.value)
      id = empleado_id
    }
    let fechaAfter_starts = ''
    let fechaBefore_starts = ''
    if (fechaAfter.value) {
      fechaAfter_starts = '&filter[starts_after]=' + moment(fechaAfter.value).format("YYYY-MM-DD");
      if (fechaBefore.value) {
        fechaBefore_starts = '&filter[starts_before]=' + moment(fechaBefore.value).format("YYYY-MM-DD");
      }
    }
    
    const complement = '/all?per_page=1999' + id + motivo + estado + fechaAfter_starts + fechaBefore_starts
    setComplementSource(complement)
    const response = await getData({ source: "/desplazamientos" + complement, bg: false })
    if (response) {
      setAllData(response.data);
      setTableData(response.data);
    }
  }


  function RenderList(props) {
    return (
      <MyDataTable
        title='Papeletas Personal a Cargo'
        data={tableData}
        localData={true}
        source='/desplazamientos'
        complementSource=''
        customColumns={customColumns}
        pdf={true}
        document='desplazamiento'
        canRegister={false}
        canApproval={true}
        canRefuse={true}
        showModal={true}
        canDelete={props.canDelete}
        canNull={props.canNull}
        handleModal={handleModal}
        reload={reload}
        multipleActions={true}
        selectableRows='multiple'
        multipleActions={true}
      />
    )
  }
  if (loading) {
    return (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }
  return (
    <Fragment>
      <form noValidate>
        <Grid container item justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <MyAutocomplete
              name="empleado"
              label="Empleado"
              data={empleado}
              setData={setEmpleado}
              options={empleados}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MySelect
              name="motivoSelected"
              label="Motivo"
              controller={motivoSelected}
              data={motivos}
              disabled={loading}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <MySelect
              name="stateSelected"
              label="Estado"
              controller={estadoSelected}
              data={estados}
              disabled={loading}
            />
          </Grid>
          
          <Grid item xs={12} sm={3}>
            <MyDatePicker
              name="fechaAfter"
              label="Desde"
              controller={fechaAfter}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MyDatePicker
              name="fechaBefore"
              label="Hasta"
              controller={fechaBefore}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              size="medium"
              disabled={loading}> Buscar </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title='Recargar Página'>
              <Button
                onClick={() => {
                  window.location.reload(true)//history.push("/desplazamientos/assigned")}}
                }}
                fullWidth
                variant="contained"
                color="primary"
                size="medium"
                disabled={loading}> <RotateLeftIcon /> </Button>
            </Tooltip>

          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title="Exportar">
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                size="medium"
                href={`${BASE_URL}/api/desplazamientos/export/${user ? user.value.empleado : ''}`}
                disabled={loading}> <GetAppIcon /> </Button>
            </Tooltip>

          </Grid>
          {error && (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          )}
        </Grid>

      </form>

      <RenderList
        title='Papeletas Personal a Cargo'
        data={tableData}
        localData={true}
        source='/desplazamientos'
        complementSource=''
        customColumns={customColumns}
        pdf={true}
        document='desplazamiento'
        canRegister={false}
        canApproval={true}
        canRefuse={true}
        showModal={true}
        canDelete={user.value.role!=null?true:false }
        canNull={user.value.role!=null?true:false }
        handleModal={handleModal}
        reload={reload}
        multipleActions={true}
        selectableRows='multiple'
        multipleActions={true}
      
      />
      <Modal open={open} setOpen={setOpen} data={dataModal} type={type} />
    </Fragment>
  );
};




const Modal = ({ open, setOpen, data, type }) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const { BASE_URL } = useContext(UserContext);
  const handleClose = () => {
    setOpen(false);
  };
  if (type == 'Data') {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Empleado"
            disabled={true}
            value={data && (data.empleado || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Cargo"
            disabled={true}
            value={data && (data.cargo || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Unidad/Area/Equipo"
            disabled={true}
            value={data && (data.area_equipo || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Motivo de Salida"
            disabled={true}
            value={data && (data.motivo_salida || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Asunto"
            disabled={true}
            value={data && (data.asunto || "")}
            fullWidth
          />
          {data && data.motivo_comision && (
            <TextField
              margin="dense"
              label="Motivo Comision"
              disabled={true}
              value={data && (data.motivo_comision || "")}
              fullWidth
            />
          )}
          {data && data.institucion_educativa && (
            <TextField
              margin="dense"
              label="Institucion Educativa"
              disabled={true}
              value={data && (data.institucion_educativa || "")}
              fullWidth
            />
          )}
          <TextField
            margin="dense"
            label="Detalle de Motivo"
            disabled={true}
            value={data && (data.detalle_motivo || "")}
            fullWidth
            multiline={true}
            rows={2}
          />
          <TextField
            margin="dense"
            label="Fecha de Salida"
            disabled={true}
            value={data && (data.fecha_salida || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Fecha de Retorno"
            disabled={true}
            value={data && (data.fecha_retorno || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Estado"
            disabled={true}
            value={data && (data.estado || "")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Observacion"
            multiline={true}
            rows={4}
            disabled={true}
            value={data && (data.observacion || "")}
            fullWidth
          />
          {data && data.documento && (
            <div>
              <a
                href={BASE_URL + "/app/" + data.documento}
                target="_blank"
                style={{ color: "blue", fontSize: "16px" }}
              >
                Documento
              </a>
            </div>
          )}
          {data && data.justificativo && (
            <div>
              <a
                href={BASE_URL + "/app/" + data.justificativo}
                target="_blank"
                style={{ color: "blue", fontSize: "16px" }}
              >
                Justificativo
              </a>
            </div>
          )}
          {data && data.foto && (
            <div>
              <a
                href={BASE_URL + "/app/" + data.foto}
                target="_blank"
                style={{ color: "blue", fontSize: "16px" }}
              >
                Foto
              </a>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
      >
          <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
          <DialogContent>
            <NuevasTareasMain
              asignacion={data}
            ></NuevasTareasMain>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
          </Button>
          </DialogActions>
      </Dialog>
    )
  }

};


