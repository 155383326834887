import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

    html {
        font-size: 14px !important;
        font-family: 'Roboto', sans-serif;
    }

    body {
        background-color: rgba(0,0,0,0.05) !important;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .profile {
        color: white !important;
        background: rgba(255,255,255,.1) !important;
    }

`;
