import styled from 'styled-components'

export const StyledImage = styled.img`
    width: 100%;
    height: 400px;
    object-fit: contain;
`

export const StyledDiv = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: crimson;

    && p {
        color: white;
        font-size: 20px;
    }
`

export const StyledFile = styled.div`
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 3px;
    margin-top: 17px;
    position: relative;
    
    && label {
        position: absolute;
        top: -10px;
        left: 15px;
        background-color: white;
        padding: 0px 5px;
    }

    && input {
        width: 100%;
        padding: 15px 10px;
    }
`