import React, { Fragment, useContext } from 'react';
import { Link } from '@reach/router';

import { Export } from './styles';
import { MyDataTable } from '../../../components/MyDataTable';
import { Grid, Box } from '@material-ui/core';
import { MyButtton } from '../../../components/MyButton';

import { UserContext } from '../../../contexts/UserContext';

export const TareasMain = () => {
  const { BASE_URL, user } = useContext(UserContext);

  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'accion',
      label: 'Acción',
      options: {
        filter: true,
      },
    },
    {
      name: 'fec_inicio',
      label: 'Fecha de Inicio',
      options: {
        filter: true,
      },
    },
    {
      name: 'fec_fin',
      label: 'Fecha Final',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'jefe',
      label: 'Encargado',
      options: {
        filter: true,
      },
    },
    {
      name: 'descripcion',
      label: 'Descripción',
      options: {
        filter: true,
      },
    },
    {
      name: 'observaciones',
      label: 'Actividades',
      options: {
        filter: true,
      },
    },
    // {
    //   name: 'created',
    //   label: 'Creado',
    //   options: {
    //     filter: true,
    //   },
    // },
  ];

  return (
    <Fragment>

    <Box>
      <Grid container spacing={2} justify='space-between'>
        <Grid item xs={12} md={3}>
          <Link to='/tareas/register'>
            <MyButtton title='Crear' />
          </Link>
        </Grid>
      </Grid>
    </Box>

      <MyDataTable
        title='Tareas'
        source='/tareas'
        customColumns={customColumns}
        canRegister={false}
      />
    </Fragment>
  );
};
