import React, { useState, Fragment, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";

import { MyDataTable } from "../../../components/MyDataTable";
import { UserContext } from "../../../contexts/UserContext";

export const SolicitudMain = () => {
  const [dataModal, setDataModal] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleModal = (data) => {
    setOpen(true);
    setDataModal(data);
  };

  const customColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "numero",
      label: "Numero",
      options: {
        filter: true,
      },
    },
    {
      name: "empleado",
      label: "Empleado",
      options: {
        filter: true,
      },
    },
    {
      name: "motivo_salida",
      label: "Motivo de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "detalle_motivo",
      label: "Detalles del Motivo",
      options: {
        filter: true,
      },
    },
    {
      name: "fecha_salida",
      label: "Fecha de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "hora_salida",
      label: "Hora de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "fecha_retorno",
      label: "Fecha de Retorno",
      options: {
        filter: true,
      },
    },
    {
      name: "hora_retorno",
      label: "Hora de Retorno",
      options: {
        filter: true,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
      },
    },
  ];

  return (
    <Fragment>
      <MyDataTable
        title="Jefe - Solicitudes"
        source="/solicitudes"
        customColumns={customColumns}
        canRegister={false}
        canDelete={false}
        canApproval={true}
        canRefuse={true}
        showModal={true}
        handleModal={handleModal}
        multipleActions= {true}
        selectableRows = 'multiple'
      />

      <Modal open={open} setOpen={setOpen} data={dataModal} />
    </Fragment>
  );
};

const Modal = ({ open, setOpen, data }) => {
  const { BASE_URL } = useContext(UserContext);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Empleado"
          disabled={true}
          value={data && (data.empleado || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Cargo"
          disabled={true}
          value={data && (data.cargo || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Unidad/Area/Equipo"
          disabled={true}
          value={data && (data.area_equipo || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Motivo de Salida"
          disabled={true}
          value={data && (data.motivo_salida || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Asunto"
          disabled={true}
          value={data && (data.asunto || "")}
          fullWidth
        />
        {data && data.motivo_comision && (
          <TextField
            margin="dense"
            label="Motivo Comision"
            disabled={true}
            value={data && (data.motivo_comision || "")}
            fullWidth
          />
        )}
        {data && data.institucion_educativa && (
          <TextField
            margin="dense"
            label="Institucion Educativa"
            disabled={true}
            value={data && (data.institucion_educativa || "")}
            fullWidth
          />
        )}
        <TextField
          margin="dense"
          label="Detalle de Motivo"
          disabled={true}
          value={data && (data.detalle_motivo || "")}
          fullWidth
          multiline={true}
          rows={2}
        />
        <TextField
          margin="dense"
          label="Fecha de Salida"
          disabled={true}
          value={data && (data.fecha_salida || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Fecha de Retorno"
          disabled={true}
          value={data && (data.fecha_retorno || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Estado"
          disabled={true}
          value={data && (data.estado || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Observacion"
          multiline={true}
          rows={4}
          disabled={true}
          value={data && (data.observacion || "")}
          fullWidth
        />
        {data && data.documento && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.documento}
              target="_blank"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Documento
            </a>
          </div>
        )}
        {data && data.justificativo && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.justificativo}
              target="_blank"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Justificativo
            </a>
          </div>
        )}
        {data && data.foto && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.foto}
              target="_blank"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Foto
            </a>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
