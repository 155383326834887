import React, { useState, Fragment, useContext } from 'react';

import { Export } from './styles';
import { MyDataTable } from '../../../components/MyDataTable';
import { Grid, Box } from '@material-ui/core';

import { UserContext } from '../../../contexts/UserContext';
import moment from "moment";
import { useInputValue } from '../../../hooks/useInputValue';

export const DesplazamientoMain = () => {
  const fechaAfter = useInputValue(moment(Date()).format("DD-MM-YYYY"));
  const fechaBefore = useInputValue(moment().add(30, 'days').format("DD-MM-YYYY"));
  // const complement = '/all?per_page=1999'.concat('&starts_after=', fechaAfter.value, '&starts_before=', fechaBefore.value)
  const complement = ''
  const [complementSource, setComplementSource] = useState(complement)
  const { BASE_URL, user } = useContext(UserContext);
  console.log(user)
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Numero',
      options: {
        filter: true,
      },
    },
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'motivo_salida',
      label: 'Motivo de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'asunto',
      label: 'Asunto',
      options: {
        filter: true,
      },
    },
    {
      name: 'detalle_motivo',
      label: 'Detalle de Motivo',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_salida',
      label: 'Fecha de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_salida',
      label: 'Hora de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_retorno',
      label: 'Fecha de Retorno',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_retorno',
      label: 'Hora de Retorno',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_reserva',
      label: 'Fecha de Reserva',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_llegada_ie',
      label: 'Hora de Llegada (IE)',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_salida_ie',
      label: 'Hora de Salida (IE)',
      options: {
        filter: true,
      },
    },
    {
      name: 'ficha_sintomatologica_url',
      label: 'Ficha',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <Fragment>
      <Box mb={2}>
        <Grid container item spacing={2} justify="flex-end">
          <Grid item xs={12} md={3}>
            <Export href={`${BASE_URL}/api/desplazamientos/export/${user ? user.value.empleado : ''}`}>EXPORTAR COMO EXCEL</Export>
          </Grid>
        </Grid>
      </Box>

      <MyDataTable
        title='Mis Papeletas'
        source='/desplazamientos'
        customColumns={customColumns}
        complementSource=''
        pdf={true}
        document='desplazamiento'
        canRegister={false}
        multipleActions = {user.value.role===1?true:false}
        selectableRows = {user.value.role===1?'multiple':'none'}
      />
    </Fragment>
  );
};
