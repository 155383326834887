import React from 'react';

import { MyDataTable } from '../../../components/MyDataTable';

export const ReporteMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Numero',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'motivo_salida',
      label: 'Motivo de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_salida',
      label: 'Fecha de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_salida',
      label: 'Hora de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_retorno',
      label: 'Fecha de Retorno',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora_retorno',
      label: 'Hora de Retorno',
      options: {
        filter: true,
      },
    },
  ];

  return <MyDataTable title='Cerrar Papeletas' source='/reportes' customColumns={customColumns} canDelete={false} canRegister={false} />;
};
