import React, { useState, Fragment, useEffect, useContext } from "react";
import { navigate } from "@reach/router";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import { MUITable } from "../../../components/MUITable";
import { useDialog } from "../../../hooks/useDialog";
import { useFetch } from "../../../hooks/useFetch";

import { UserContext } from "../../../contexts/UserContext";

export const RRHHSolicitudMain = () => {
  const source = "/solicitudes/rrhh";
  const { loading, getData } = useFetch();
  const { confirm } = useDialog();
  const [dataTable, setDataTable] = useState([]);
  const [dataModal, setDataModal] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await getData({ source: source });
      res.status === 200 && setDataTable(res.data);
      
    })();
  }, [getData]);

  const handleModal = (data) => {
    setOpenModal(true);
    setDataModal(data);
  };

  const actions = [
    {
      name: "Acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const id = tableMeta.rowData[1];
          return (
            <Fragment key={id}>
              <Tooltip title="Editar">
                <IconButton
                  onClick={() => {
                    navigate(`${source}/${id}/edit`);
                  }}
                >
                  <EditIcon color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Detalle">
                <IconButton
                  onClick={() =>
                    handleModal(dataTable.filter((d) => d.id === id)[0])
                  }
                >
                  <VisibilityIcon style={{ color: "#ff6d00" }} />
                </IconButton>
              </Tooltip>
              {dataTable[index].estado === "PENDIENTE" && (
                <Tooltip title="Aprobar">
                  <IconButton
                    onClick={() => {
                      confirm({
                        title: "Mensaje",
                        message:
                          "¿Estas seguro de querer aprobar esta solicitud?",
                      }).then(async (result) => {
                        if (result) {
                          const response = await getData({
                            source: `${source}/${id}`,
                            body: { rrhh: 1, rrhh_motivo_rechazo: null },
                            json: true,
                            method: "PUT",
                            bg: "true",
                          });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp[index].estado = "APROBADO";
                            setDataTable(tmp);
                          }
                        }
                      });
                    }}
                  >
                    <DoneOutlineIcon style={{ color: "#00e676" }} />
                  </IconButton>
                </Tooltip>
              )}
              {dataTable[index].estado === "PENDIENTE" && (
                <Tooltip title="Rechazar">
                  <IconButton
                    onClick={() => {
                      confirm({
                        title: "Mensaje",
                        message:
                          "¿Estas seguro de querer rechazar esta solicitud?",
                      }).then(async (result) => {
                        if (result) {
                          const response = await getData({
                            source: `${source}/${id}`,
                            body: { rrhh: 2, rrhh_motivo_rechazo: null },
                            json: true,
                            method: "PUT",
                            bg: "true",
                          });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp[index].estado = "RECHAZADO";
                            // tmp.shift();
                            setDataTable(tmp);
                          }
                        }
                      });
                    }}
                  >
                    <NotInterestedIcon style={{ color: "#ff3d00" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          );
        },
      },
    },
  ];

  const columns = [
    ...actions,
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "numero",
      label: "Numero",
      options: {
        filter: true,
      },
    },
    {
      name: "empleado",
      label: "Empleado",
      options: {
        filter: true,
      },
    },
    {
      name: "motivo_salida",
      label: "Motivo de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "detalle_motivo",
      label: "Detalles del Motivo",
      options: {
        filter: true,
      },
    },
    {
      name: "fecha_salida",
      label: "Fecha de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "hora_salida",
      label: "Hora de Salida",
      options: {
        filter: true,
      },
    },
    {
      name: "fecha_retorno",
      label: "Fecha de Retorno",
      options: {
        filter: true,
      },
    },
    {
      name: "hora_retorno",
      label: "Hora de Retorno",
      options: {
        filter: true,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
      },
    },
  ];

  return (
    <Fragment>
      <MUITable
        title="RRHH - Solicitudes"
        source="/solicitudes/rrhh"
        columns={columns}
        data={dataTable}
        loading={loading}
        canRegister={false}
        multipleActions= {true}
        selectableRows = 'multiple'
      />

      <Modal open={openModal} setOpen={setOpenModal} data={dataModal} />
    </Fragment>
  );
};

const Modal = ({ open, setOpen, data }) => {
  const { BASE_URL } = useContext(UserContext);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Empleado"
          disabled={true}
          value={data && (data.empleado || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Cargo"
          disabled={true}
          value={data && (data.cargo || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Unidad/Area/Equipo"
          disabled={true}
          value={data && (data.area_equipo || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Motivo de Salida"
          disabled={true}
          value={data && (data.motivo_salida || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Asunto"
          disabled={true}
          value={data && (data.asunto || "")}
          fullWidth
        />
        {data && data.motivo_comision && (
          <TextField
            margin="dense"
            label="Motivo Comision"
            disabled={true}
            value={data && (data.motivo_comision || "")}
            fullWidth
          />
        )}
        {data && data.institucion_educativa && (
          <TextField
            margin="dense"
            label="Institucion Educativa"
            disabled={true}
            value={data && (data.institucion_educativa || "")}
            fullWidth
          />
        )}
        <TextField
          margin="dense"
          label="Detalle de Motivo"
          disabled={true}
          value={data && (data.detalle_motivo || "")}
          fullWidth
          multiline={true}
          rows={2}
        />
        <TextField
          margin="dense"
          label="Fecha de Salida"
          disabled={true}
          value={data && (data.fecha_salida || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Fecha de Retorno"
          disabled={true}
          value={data && (data.fecha_retorno || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Estado"
          disabled={true}
          value={data && (data.estado || "")}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Observacion"
          multiline={true}
          rows={4}
          disabled={true}
          value={data && (data.observacion || "")}
          fullWidth
        />
        {data && data.documento && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.documento}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Documento
            </a>
          </div>
        )}
        {data && data.justificativo && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.justificativo}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Justificativo
            </a>
          </div>
        )}
        {data && data.foto && (
          <div>
            <a
              href={BASE_URL + "/app/" + data.foto}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", fontSize: "16px" }}
            >
              Foto
            </a>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
