import React, { Fragment, useContext } from 'react';
import { Link } from '@reach/router';
import { Menu, MenuItem, ListItemIcon, ListItemText, Fab, Grid, Divider } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceIcon from '@material-ui/icons/Face';

import { StyledAvatar } from './styles';
import { UserContext } from '../../contexts/UserContext';
import { LayoutContext } from '../../contexts/LayoutContext';

export const MyMenu = ({ children, handleSignOut }) => {
  const { user } = useContext(UserContext);
  const { layout } = useContext(LayoutContext)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <Fab variant='extended' size='small' className='profile' onClick={handleClick}>
        {children}
      </Fab>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Grid container justify='center' alignItems='center'>
          <StyledAvatar src={((user.value && user.value.photo !== undefined) && user.value.photo)  || '/assets/images/profile.png'} />
        </Grid>
        <Divider />
        <Link to='/perfil'>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FaceIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Mi Perfil' />
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Cerrar sesion' />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
