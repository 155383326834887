import React, { useState, useEffect, Fragment, useContext } from 'react';

import { Export } from './styles';
import { MyDataTable } from '../../../components/MyDataTable';
import { Grid, Typography, Button, debounce } from '@material-ui/core';
import { MyDatePicker } from "../../../components/MyDatePicker";
import { MyAutocomplete } from "../../../components/MyAutocomplete";
import { MyPaper } from "../../../components/MyPaper";
import { UserContext } from '../../../contexts/UserContext';
import { useFetch } from '../../../hooks/useFetch';
import { useInputValue } from '../../../hooks/useInputValue';
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";

const useInitial = (getData, setReload) => {
  const [title, setTitle] = useState("Reporte de Control de Bioseguridad");
  const [empleados, setEmpleados] = useState([]);
  useEffect(() => {
    (async function () {
      const [empleados] = await Promise.all([
        getData({ source: "/employees", bg: true }),
      ]);
      if (empleados) {
        let empleadosAux = [];
        empleados.data.empleados.map((empleado) => {
          let aux = {
            value: empleado.value,
            label: empleado.label,
            cargo: empleado.cargo,
            area_equipo: empleado.area_equipo,
          }
          empleadosAux.push(aux)
        })
        setEmpleados(empleadosAux);
      }
    })();
  }, [getData]);
  return {
    title,
    empleados,

  };
};

export const VigilanciaMain = () => {
  const fechaAfter = useInputValue(moment().subtract(30, 'days'));
  const fechaBefore = useInputValue(moment(Date()));
  const before = moment(Date()).format("DD-MM-YYYY")
  const after = moment().subtract(30, 'days').format("DD-MM-YYYY")
  const complement = '?per_page=1999'.concat('&filter[starts_after]=', after, '&filter[starts_before]=', before);

  const { loading, getData } = useFetch();
  const [reload, setReload] = useState('All');
  const [error, setError] = useState(undefined);
  const { BASE_URL, user } = useContext(UserContext);
  const [empleado, setEmpleado] = useState('');
  // const fechaAfter = useInputValue(moment(Date()).format("DD-MM-YYYY"));
  // const fechaBefore = useInputValue(moment(Date()).format("DD-MM-YYYY"));
  // const complement = '?per_page=500'.concat('&filter[starts_after]=', fechaAfter.value, '&filter[starts_before]=', fechaBefore.value)
  const [complementSource, setComplementSource] = useState(complement)
  const {
    title,
    empleados,
  } = useInitial(getData, setReload);


  useEffect(() => {
    if (user.value.empleado && empleados.length) {
      const tmp = empleados.filter(
        (item) => item.value === user.value.empleado
      );
      empleado.setValue(tmp[0].label);
      setEmpleado(tmp[0]);
    }
  }, [empleados]);


  useEffect(() => {
    if (empleado.value) {
      setComplementSource('');
      let id = ''
      if (empleado && empleado.value != '') {
        const empleado_id = '&filter[empleado_id]='.concat(empleado.value)
        id = empleado_id
      }
      let fechaAfter_starts = ''
      let fechaBefore_starts = ''
      if (fechaAfter.value && fechaAfter.value) {
        fechaAfter_starts = '&filter[starts_after]=' + moment(fechaAfter.value).format("YYYY-MM-DD");
        if (fechaBefore.value) {
          fechaBefore_starts = '&filter[starts_before]=' + moment(fechaBefore.value).format("YYYY-MM-DD");
        }
      }
      const complement = '?per_page=500' + id + fechaAfter_starts + fechaBefore_starts
      setComplementSource(complement)

    }
  }, [empleado]);

  useEffect(() => {
    if (fechaBefore.value) {
      fechaBefore.setError("");
      if (fechaAfter.value) {
        const start = moment(fechaAfter.value);
        const end = moment(fechaBefore.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaBefore.setError(
            "La fecha hasta no puede ser menor que de inicio"
          );
          fechaBefore.setValue(null);
        } else {
          if (empleado.value) {
            setComplementSource('');
            let id = ''
            if (empleado && empleado.value != '') {
              const empleado_id = '&filter[empleado_id]='.concat(empleado.value)
              id = empleado_id
            }
            let fechaAfter_starts = ''
            let fechaBefore_starts = ''
            if (fechaAfter.value && fechaAfter.value) {
              fechaAfter_starts = '&filter[starts_after]=' + moment(fechaAfter.value).format("YYYY-MM-DD");
              if (fechaBefore.value) {
                fechaBefore_starts = '&filter[starts_before]=' + moment(fechaBefore.value).format("YYYY-MM-DD");
              }
            }
            const complement = '?per_page=500' + id + fechaAfter_starts + fechaBefore_starts
            setComplementSource(complement)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaBefore.value]);

  useEffect(() => {
    if (fechaAfter.value) {
      fechaAfter.setError("");
      if (fechaBefore.value) {
        const start = moment(fechaAfter.value);
        const end = moment(fechaBefore.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaAfter.setError(
            "La fecha hasta no puede ser menor que de inicio"
          );
          fechaAfter.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaBefore.value]);


  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'fecha_reserva',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'employee_full_name',
      label: 'Empleado',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   let empleado =value.apellido_paterno+" "+value.apellido_materno+" "+value.nombres
        //   return <div>{empleado}</div>;
        // },
      },
    },
    {
      name: 'mascarilla_boolean',
      label: 'Mascarilla',
      options: {
        filter: true,
      },
    },
    {
      name: 'alcohol_boolean',
      label: 'Guantes Quirúrgicos',
      options: {
        filter: true,
      },
    },
    {
      name: 'protector_facial_boolean',
      label: 'Protector',
      options: {
        filter: true,
      },
    },
    {
      name: 'gel_antibacterial_boolean',
      label: 'Gel',
      options: {
        filter: true,
      },
    },
    {
      name: 'temperatura',
      label: 'Temperatura',
      options: {
        filter: false,
      },
    },
    {
      name: 'finish_boolean',
      label: 'Ingresó',
      options: {
        filter: false,
      },
    },

  ];


  const handleSubmit = () => {
    setComplementSource('');
    let id = ''
    if (empleado && empleado.value != '') {
      const empleado_id = '&filter[empleado_id]='.concat(empleado.value)
      id = empleado_id
    }
    let fechaAfter_starts = ''
    let fechaBefore_starts = ''
    if (fechaAfter.value) {
      fechaAfter_starts = '&filter[starts_after]=' + moment(fechaAfter.value).format("YYYY-MM-DD");
      if (fechaBefore.value) {
        fechaBefore_starts = '&filter[starts_before]=' + moment(fechaBefore.value).format("YYYY-MM-DD");
      }
    }
    const complement = '?per_page=500' + id + fechaAfter_starts + fechaBefore_starts
    setComplementSource(complement)
    setReload('Filter')
  }


  function RenderList(props) {
    console.log(complementSource)
    return (
      <MyDataTable
        title={props.title}
        source={props.source}
        complementSource={props.complementSource}
        customColumns={props.customColumns}
        pdf={props.pdf}
        document={props.document}
        canRegister={props.canRegister}
      />
    )
  }



  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate>
          <Grid container item spacing={2}>
            {!user.value.empleado && (
              <Grid item xs={12} md={4}>
                <MyAutocomplete
                  name="empleado"
                  label="Empleado"
                  data={empleado}
                  setData={setEmpleado}
                  options={empleados}
                  disabled={loading}
                />
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <MyDatePicker
                name="fechaAfter"
                label="Desde"
                controller={fechaAfter}
                disabled={loading}
                disablePast={user && !user.value.empleado ? false : true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MyDatePicker
                name="fechaBefore"
                label="Hasta"
                controller={fechaBefore}
                disabled={loading}
                disablePast={user && !user.value.empleado ? false : true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={loading}
              >
                Buscar
                </Button>
            </Grid>
            {error && (
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            )}
          </Grid>
          {reload == 'All' && (
            <MyDataTable
              title='Vigilancia Control'
              source='/vigilancia'
              complementSource={complementSource}
              customColumns={customColumns}
              pdf={false}
              document='vigilancia'
              canRegister={true}
            />
          )}

          {reload == 'Filter' && (
            <RenderList
              title='Vigilancia Control'
              source='/vigilancia'
              complementSource={complementSource}
              customColumns={customColumns}
              pdf={false}
              document='vigilancia'
              canRegister={true}
              reload={reload}

            />
          )}
        </form>
      </MyPaper>
    </Fragment >
  );
};

