import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, Switch } from '@material-ui/core';
import swal from 'sweetalert';
import moment from 'moment';

import { StyledTable, InputBox } from './styles';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    (async function() {
      const desplazamiento = await getData({ source: `/solicitudes/${id}/show` });
      desplazamiento.status === 200 && setData(desplazamiento.data);
    })();
  }, [id, getData]);

  return { data };
};

export const SolicitudRegister = ({ id }) => {
  const title = 'Editar Solicitud';
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { data } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const empleado = useInputValue('');
  const cargo = useInputValue('');
  const area = useInputValue('');
  const motivoSalida = useInputValue('');
  const asunto = useInputValue('');
  const motivoComision = useInputValue('');
  const institucionEducativa = useInputValue('');
  const fechaSalida = useInputValue('');
  const fechaRetorno = useInputValue('');
  const estado = useInputValue('');
  const motivoRechazo = useInputValue('');
  const detalleMotivo = useInputValue('');
  const motivoAnulado = useInputValue('');

  const [previewJ, setPreviewJ] = useState(null);
  const [sinRetorno, setSinRetorno] = useState(false);

  const [desplazamiento, setDesplazamiento] = useState(undefined);
  const [dias, setDias] = useState(undefined);
  const [rango, setRango] = useState(undefined);

  // Handles
  const getDias = empleado => {
    (async () => {
      const response = await getData({ source: `/desplazamientos/vacaciones-disponibles/${empleado}`, bg: true });
      response.status === 200 && setDias(response.data);
    })();
  };

  const calcular = (salida, retorno) => {
    const start = moment(moment(salida).format('YYYY-MM-DD'));
    let end = moment(retorno);
    if (end.isoWeekday() === 5) {
      end = end.add(2, 'days');
      fechaRetorno.setValue(end);
    }
    end = moment(end.format('YYYY-MM-DD'));
    const diff = end.diff(start, 'days') + 1;
    setRango({
      dias: diff,
      desde: start.format('DD-MM-Y'),
      hasta: end.isoWeekday() === 5 ? end.add(2, 'days').format('DD-MM-Y') : end.format('DD-MM-Y'),
    });
  };

  useEffect(() => {
    if (data) {
      const _empleado = data.empleado;
      const _desplazamiento = data.desplazamiento;
      setDesplazamiento(_desplazamiento);
      empleado.setValue(`${_empleado.apellido_paterno} ${_empleado.apellido_materno} ${_empleado.nombres}`);
      cargo.setValue(_empleado.cargo);
      area.setValue(_empleado.area);
      motivoSalida.setValue(_desplazamiento._motivo_salida);
      asunto.setValue(_desplazamiento._asunto);
      motivoComision.setValue(_desplazamiento._motivo_comision || '');
      institucionEducativa.setValue(_desplazamiento._institucion_educativa || '');
      detalleMotivo.setValue(_desplazamiento.detalle_motivo || '');
      motivoAnulado.setValue(_desplazamiento.motivo_anulado || '');

      const fs = moment(_desplazamiento.fecha_salida);
      const hs = _desplazamiento.hora_salida && moment(_desplazamiento.hora_salida, 'H:mm:ss');
      const fr = _desplazamiento.fecha_retorno && moment(_desplazamiento.fecha_retorno);
      const hr = _desplazamiento.hora_retorno && moment(_desplazamiento.hora_retorno, 'H:mm:ss');
      fechaSalida.setValue(fs.format('DD-MM-YYYY') + ' ' + (hs ? hs.format('LT') : ''));
      fechaRetorno.setValue((fr ? fr.format('DD-MM-YYYY') : '') + ' ' + (hr ? hr.format('LT') : ''));
      estado.setValue(_desplazamiento.estado);
      motivoRechazo.setValue(_desplazamiento.motivo_rechazo || '');
      if (_desplazamiento.motivo_salida === 3) {
        getDias(_empleado.id);
        calcular(fs, fr);
      }
      setSinRetorno(_desplazamiento.sin_retorno ? true : false);
      if (_desplazamiento.justificativo) {
        setPreviewJ(`data:application/pdf;base64,${_desplazamiento.justificativo}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!estado.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const body = {
      estado: estado.value,
      sin_retorno: sinRetorno,
      motivo_rechazo: Number(estado.value) === 2 && motivoRechazo.value,
      motivo_anulado: Number(estado.value) === 3 && motivoAnulado.value,
    };
    // console.log(body)
    setError(undefined);
    const response = await getData({ source: `/solicitudes/${id}`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Solicitud actualizada con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={4}>
              <MyInput name='empleado' label='Empleado' controller={empleado} disabled={true} />
            </Grid>

            <Grid item xs={12} md={4}>
              <MyInput name='cargo' label='Cargo' controller={cargo} disabled={true} />
            </Grid>

            <Grid item xs={12} md={4}>
              <MyInput name='area' label='Unidad/Area/Equipo' controller={area} disabled={true} />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyInput name='motivoSalida' label='Motivo de Salida' controller={motivoSalida} disabled={true} />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyInput name='asunto' label='Asunto' controller={asunto} disabled={true} />
            </Grid>

            {desplazamiento && Number(desplazamiento.motivo_salida) === 4 && (
              <Grid item xs={12} md={6}>
                <MyInput name='motivoComision' label='Motivo Comision' controller={motivoComision} disabled={true} />
              </Grid>
            )}

            {desplazamiento && Number(desplazamiento.motivo_salida) === 4 && Number(desplazamiento.asunto) === 28 && (
              <Grid item xs={12} md={6}>
                <MyInput name='institucionEducativa' label='Institucion Educativa' controller={institucionEducativa} disabled={true} />
              </Grid>
            )}

            <Grid item xs={12}>
              <MyInput name='detalleMotivo' label='Detalle de Motivo' toCase='upper' controller={detalleMotivo} disabled={true} rows={5} />
            </Grid>

            {desplazamiento && desplazamiento.motivo_salida === 3 && <VacationTable dias={dias} />}

            <Grid item xs={12} md={6}>
              <MyInput name='fechaSalida' label='Fecha de Salida' controller={fechaSalida} disabled={true} />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyInput name='fechaRetorno' label='Fecha de Retorno' controller={fechaRetorno} disabled={true} />
            </Grid>

            {desplazamiento && desplazamiento.motivo_salida === 3 && <RangeTable rango={rango} />}

            <Grid item xs={12} md={6}>
              <MySelect
                name='estado'
                label='Estado'
                controller={estado}
                required={true}
                disabled={loading}
                data={[
                  { id: 1, name: 'APROBADO' },
                  { id: 2, name: 'RECHAZADO' },
                  { id: 3, name: 'ANULADO' },
                ]}
              />
            </Grid>

            {Number(estado.value) === 1 && desplazamiento && Number(desplazamiento.motivo_salida) === 4 && (
              <Grid item xs={12} md={6}>
                <InputBox>
                  <FormControlLabel
                    control={<Switch checked={sinRetorno} onChange={() => setSinRetorno(!sinRetorno)} color='primary' />}
                    label='SIN RETORNO'
                  />
                </InputBox>
              </Grid>
            )}

            {Number(estado.value) === 2 && (
              <Grid item xs={12}>
                <MyInput
                  name='motivoRechazo'
                  label='Motivo de Rechazo'
                  toCase='upper'
                  controller={motivoRechazo}
                  disabled={loading}
                  rows={5}
                />
              </Grid>
            )}

            {Number(estado.value) === 3 && (
              <Grid item xs={12}>
                <MyInput
                  name='motivoAnulado'
                  label='Motivo de Anulado'
                  toCase='upper'
                  controller={motivoAnulado}
                  disabled={loading}
                  rows={5}
                />
              </Grid>
            )}

            {previewJ && (
              <Grid item xs={12}>
                <object width='100%' height='500' data={previewJ}>
                  <p>Error al mostrar el documento.</p>
                </object>
              </Grid>
            )}

            <Grid container item justify='flex-end' alignItems='center' spacing={2}>
              <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
                {loading && (
                  <Fragment>
                    <CircularProgress />
                    <Box ml={2}>
                      <Typography variant='h6' color='textSecondary'>
                        Procesando...
                      </Typography>
                    </Box>
                  </Fragment>
                )}
                {error && (
                  <Typography variant='h6' color='error'>
                    {error}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6} md={3}>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='secondary'
                  disabled={loading}
                  onClick={() => window.history.back()}
                >
                  Atras
                </Button>
              </Grid>

              <Grid item xs={6} md={3}>
                <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MyPaper>
    </Fragment>
  );
};

const VacationTable = ({ dias }) => {
  return (
    <Grid item xs={12}>
      <StyledTable>
        <thead>
          <tr>
            <th colSpan={3}>DIAS DISPONIBLES</th>
          </tr>
          <tr>
            <th width='33.3%'>HABILES</th>
            <th>NO HABILES</th>
            <th width='33.3%'>FRACCIONADOS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dias ? dias.habiles : 0}</td>
            <td>{dias ? dias.noHabiles : 0}</td>
            <td>{dias ? dias.fraccionados : 0}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Grid>
  );
};

const RangeTable = ({ rango }) => {
  return (
    <Grid item xs={12}>
      <StyledTable>
        <thead>
          <tr>
            <th colSpan={3}>RANGO</th>
          </tr>
          <tr>
            <th width='33.3%'>DIAS</th>
            <th>DESDE</th>
            <th width='33.3%'>HASTA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{rango ? rango.dias : 0}</td>
            <td>{rango ? rango.desde : 0}</td>
            <td>{rango ? rango.hasta : 0}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Grid>
  );
};
