import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';
import moment from 'moment';

import { StyledAvatar, File } from './styles';

import { MyPaper } from '../../components/MyPaper';
import { MyInput } from '../../components/MyInput';
import { MySelect } from '../../components/MySelect';
import { MyDatePicker } from '../../components/MyDatePicker';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

import { UserContext } from '../../contexts/UserContext';

export const Perfil = ({ id }) => {
  const title = 'Perfil';
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { user } = useContext(UserContext);
  const inputFile = document.querySelector('#foto');

  const dni = useInputValue('');
  const nombres = useInputValue('');
  const apellidoPaterno = useInputValue('');
  const apellidoMaterno = useInputValue('');
  const sexo = useInputValue('');
  const fechaNacimiento = useInputValue(null);
  const telefono = useInputValue('');
  const direccion = useInputValue('');
  const email = useInputValue('');
  const [foto, setFoto] = useState(null);

  const handleFileChange = async e => {
    if (e.target.files[0]) {
      const blob = await fileToBlob(e.target.files[0]);
      setFoto(blob);
    }
  };

  const fileToBlob = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  useEffect(() => {
    (async () => {
      const response = await getData({ source: `/empleados/${user.value.empleado}/show` });
      if (response.status === 200) {
        dni.setValue(response.data.dni || '');
        nombres.setValue(response.data.nombres || '');
        apellidoPaterno.setValue(response.data.apellido_paterno || '');
        apellidoMaterno.setValue(response.data.apellido_materno || '');
        sexo.setValue(response.data.sexo || '');
        const fecha = response.data.fecha_nacimiento ? moment(response.data.fecha_nacimiento) : null;
        fechaNacimiento.setValue(fecha);
        direccion.setValue(response.data.direccion || '');
        telefono.setValue(response.data.telefono || '');
        email.setValue(response.data.email || '');
        setFoto(response.data.foto);
        // if (user.value !== null && user.value.role !== 1) {
        //   const body = {
        //     tipo: 0,
        //     empleado: user.value.empleado,
        //     rut: response.data.dni ? response.data.dni.toString() : "0",
        //   }
        //   let responseClock = await getData({
        //     source: "/asistencias/add",
        //     body: body,
        //     json: true,
        //     method: "POST",
        //   });
        //   if (responseClock.status === 200) {
        //     let message = "Bienvenido al sistema, que tenga buen día!";
        //     await swal(message, { icon: "success" });
        //   } else {
        //     // let message = "Hay un error, al parecer ya has marcado salida, confirma con RRHH";
        //     // await swal(message, { icon: "warning" });
        //   }
        // }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);


  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!dni.value || !nombres.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const body = {
      dni: dni.value,
      nombres: nombres.value,
      apellidoPaterno: apellidoPaterno.value,
      apellidoMaterno: apellidoMaterno.value,
      sexo: sexo.value,
      fechaNacimiento: fechaNacimiento.value ? moment(fechaNacimiento.value).format('YYYY-MM-DD') : null,
      direccion: direccion.value,
      telefono: telefono.value,
      foto: foto,
      email: email.value,
    };
    setError(undefined);
    let response = await getData({ source: `/empleados/${user.value.empleado}/perfil`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Perfil actualizado con exito.', { icon: 'success' });
    } else {
      setError(response.message);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <MyPaper title='Foto de perfil'>
          <Grid container justify='center' alignItems='center'>
            <StyledAvatar src={foto || '/assets/images/profile.png'} />
          </Grid>
          <Grid container justify='center' alignItems='center'>
            <File type='file' id='foto' value='' onChange={handleFileChange} />
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                inputFile.click();
              }}
            >
              Seleccionar
            </Button>
          </Grid>
        </MyPaper>
      </Grid>
      <Grid item xs={12} md={8}>
        <MyPaper title={title}>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={6}>
                <MyInput name='dni' label='DNI' controller={dni} required={true} disabled={loading} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyInput name='nombres' label='Nombres' controller={nombres} required={true} disabled={loading} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyInput name='apellidoPaterno' label='Apellido Paterno' controller={apellidoPaterno} disabled={loading} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyInput name='apellidoMaterno' label='apellidoMaterno' controller={apellidoMaterno} disabled={loading} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MySelect name='sexo' label='Sexo' controller={sexo} disabled={loading} data={[{ name: 'MUJER' }, { name: 'HOMBRE' }]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyDatePicker name='fechaNacimiento' label='Fecha de Nacimiento' controller={fechaNacimiento} disabled={loading} />
              </Grid>
              <Grid item xs={12}>
                <MyInput name='direccion' label='DIRECCION' toCase='upper' controller={direccion} disabled={loading} rows={2} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyInput name='telefono' label='Telefono' controller={telefono} disabled={loading} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyInput name='email' label='Email' controller={email} disabled={loading} />
              </Grid>

              <Grid container item justify='flex-end' alignItems='center' spacing={2}>
                <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
                  {loading && (
                    <Fragment>
                      <CircularProgress />
                      <Box ml={2}>
                        <Typography variant='h6' color='textSecondary'>
                          Procesando...
                        </Typography>
                      </Box>
                    </Fragment>
                  )}
                  {error && (
                    <Typography variant='h6' color='error'>
                      {error}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </MyPaper>
      </Grid>
    </Grid>
  );
};
