import React from 'react';

import {
    StyledDrawer,
} from './styles'
import { MyList } from '../MyList';

const widthOpen = 300
const widthClosed = 70

export const MyDrawer = ({ openDrawer }) => {

    return (

        <StyledDrawer
            variant="permanent"
            // open={openDrawer}
            width={openDrawer ? widthOpen : widthClosed}
        >
            <MyList />
        </StyledDrawer>

    )

}