import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';
import moment from 'moment';
import RoomIcon from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';

import {
  StyledImage,
  StyledDiv,
  StyledFile,
} from './styles';

import { MyPaper } from '../../../components/MyPaper';
import { MyDatePicker, MyTimePicker } from '../../../components/MyDatePicker';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [desplazamiento, setDesplazamiento] = useState(undefined);
  const [empleado, setEmpleado] = useState([]);
  const [institucionEducativa, setInstitucionEducativa] = useState([]);

  useEffect(() => {
    (async function () {
      const result = await getData({ source: `/reportes/${id}/show` });
      if (result.status === 200) {
        setDesplazamiento(result.data.desplazamiento);
        setEmpleado(result.data.empleado);
        setInstitucionEducativa(result.data.institucionEducativa);
      }
    })();
  }, [id, getData]);

  return { desplazamiento, empleado, institucionEducativa };
};

export const ReporteRegister = ({ id }) => {
  const title = 'Editar reporte';
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { desplazamiento, empleado, institucionEducativa } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const _empleado = useInputValue('');
  const _institucionEducativa = useInputValue('');
  const fechaSalida = useInputValue(null);
  const horaSalida = useInputValue(null);
  const fechaRetorno = useInputValue(null);
  const horaRetorno = useInputValue(null);
  const observacion = useInputValue('');
  const motivo_salida = useInputValue(null);
  const asunto = useInputValue(null);
  const [foto, setFoto] = useState(null);
  const lat = useInputValue(-12.0289753);
  const lng = useInputValue(-77.0128055);
  const [documento, setDocumento] = useState(null);

  const [previewF, setPreviewF] = useState(null);
  const [previewD, setPreviewD] = useState(null);

  // Handles
  const fileToBlob = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  useEffect(() => {
    if (foto) {
      (async () => setPreviewF(await fileToBlob(foto)))();
    }
  }, [foto]);

  useEffect(() => {
    if (desplazamiento) {
      const fs = moment(desplazamiento.fecha_salida);
      fechaSalida.setValue(fs);
      const hs = desplazamiento.hora_salida && moment(desplazamiento.hora_salida, 'H:mm:ss');
      horaSalida.setValue(hs);
      const fr = desplazamiento.fecha_retorno && moment(desplazamiento.fecha_retorno);
      fechaRetorno.setValue(fr);
      const hr = desplazamiento.hora_retorno && moment(desplazamiento.hora_retorno, 'H:mm:ss');
      horaRetorno.setValue(hr);
      observacion.setValue(desplazamiento.observacion || '');
      if (desplazamiento.foto) {
        setPreviewF(`data:image/png;base64,${desplazamiento.foto}`);
      }
      desplazamiento.motivo_salida && motivo_salida.setValue(desplazamiento.motivo_salida)
      desplazamiento.asunto && asunto.setValue(desplazamiento.asunto)
      desplazamiento.lat && lat.setValue(desplazamiento.lat);
      desplazamiento.lng && lng.setValue(desplazamiento.lng);
      if (desplazamiento.documento) {
        setPreviewD(`data:application/pdf;base64,${desplazamiento.documento}`);
      }
    }
    empleado && _empleado.setValue(`${empleado.apellido_paterno} ${empleado.apellido_materno} ${empleado.nombres}`);
    institucionEducativa && _institucionEducativa.setValue(institucionEducativa.nombre || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(motivo_salida)
  }, [desplazamiento, empleado, institucionEducativa]);

  useEffect(() => {
    if (fechaRetorno.value) {
      fechaRetorno.setError('');
      if (fechaSalida.value) {
        const start = moment(fechaSalida.value);
        const end = moment(fechaRetorno.value);
        const diff = end.diff(start, 'minutes');
        if (diff < 0) {
          fechaRetorno.setError('La fecha retorno no puede ser menor que la de salida');
          fechaRetorno.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaRetorno.value]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (foto && foto.type.search('image/') === -1) {
      swal('La foto debe ser | png | jpeg |', { icon: 'error' });
      return;
    }
    if (documento && documento.type !== 'application/pdf') {
      swal('El documento debe ser | pdf |', { icon: 'error' });
      return;
    }
    if (
      (!fechaRetorno.value && motivo_salida.value != 7 && asunto.value != 35 )
      || (!horaRetorno.value &&  motivo_salida.value != 7 && asunto.value != 35 )
      || !observacion.value
      // || !lat.value
      // || !lng.value
    ) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const body = {
      fechaRetorno: moment(fechaRetorno.value).format('YYYY-MM-DD'),
      horaRetorno: moment(horaRetorno.value).format('H:mm'),
      observacion: observacion.value,
      foto: previewF || null,
      lat: lat.value,
      lng: lng.value,
      documento: documento ? await fileToBlob(documento) : null,
    };

    setError(undefined);

    const response = await getData({ source: `/reportes/${id}`, body: body, json: true, method: 'PUT' });

    if (response.status === 200) {
      await swal('Reporte actualizado con exito.', { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput name='empleado' label='Empleado' controller={_empleado} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='institucionEducativa' label='Institucion Educativa' controller={_institucionEducativa} disabled={true} />
          </Grid>

          <Grid item xs={12} md={3}>
            <MyDatePicker name='fechaSalida' label='Fecha de Salida' controller={fechaSalida} disabled={true} required={true} />
          </Grid>

          <Grid item xs={12} md={3}>
            <MyTimePicker name='horaSalida' label='Hora de Salida' controller={horaSalida} required={true} disabled={true} />
          </Grid>

          {motivo_salida.value != 7 && asunto.value != 35 &&(
            <Grid item xs={12} md={3}>
              <MyDatePicker
                name='fechaRetorno'
                label='Fecha de Retorno'
                controller={fechaRetorno}
                disabled={loading}
                required={true}
                disablePast={true}
              />
            </Grid>
          )}
          {motivo_salida.value != 7 && asunto.value != 35 &&(
            <Grid item xs={12} md={3}>
              <MyTimePicker name='horaRetorno' label='Hora de Retorno' controller={horaRetorno} required={true} disabled={loading} />
            </Grid>

          )}

          <Grid item xs={12}>
            <MyInput name='observacion' label='Observacion' toCase='upper' controller={observacion} disabled={loading} rows={5} />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledFile>
              <label>Foto</label>
              <input type='file' onChange={e => setFoto(e.target.files[0])} />
            </StyledFile>
          </Grid>

          {previewF && (
            <Grid item xs={12}>
              <StyledImage src={previewF} />
            </Grid>
          )}

          {!previewF && (
            <Grid item xs={12}>
              <StyledDiv>
                <p>Foto</p>
              </StyledDiv>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <MyInput name='lat' label='Latitud' controller={lat} type='number' disabled={loading} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MyInput name='lng' label='Longitud' controller={lng} type='number' disabled={loading} />
          </Grid>

          <Grid item xs={12}>
            <Map lat={lat.value} lng={lng.value} />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledFile>
              <label>Documento</label>
              <input type='file' onChange={e => setDocumento(e.target.files[0])} />
            </StyledFile>
          </Grid>

          {previewD && (
            <Grid item xs={12}>
              <object width='100%' height='500' data={previewD}>
                <p>Error al mostrar el documento.</p>
              </object>
            </Grid>
          )}

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const AnyReactComponent = ({ text }) => <RoomIcon color='secondary' fontSize='large' />;

const Map = ({ lat, lng, zoom = 16 }) => {
  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDUckEqMGN6Llf4Zk-JXaIa-lsh3jz-noc' }}
        defaultCenter={{ lat: -12.0289753, lng: -77.0128055 }}
        defaultZoom={zoom}
        center={{
          lat: Number(lat),
          lng: Number(lng),
        }}
      >
        <AnyReactComponent lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
