import React from 'react';
import { Grid } from '@material-ui/core';
import { navigate } from '@reach/router';
import MUIDataTable from 'mui-datatables';

import { CustomToolbar } from './CustomToolbar';
import { Loading } from '../Loading';

export const MUITable = ({ title, source, columns, data, loading = false, canRegister = true }) => {

  const handleClick = () => {
    navigate(`${source}/register`);
  };

  const options = {
    filter: true,
    print: false,
    selectableRows: 'none',
    rowsPerPage: 15,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',
    customToolbar: () =>
      canRegister ? <CustomToolbar tooltip='Registrar' handleClick={handleClick} /> : <React.Fragment></React.Fragment>,
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <MUIDataTable title={title} data={data} columns={columns} options={options} />
    </Grid>
  );
};
