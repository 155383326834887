import React from 'react';

import { StyledDivider } from './styles'

export const MyDivider = () => {

    return (

        <StyledDivider />

    )

}