import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography, FormControlLabel, Switch } from '@material-ui/core';
import swal from 'sweetalert';
import moment from 'moment';

// MyAutocomplete

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// FIn

import { InputBox } from './styles';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyDatePicker } from '../../../components/MyDatePicker';
// import { Creatable } from '../../../components/MyAutocomplete/Creatable';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [title, setTitle] = useState('Registrar empleado');
  const [empleado, setEmpleado] = useState(undefined);
  const [selects, setSelects] = useState(undefined);
  useEffect(() => {
    id && setTitle('Editar empleado');
    (async function () {
      const [selects, _empleado] = await Promise.all([
        getData({ source: '/empleados/selects', bg: true }),
        id && getData({ source: `/empleados/${id}/show` }),
      ]);
      selects.status === 200 && setSelects(selects.data);
      if (id && _empleado.status === 200) {
        setEmpleado(_empleado.data);
      }
    })();
  }, [id, getData]);

  return { title, empleado, selects };
};

const filter = createFilterOptions();

export const EmpleadoRegister = ({ id }) => {

  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, empleado, selects } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const areaEquipo = useInputValue('');
  const area = useInputValue('');
  const cargo = useInputValue('');
  const apellidoMaterno = useInputValue('');
  const apellidoPaterno = useInputValue('');
  const nombres = useInputValue('');
  const sexo = useInputValue('');
  const dni = useInputValue('');
  const numeroContrato = useInputValue('');
  const fechaInicioContrato = useInputValue(null);
  const fechaFinContrato = useInputValue(null);
  const condicion = useInputValue('');
  const tipoServidor = useInputValue('');
  const metaPresupuestal = useInputValue('');
  const regimenLaboral = useInputValue('');
  const grupoOcupacional = useInputValue('');
  const procesoCas = useInputValue('');
  const observacion = useInputValue('');
  const depende = useInputValue('');
  const [baja, setBaja] = useState(false);
  const fechaBaja = useInputValue('');
  const email = useInputValue('');

  const [enRiesgo, setEnRiesgo] = useState(false);
  // Funciones y Callbacks
  useEffect(() => {
    if (empleado) {
      areaEquipo.setValue(empleado.area_equipo || '');
      area.setValue(empleado.area || '');
      cargo.setValue(empleado.cargo || '');
      apellidoMaterno.setValue(empleado.apellido_materno || '');
      apellidoPaterno.setValue(empleado.apellido_paterno || '');
      nombres.setValue(empleado.nombres || '');
      sexo.setValue(empleado.sexo || '');
      dni.setValue(empleado.dni || '');
      numeroContrato.setValue(empleado.numero_contrato || '');
      fechaInicioContrato.setValue(empleado.fecha_inicio_contrato ? moment(empleado.fecha_inicio_contrato) : null);
      fechaFinContrato.setValue(empleado.fecha_fin_contrato ? moment(empleado.fecha_fin_contrato) : null);
      condicion.setValue(empleado.condicion || '');
      tipoServidor.setValue(empleado.tipo_servidor || '');
      metaPresupuestal.setValue(empleado.meta_presupuestal || '');
      regimenLaboral.setValue(empleado.regimen_laboral || '');
      grupoOcupacional.setValue(empleado.grupo_ocupacional || '');
      procesoCas.setValue(empleado.numero_proceso_cas || '');
      observacion.setValue(empleado.observacion || '');
      depende.setValue(empleado.depende || '');
      fechaBaja.setValue(empleado.baja ? moment(empleado.baja) : null);
      empleado.baja && setBaja(true);
      console.log(empleado.enRiesgo)
      empleado.enRiesgo === 1?setEnRiesgo(true):setEnRiesgo(false);
      email.setValue(empleado.email || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleado]);
  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    setError(undefined);
    if (!nombres.value || !dni.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    console.log(baja)
    const body = {
      areaEquipo: areaEquipo.value,
      area: area.value,
      cargo: cargo.value,
      apellidoMaterno: apellidoMaterno.value,
      apellidoPaterno: apellidoPaterno.value,
      nombres: nombres.value,
      sexo: sexo.value,
      dni: dni.value,
      numeroContrato: numeroContrato.value,
      fechaInicioContrato: fechaInicioContrato.value ? moment(fechaInicioContrato.value).format('YYYY-MM-DD') : null,
      fechaFinContrato: fechaFinContrato.value ? moment(fechaFinContrato.value).format('YYYY-MM-DD') : null,
      condicion: condicion.value,
      tipoServidor: tipoServidor.value,
      metaPresupuestal: metaPresupuestal.value,
      regimenLaboral: regimenLaboral.value,
      grupoOcupacional: grupoOcupacional.value,
      procesoCas: procesoCas.value,
      observacion: observacion.value,
      depende: depende.value,
      baja: baja ? moment(fechaBaja.value).format('YYYY-MM-DD') : null,
      enRiesgo: enRiesgo?1:null,
      email: email.value,
    };
    let message = undefined;
    let response = null;
    if (id) {
      message = 'Empleado actualizado con exito.';
      response = await getData({ source: `/empleados/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Empleado registrado con exito.';
      response = await getData({ source: '/empleados', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={4}>

            <Autocomplete
              value={areaEquipo.value}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  areaEquipo.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  areaEquipo.setValue(newValue.inputValue);
                } else {
                  areaEquipo.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="areaEquipo"
              disabled={loading}
              name='areaEquipo'
              options={(selects && selects.areasEquipos) ? selects.areasEquipos : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Area de Equipo" variant="outlined" />
              )}
            />

          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              value={area.value}
              disabled={loading}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  area.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  area.setValue(newValue.inputValue);
                } else {
                  area.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="area"
              name='area'
              options={(selects && selects.areas) ? selects.areas.filter(area => area.name) : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Area" variant="outlined" />
              )}
            />

          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              value={cargo.value}
              disabled={loading}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  cargo.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  cargo.setValue(newValue.inputValue);
                } else {
                  cargo.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="cargo"
              name='cargo'
              options={(selects && selects.cargos) ? selects.cargos : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Cargo" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyInput name='apellidoPaterno' label='Apellido Paterno' toCase='upper' controller={apellidoPaterno} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyInput name='apellidoMaterno' label='Apellido Materno' toCase='upper' controller={apellidoMaterno} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyInput name='nombres' label='Nombres' toCase='upper' controller={nombres} disabled={loading} required={true} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MySelect name='sexo' label='Sexo' controller={sexo} disabled={loading} data={[{ name: 'MUJER' }, { name: 'HOMBRE' }]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyInput name='dni' label='DNI' controller={dni} disabled={loading} required={true} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyInput name='numeroContrato' label='Numero de Contrato' controller={numeroContrato} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyDatePicker
              name='fechaInicioContrato'
              label='Fecha de Inicio del Contrato'
              controller={fechaInicioContrato}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyDatePicker name='fechaFinContrato' label='Fecha de Fin del Contrato' controller={fechaFinContrato} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MySelect
              name='condicion'
              label='Condicion'
              controller={condicion}
              disabled={loading}
              data={[{ name: 'CONTRADADO' }, { name: 'DESIGNADO' }, { name: 'ENCARGADO' }, { name: 'TITULAR' }, { name: 'VACANTE' }]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MySelect
              name='tipoServidor'
              label='Tipo de Servidor'
              controller={tipoServidor}
              disabled={loading}
              data={[{ name: 'CAS' }, { name: 'CAP' }]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MySelect
              name='metaPresupuestal'
              label='Meta Presupuestal'
              controller={metaPresupuestal}
              disabled={loading}
              data={[{ name: '035-ACCIONES CENTRALES' }, { name: '037-EDUC. BASICA ALTERNATIVA' }, { name: '040-MODERNIZACION' }]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MySelect
              name='regimenLaboral'
              label='Regimen Laboral'
              controller={regimenLaboral}
              disabled={loading}
              data={[{ name: 'D.LEG. 276' }, { name: 'D.LEG. 1024' }, { name: 'D.LEG. 1057' }, { name: 'Ley N° 29944' }]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              value={grupoOcupacional.value}
              disabled={loading}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  grupoOcupacional.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  grupoOcupacional.setValue(newValue.inputValue);
                } else {
                  grupoOcupacional.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="grupoOcupacional"
              name='grupoOcupacional'
              options={(selects && selects.gruposOcupacionales) ? selects.gruposOcupacionales : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Grupo Ocupacional" variant="outlined" />
              )}
            />

          </Grid>
          <Grid item xs={12} md={4}>

            <Autocomplete
              value={procesoCas.value}
              disabled={loading}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  procesoCas.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  procesoCas.setValue(newValue.inputValue);
                } else {
                  procesoCas.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="procesoCas"
              name='procesoCas'
              options={(selects && selects.procesosCas) ? selects.procesosCas : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Proceso CAS N°" variant="outlined" />
              )}
            />

          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              value={depende.value}
              disabled={loading}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  depende.setValue(newValue);
                } else if (newValue && newValue.inputValue) {
                  depende.setValue(newValue.inputValue);
                } else {
                  depende.setValue(newValue.name);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="depende"
              name='depende'
              options={(selects && selects.cargos) ? selects.cargos : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => option.name}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Depende de" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MyInput
              name='observacion'
              label='Observacion' toCase='upper' controller={observacion} disabled={loading} rows={5} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='email'
              label='Email'
              controller={email} disabled={loading} />
          </Grid>

          {id && (
            <Grid item xs={12} md={3}>
              <InputBox>
                <FormControlLabel control={<Switch checked={baja}
                  onChange={() => { setBaja(!baja); fechaBaja.setValue(null); console.log(baja) }} color='primary' />}
                  label={!baja ? 'DAR DE BAJA' : 'ACTIVAR USUARIO'} />
              </InputBox>
            </Grid>
          )}

          {baja && (
            <Grid item xs={12} md={3}>
              <MyDatePicker name='baja' label='Fecha de baja' controller={fechaBaja} disabled={loading} />
            </Grid>
          )}


          <Grid item xs={12} md={3}>
            <InputBox>
              <FormControlLabel control={<Switch checked={enRiesgo}
                onChange={() => { setEnRiesgo(!enRiesgo)}} color='primary' />}
                label='Persona de Riesgo (Comorbilidades COVID)' />
            </InputBox>
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
