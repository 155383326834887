import styled from 'styled-components'

export const Export = styled.a`
    display: block;
    background-color: green;
    margin-top: 16px;
    text-align: center;
    font-size: 1.1rem;
    padding: 1.1rem 0;
    border-radius: 5px;
    color: white;
`