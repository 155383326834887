import styled from 'styled-components'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

export const StyledSelect = styled(Select)`
    margin-top: 18px;
    && .autocomplete__control {
        padding: .5rem 0;
    }
    && .autocomplete__menu {
        z-index: 115;
    } 
`

export const StyledCreatable = styled(CreatableSelect)`
    margin-top: 18px;
    && .autocomplete__control {
        padding: .5rem 0;
    }
    && .autocomplete__menu {
        z-index: 115;
    } 
`