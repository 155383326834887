import React, {Fragment} from 'react'
import {
    Grid,
    Box,
    Typography,
    CircularProgress,
} from '@material-ui/core'
import moment from 'moment'

import { MyPaper } from '../../components/MyPaper'
import { MyDatePicker } from '../../components/MyDatePicker'
import { MyButtton } from '../../components/MyButton'

import { useInputValue } from '../../hooks/useInputValue'
import { useFetch } from '../../hooks/useFetch'
import swal from 'sweetalert'

export const AsistenciaGuardar = () => {

    const title = 'Guardar asistencias en base de datos'
    // const [error, setError] = useState(undefined)
    const {loading, getData} = useFetch()

    const fechaInicio = useInputValue(null)
    const fechaFin = useInputValue(null)

    const handleSubmit = async (event) => {
        event.preventDefault()

        let isValid = true

        if(!fechaInicio.value) {
            fechaInicio.setError('Campo requerido.')
            isValid = false
        }
        if(!fechaFin.value) {
            fechaFin.setError('Campo requerido.')
            isValid = false
        }

        if(isValid) {
            const body = {
                fechaInicio: moment(fechaInicio.value).format('D-M-YYYY'),
                fechaFin: moment(fechaFin.value).format('D-M-YYYY'),
            }
            const response = await getData({ source: '/asistencias', body: body, json: true, method: 'POST' })
            if(response.status === 200) {
                swal('Asistencias guardadas con exito.', {icon: 'success'})
            } else {
                swal(response.message, {icon: 'error'})
            }
        }
    }

    return (
        <MyPaper title={title}>

            <form
                noValidate
                onSubmit={handleSubmit}
            >

                <Grid container item spacing={2}>

                    <Grid item xs={12} md={4} lg={5}>

                        <MyDatePicker
                            name="fechaInicio"
                            label="Fecha Inicial"
                            controller={fechaInicio}
                            required={true}
                            disabled={loading}
                        />

                    </Grid>

                    <Grid item xs={12} md={4} lg={5}>

                        <MyDatePicker
                            name="fechaFin"
                            label="Fecha Final"
                            controller={fechaFin}
                            required={true}
                            disabled={loading}
                        />

                    </Grid>


                    <Grid item xs={12} md={4} lg={2}>

                        <MyButtton
                            title="GUARDAR"
                            loading={loading}
                        />

                    </Grid>

                </Grid>

                {loading && <Box mt={2}>
                    <Grid container item justify="center" alignItems="center" xs={12}>
                        <Fragment>
                            <CircularProgress />
                            <Box ml={2}>
                                <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                            </Box>
                        </Fragment>
                    </Grid>
                </Box>}

            </form>

        </MyPaper>
    )

}