import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyAutocomplete } from '../../../components/MyAutocomplete';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [title, setTitle] = useState('Registrar usuario');
  const [user, setUser] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [empleados, setEmpleados] = useState([]);

  useEffect(() => {
    id && setTitle('Editar usuario');

    (async function() {
      const [selects, _user] = await Promise.all([
        getData({ source: '/users/selects', bg: true }),
        id && getData({ source: `/users/${id}/show` }),
      ]);
      if (selects.status === 200) {
        setRoles(selects.data.roles);
        setEmpleados(
          selects.data.empleados.map(e => {
            return {
              value: e.id,
              label: `${e.dni} ${e.apellido_paterno} ${e.apellido_materno} ${e.nombres}`,
            };
          }),
        );
      }
      id && _user.status === 200 && setUser(_user.data);
    })();
  }, [id, getData]);

  return { title, user, roles, empleados };
};

export const UserRegister = ({ id }) => {
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, user, roles, empleados } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const email = useInputValue('');
  const password = useInputValue('');
  const name = useInputValue('');
  const role = useInputValue('');
  const status = useInputValue('1');

  const [empleado, setEmpleado] = useState(undefined);

  // Funciones y Callbacks
  const setDataF = (a, b) => {
      email.setValue(a.email);
      name.setValue(a.name || '');
      role.setValue(a.role);
      status.setValue(a.status);

      let tmp = b.filter(item => item.value === a.empleado);
      setEmpleado(tmp[0]);
    },
    setData = useCallback(setDataF, []);

  useEffect(() => {
    user && setData(user, empleados);
  }, [user, empleados, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    setError(undefined);

    if (!email.value || !role.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      email: email.value,
      password: password.value,
      name: name.value,
      empleado: empleado ? empleado.value : null,
      role: role.value,
      status: status.value,
    };

    let message = undefined;
    let response = null;
    if (id) {
      message = 'Usuario actualizado con exito.';
      response = await getData({ source: `/users/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Usuario registrado con exito.';
      response = await getData({ source: '/users/', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput
              name='email'
              label='Email'
              type='email'
              toCase='lower'
              autoFocus={true}
              controller={email}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='password' label='Clave' type='password' controller={password} required={true} disabled={loading} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='name' label='Alias' toCase='upper' controller={name} disabled={loading} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MySelect name='role' label='Rol' controller={role} required={true} disabled={loading} data={roles} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyAutocomplete
              name='empleado'
              label='Empleado'
              options={[{ value: '', label: '' }].concat(empleados)}
              data={empleado}
              setData={setEmpleado}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MySelect
              name='status'
              label='Estado'
              controller={status}
              required={true}
              disabled={loading}
              data={[
                { id: 1, name: 'ACTIVO' },
                { id: 0, name: 'INACTIVO' },
              ]}
            />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
