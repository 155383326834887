import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Button,
  Checkbox,
} from "@material-ui/core";
import swal from "sweetalert";

import { navigate } from "@reach/router";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MyPaper } from "../../../components/MyPaper";
import { MyInput } from "../../../components/MyInput";
import { MyAutocomplete } from "../../../components/MyAutocomplete";

import { useInputValue } from "../../../hooks/useInputValue";
import { useFetch } from "../../../hooks/useFetch";

import { UserContext } from "../../../contexts/UserContext";
import { set } from "date-fns";

const useInitial = (id, getData) => {
  const [title, setTitle] = useState("Crear Control de Vigilancia");
  const [IE, setIE] = useState(null);

  useEffect(() => {
    (async function () {
      const [_IE] = await Promise.all([
        id && getData({ source: `/institucion/${id}` }),
      ]);
      if (_IE && id) {
        setIE(_IE.data);
      }

    })();
  }, [id, getData]);
  return {
    IE,
  };
};

export const InstitucionEducativaRegister = ({ id }) => {
  const [title, setTitle] = useState(id ? 'Registrar Institución Educativa' : 'Editar Institución Educativa')
  const [editable, setEditable] = useState(0);
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);

  const [empleado, setEmpleado] = useState(undefined);
  let codigo_local = useInputValue(0);
  let codigo_modular = useInputValue("");
  let nombre = useInputValue("");
  let modalidad = useInputValue("");
  let nivel = useInputValue("");
  let red = useInputValue(0);
  let distrito = useInputValue("");
  let direccion = useInputValue("");
  let dni = useInputValue("");
  let director = useInputValue("");
  const {
    IE
  } = useInitial(id, getData);

  // Handles
  useEffect(() => {
    if (IE) {
      console.log(IE)
      codigo_local.setValue(IE.codigo_local);
      codigo_modular.setValue(IE.codigo_modular);
      nombre.setValue(IE.nombre);
      modalidad.setValue(IE.modalidad);
      nivel.setValue(IE.nivel);
      red.setValue(IE.red);
      distrito.setValue(IE.distrito);
      direccion.setValue(IE.direccion);
      dni.setValue(IE.dni);
      director.setValue(IE.director)
    }
  }, [IE]);

  useEffect(() => {
    // if (user.value.empleado && empleados.length) {
    //   // console.log('user ', user, 'empleados ', empleados)
    //   // console.log('vigilancia', vigilancia)
    //   const tmp = empleados.filter(
    //     (item) => item.value === user.value.empleado
    //   );
    //   _empleado.setValue(tmp[0].label);
    //   setEmpleado(tmp[0]);
    // }
  }, []);

  useEffect(() => {
    // if (empleado) {
    //   cargo.setValue(empleado.cargo);
    //   area.setValue(empleado.area_equipo);
    // }
  }, []);

  // Manejador de envio del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      codigo_local.value === 0 || codigo_modular.value.lenght === 0 || nombre.value.lenght === 0 ||
      modalidad.value.lenght === 0 || nivel.value.lenght === 0 || red.value === 0 ||
      distrito.value.lenght === 0 || direccion.value.lenght === 0 || dni.value.lenght === 0 ||
      director.value.lenght === 0

    ) {
      swal("Debe llenar los campos requeridos.", { icon: "error" });
      return;
    }
    const body = {
      codigo_local: codigo_local.value,
      codigo_modular: codigo_modular.value,
      nombre: nombre.value,
      modalidad: modalidad.value,
      nivel: nivel.value,
      red: red.value,
      distrito: distrito.value,
      direccion: direccion.value,
      dni: dni.value,
      director: director.value,
    };
    setError(undefined);
    let message = undefined;
    let response = undefined;
    if (id) {
      message = "Institución Educativa actualizada con éxito.";
      response = await getData({
        source: `/institucion/${id}`,
        body: body,
        json: true,
        method: "PUT",
      });
    } else {
      message = "Institución Educativa creada con éxito.";
      response = await getData({
        source: "/institucion",
        body: body,
        json: true,
        method: "POST",
      });
    }
    if (response.status === 200) {
      await swal(message, { icon: "success" });
      if (id) window.history.back();
      else navigate("/instituciones-educativas");
    } else {
      setError(response.message);
    }
  };
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <MyInput
                name="codigo_local"
                label="Codigo Local"
                controller={codigo_local}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="codigo_modular"
                label="Codigo Modular"
                controller={codigo_modular}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="nombre"
                label="Nombre"
                controller={nombre}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="modalidad"
                label="Modalidad"
                controller={modalidad}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="nivel"
                label="Nivel"
                controller={nivel}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="red"
                label="Red"
                controller={red}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="distrito"
                label="Distrito"
                controller={distrito}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="direccion"
                label="Direccion"
                controller={direccion}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="dni"
                label="DNI"
                controller={dni}
                disabled={loading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyInput
                name="director"
                label="Director"
                controller={director}
                disabled={loading}
                required={true}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
                </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading || editable !== 0}
              >
                Guardar
                </Button>
            </Grid>

          </Grid>
        </form>
      </MyPaper>
    </Fragment>
  );
};

