import React from 'react';

import { MyDataTable } from '../../../components/MyDataTable';

export const ActaVerificacionMain = () => {
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Desplazamiento',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'institucion_educativa',
      label: 'Institucion Educativa',
      options: {
        filter: true,
      },
    },
    {
      name: 'codigo_local',
      label: 'Codido de Local',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha',
      label: 'Fecha',
      options: {
        filter: true,
      },
    },
    {
      name: 'hora',
      label: 'Hora',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <MyDataTable
      title='Actas de Verificacion'
      source='/actas-verificacion'
      customColumns={customColumns}
      canRegister={false}
      canDelete={false}
      pdf={true}
      document='acta_verificacion'
    />
  );
};
