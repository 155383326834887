import React, { useState, useEffect, Fragment } from 'react'
import {
    Grid,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import swal from 'sweetalert'
import moment from 'moment'

import { MyPaper } from '../../../components/MyPaper'
import { MyInput } from '../../../components/MyInput'
import { MyDatePicker } from '../../../components/MyDatePicker'

import { useInputValue } from '../../../hooks/useInputValue'
import { useFetch } from '../../../hooks/useFetch'

const useInitial = (id, getData) => {
    const [title, setTitle] = useState('Registrar feriado')
    const [feriado, setFeriado] = useState(undefined)

    useEffect(() => {
        if(id) {
            setTitle('Editar feriado');

            (async function() {
                const _feriado = await getData({ source: `/feriados/${id}/show` });
                _feriado.status === 200 && setFeriado(_feriado.data)
            })();
        }
    }, [id, getData])

    return {title, feriado}
}

export const FeriadoRegister = ({id}) => {

    const [error, setError] = useState(undefined)
    const {loading, getData} = useFetch()
    const {title, feriado} = useInitial(id, getData)

    // Controladores para los campos de formulario
    const fecha = useInputValue(null)
    const descripcion = useInputValue('')

    // Funciones y Callbacks
    useEffect(() => {
        if(feriado) {
            fecha.setValue(moment(feriado.fecha, 'D-M-YYYY'))
            descripcion.setValue(feriado.descripcion)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feriado])

    // Manejador de envio del formulario
    const handleSubmit = async (event) => {
        event.preventDefault()
        setError(undefined)

        if(
            !fecha.value
            || !descripcion.value 
        ) {
            swal('Debe llenar los campos requeridos.', { icon: "error" })
            return;
        }

        const body = {
            fecha: moment(fecha.value).format('YYYY-M-D'),
            descripcion: descripcion.value,
        }
        
        let message = undefined
        let response = null
        if(id) {
            message = 'Feriado actualizado con exito.'
            response = await getData({ source: `/feriados/${id}`, body: body, json: true, method: 'PUT' })
        } else {
            message = 'Feriado registrado con exito.'
            response = await getData({ source: '/feriados/', body: body, json: true, method: 'POST' })
        }
        if(response.status === 200) {
            await swal(message, { icon: "success" })
            window.history.back()
        } else {
            setError(response.message)
        }

    }

    return (

        <MyPaper title={title}>

            <form
                noValidate
                onSubmit={handleSubmit}
            >

                <Grid container item spacing={2}>

                    <Grid item xs={12} md={6}>
                        <MyDatePicker
                            name="fecha"
                            label="Fecha"
                            controller={fecha}
                            required={true}
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <MyInput
                            name="descripcion"
                            label="Descripcion"
                            toCase="upper"
                            controller={descripcion}
                            required={true}
                            disabled={loading}
                        />
                    </Grid>

                    <Grid container item justify="flex-end" alignItems="center" spacing={2}>

                        <Grid container item justify="flex-start" alignItems="center" xs={12} md={6}>
                            {loading &&
                                <Fragment>
                                    <CircularProgress />
                                    <Box ml={2}>
                                        <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                                    </Box>
                                </Fragment>
                            }
                            {error && <Typography variant="h6" color="error">{error}</Typography>}
                        </Grid>

                        <Grid item xs={6} md={3}>
                            
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={() => window.history.back()}
                            >
                                Atras
                            </Button>

                        </Grid>

                        <Grid item xs={6} md={3}>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                            >
                                Guardar
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>

            </form>

        </MyPaper>

    )

}