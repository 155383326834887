import React, { useState, Fragment, useEffect, useRef, useContext } from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
// table imports
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// Fin
// import TableToExcel from '@linways/table-to-excel';

// import { TableContainer, StyledTable, Legend, ExportExcel } from './styles';

import { MyDivider } from '../../components/MyDivider';
import { MyPaper } from '../../components/MyPaper';
import { MyDatePicker } from '../../components/MyDatePicker';
import { MyButtton } from '../../components/MyButton';
// import { MyInput } from '../../components/MyInput';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

import { UserContext } from '../../contexts/UserContext';

// Table

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.dni}
        </TableCell>
        <TableCell>{row.empleado}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Periodos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Mes/Año</TableCell>
                    <TableCell>Fecha de Salida</TableCell>
                    <TableCell align="right">Fecha de Retorno</TableCell>
                    <TableCell align="right">Total Dias</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {
                      moment(row.fecha_salida, "DD-MM-YYYY").format("MMMM/YYYY")
                    }
                  </TableCell>
                  <TableCell>
                    {
                      row.fecha_salida
                    }
                  </TableCell>
                  <TableCell align="right">{ (row.fecha_retorno != null && row.fecha_retorno != undefined ) ? row.fecha_retorno : 'Indefinido' }</TableCell>
                  <TableCell align="right">
                    {
                      (moment.duration(moment(row.fecha_retorno, "DD-MM-YYYY").diff(moment(row.fecha_salida, "DD-MM-YYYY"))).asDays())
                        ? moment.duration(moment(row.fecha_retorno, "DD-MM-YYYY").diff(moment(row.fecha_salida, "DD-MM-YYYY"))).asDays()
                        : 'Indefinido'
                    }
                  </TableCell>
                </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    empleado: PropTypes.string.isRequired,
    fecha_salida: PropTypes.string.isRequired,
    fecha_retorno: PropTypes.string,
    dni: PropTypes.string.isRequired
  }).isRequired,
};

// Fin

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: 'auto'
  },
});

function getKeyObject(obj, start) {
  let name = '';
  for ( let key in obj ) {
    if ( start ) {
      name = key;
      break;
    } else {
      name = key;
    }
  }
  return name;
}

function getMesAnio(mesanio) {
  moment.locale('es');
  const mes = mesanio.split('-')[0];
  const anio = mesanio.split('-')[1];
  let mesString = moment(mes, 'MM').format('MMMM');
  return `${mesString} del ${anio}`;
}

export const VacionesPersonal = () => {
  const { user } = useContext(UserContext);
  const gridWidth = useRef(null);
  const title = 'Vacaciones del Personal';
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const [tableBody, setTableBody] = useState(undefined);
  const fechaInicio = useInputValue(null);
  const fechaFin = useInputValue(null);

  // Paginator

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fin

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    if (fechaInicio.value && fechaFin.value) {
      setTableBody(undefined);
      let url = `/desplazamientos/vacaciones`;
      if ( user.value.name != 'ADMINISTRATOR' ) {
        const empleado = await getData({
          source: `/empleados/${user.value.empleado}/show`
        });
        url += `/${empleado.data.cargo}`;
      }
      const response = await getData({
        source: `${url}`
        // source: `/asistencias/${moment(fechaInicio.value).format('Y-MM-DD')}/${moment(fechaFin.value).format('Y-MM-DD')}`,
      });
      if (response.status === 200) {
        const time1 = moment(fechaInicio.value).format('YYYY-MM-DD');

        const time2 = moment(fechaFin.value).format('YYYY-MM-DD');

        let test = Object.values(response.data).filter( vac => {
          if ( vac.fecha_salida >= time1 && vac.fecha_retorno <= time2 && vac.fecha_retorno != null ) {
            return vac;
          }
        } );
        setTableBody(test);
        setError(response.message);
      }
    }
  };

  // Fechas filtro

  useEffect(() => {
    if (fechaInicio.value) {
      fechaInicio.setError('');
      if (fechaFin.value) {
        const start = moment(fechaInicio.value);
        const end = moment(fechaFin.value);
        const diff = end.diff(start, 'days');
        if (diff > 30) {
          fechaInicio.setError('El rango de fechas no puede superar los 31 dias');
          fechaInicio.setValue(null);
        }
        if (diff < 0) {
          fechaInicio.setError('La fecha inicial no puede ser mayor que la final');
          fechaInicio.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio.value]);

  useEffect(() => {
    if (fechaFin.value) {
      fechaFin.setError('');
      if (fechaInicio.value) {
        const start = moment(fechaInicio.value);
        const end = moment(fechaFin.value);
        const diff = end.diff(start, 'days');
        if (diff > 30) {
          fechaFin.setError('El rango de fechas no puede superar los 31 dias');
          fechaFin.setValue(null);
        }
        if (diff < 0) {
          fechaFin.setError('La fecha final no puede ser menor que la inicial');
          fechaFin.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaFin.value]);

  // Fin

  return (
    <MyPaper title={title}>

      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2} ref={gridWidth}>
          <Grid item xs={12} md={4}>
            <MyDatePicker
              name='fechaInicio'
              label='Fecha de Salida'
              controller={fechaInicio}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MyDatePicker name='fechaFin' label='Fecha de Regreso' controller={fechaFin} required={true} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MyButtton title='Consultar' loading={loading} />
          </Grid>
        </Grid>

        <MyDivider />

        {loading && (
          <Box mt={2}>
            <Grid container item justify='center' alignItems='center' xs={12}>
              <Fragment>
                <CircularProgress />
                <Box ml={2}>
                  <Typography variant='h6' color='textSecondary'>
                    Procesando...
                  </Typography>
                </Box>
              </Fragment>

              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
          </Box>
        )}

        { tableBody && (
          <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>DNI</TableCell>
                  <TableCell>Nombre y Apellidos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <Row key={index + 1} row={row} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan="5">
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={tableBody.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) }
      </form>
    </MyPaper>
  );
};
