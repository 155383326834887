import React from 'react';
import { Tooltip, Fab, Paper } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';



export const CustomToolbarSelect = ({ handleClickAction }) => {
  return (
    <Paper>
      <Tooltip title='Aprobar'>
        <Fab variant='extended' size='small' color='primary' onClick={()=>handleClickAction(1)}>
          <DoneOutlineIcon />
        </Fab>
      </Tooltip>
      <Tooltip title='Rechazar'>
        <Fab variant='extended' size='small' color='secondary' onClick={()=>handleClickAction(2)}>
          <NotInterestedIcon />
        </Fab>
      </Tooltip>
    </Paper>

  );
};
