import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';
import moment from 'moment';

import { MyPaper } from '../../../components/MyPaper';
import { MyDatePicker, MyTimePicker } from '../../../components/MyDatePicker';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [title, setTitle] = useState('Registrar acta de verificacion');
  const [actaVerificacion, setActaVerificacion] = useState(undefined);

  useEffect(() => {
    id && setTitle('Editar acta de verificacion');

    (async function() {
      const [acta] = await Promise.all([id && getData({ source: `/actas-verificacion/${id}/show` })]);
      id && acta.status === 200 && setActaVerificacion(acta.data);
    })();
  }, [id, getData]);

  return { title, actaVerificacion };
};

export const ActaVerificacionRegister = ({ id }) => {
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, actaVerificacion } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const fecha = useInputValue(null);
  const hora = useInputValue(null);
  const institucionEducativa = useInputValue('');
  const codigoLocal = useInputValue('');
  const planificacionInstitucional = useInputValue('');
  const gestionParticipacion = useInputValue('');

  // Funciones y Callbacks
  const setDataF = a => {
      fecha.setValue(a.fecha ? moment(a.fecha).format('L') : null);
      if (a.hora) {
        const tmp = moment(a.fecha + ' ' + a.hora).format('llll');
        hora.setValue(tmp);
      }

      institucionEducativa.setValue(a.institucion_educativa);
      codigoLocal.setValue(a.codigo_local);

      planificacionInstitucional.setValue(a.planificacion_institucional || '');
      gestionParticipacion.setValue(a.gestion_participacion || '');
    },
    setData = useCallback(setDataF, []);

  useEffect(() => {
    actaVerificacion && setData(actaVerificacion);
  }, [actaVerificacion, setData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!fecha.value || !hora.value || !planificacionInstitucional.value || !gestionParticipacion.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      fecha: moment(fecha.value).format('YYYY-MM-DD'),
      hora: moment(hora.value).format('H:mm:ss'),
      planificacionInstitucional: planificacionInstitucional.value,
      gestionParticipacion: gestionParticipacion.value,
    };
    setError(undefined);
    let message = undefined;
    let response = null;
    if (id) {
      message = 'Acta de verificacion actualizada con exito.';
      response = await getData({ source: `/actas-verificacion/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Acta de verificacion registrada con exito.';
      response = await getData({ source: '/actas-verificacion/', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyDatePicker name='fecha' label='Fecha' controller={fecha} required={true} disabled={loading} disablePast={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyTimePicker name='hora' label='Hora' controller={hora} required={true} disabled={loading} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='institucionEducativa' label='Institucion Educativa' controller={institucionEducativa} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='codigoLocal' label='Codigo Local' controller={codigoLocal} disabled={true} />
          </Grid>

          <Grid item xs={12}>
            <MyInput
              name='planificacionInstitucional'
              label='Planificacion Institucional'
              toCase='upper'
              controller={planificacionInstitucional}
              required={true}
              disabled={loading}
              rows={5}
            />
          </Grid>

          <Grid item xs={12}>
            <MyInput
              name='gestionParticipacion'
              label='Gestion de Participacion'
              toCase='upper'
              controller={gestionParticipacion}
              required={true}
              disabled={loading}
              rows={5}
            />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
