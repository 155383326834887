import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';
import Geolocation from 'react-geolocation';

import { MyPaper } from '../../components/MyPaper';
import { useFetch } from '../../hooks/useFetch';
import { Loading } from '../../components/Loading';

const useInitial = getData => {
  const [ubicaciones, setUbicaciones] = useState([]);
  useEffect(() => {
    (async function() {
      const result = await getData({ source: `/desplazamientos/ubicaciones` });
      result.status === 200 && setUbicaciones(result.data);
    })();
  }, [getData]);

  return { ubicaciones };
};

export const Georeferencia = () => {
  const title = 'Georeferencia';
  const { loading, getData } = useFetch();
  const { ubicaciones } = useInitial(getData);

  // Controladores para los campos de formulario
  const [lat, setLat] = useState(-12.0289753);
  const [lng, setLng] = useState(-77.0128055);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <MyPaper title={title}>
          <Grid container>
            <Grid item xs={12}>
              <Geolocation
                onSuccess={data => {
                  setLat(data.coords.latitude);
                  setLng(data.coords.longitude);
                }}
              />
              <Map lat={lat} lng={lng} ubicaciones={ubicaciones} />
            </Grid>
          </Grid>
        </MyPaper>
      )}
    </Fragment>
  );
};

const AnyReactComponent = ({ text }) => (
  <Tooltip title={text}>
    <RoomIcon color='secondary' fontSize='large' />
  </Tooltip>
);

const Map = ({ lat, lng, zoom = 13, ubicaciones }) => {
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDUckEqMGN6Llf4Zk-JXaIa-lsh3jz-noc' }}
        defaultCenter={{ lat: -12.0289753, lng: -77.0128055 }}
        defaultZoom={zoom}
        center={{
          lat: Number(lat),
          lng: Number(lng),
        }}
      >
        {ubicaciones &&
          ubicaciones.map((ubicacion, index) => (
            <AnyReactComponent
              key={index}
              lat={Number(ubicacion.lat)}
              lng={Number(ubicacion.lng)}
              text={`${ubicacion.empleado} (${ubicacion.hora})`}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};
