import styled from 'styled-components'
import {
    Paper,
} from '@material-ui/core'

export const StyledPaper = styled(Paper)`
    padding: 1rem;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.1) !important;

    && .title-container {
        display: flex;
        position: relative;
    }

    && .avatar {
        background: linear-gradient(60deg, #ec407a, #d81b60);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
        padding: 2rem;
        position: relative;
        top: -2rem;
        margin-right: 2rem;
    }

    && .title {
        font-size: 1.3rem;
        font-weight: 300;
    }
`