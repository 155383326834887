import React, { useState, useEffect,Fragment, useContext } from 'react';
import { Link } from '@reach/router';

import { Export } from './styles';
import { MyDataTable } from '../../../components/MyDataTable';
import { Grid, Box } from '@material-ui/core';
import { MyButtton } from '../../../components/MyButton';
import { useInputValue } from "../../../hooks/useInputValue";

import { UserContext } from '../../../contexts/UserContext';

export const NuevasTareasMain = ( asignacion, empleado ) => {
  const [desplazamiento, setDesplazamiento]= useState();
  const [empleadoAsignado, setEmpleadoAsignado]= useState();

  const idDesplazamiento = useInputValue("");
  const { BASE_URL, user } = useContext(UserContext);
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'accion',
      label: 'Acción',
      options: {
        filter: true,
      },
    },
    {
      name: 'fec_inicio',
      label: 'Fecha de Inicio',
      options: {
        filter: true,
      },
    },
    {
      name: 'fec_fin',
      label: 'Fecha Final',
      options: {
        filter: true,
      },
    },
    {
      name: 'employee_full_name',
      label: 'Especialista',
      options: {
        filter: true,
      },
    },
    {
      name: 'jefe',
      label: 'Encargado',
      options: {
        filter: true,
      },
    },
    {
      name: 'descripcion',
      label: 'Descripción',
      options: {
        filter: true,
      },
    },
    {
      name: 'observaciones',
      label: 'Actividades',
      options: {
        filter: true,
      },
    },
    // {
    //   name: 'created',
    //   label: 'Creado',
    //   options: {
    //     filter: true,
    //   },
    // },
  ];
  useEffect(() => {
    if (asignacion){
       setDesplazamiento(asignacion.asignacion)
       idDesplazamiento.setValue(asignacion.asignacion.id)
    }
  })
  useEffect(() => {
    if (empleado){
       setEmpleadoAsignado(empleado)
    }
  })
  return (
    <Fragment>
    {desplazamiento&&(
      <MyDataTable
        title='Tareas'
        source={'/tasks'}
        complementSource={'/all/'+idDesplazamiento.value}
        customColumns={customColumns}
        canRegister={true}
        dataAsignacion={idDesplazamiento.value}

      />
    )}
      
    </Fragment>
  );
};
