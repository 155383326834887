import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../components/MyPaper';
import { MyInput } from '../../components/MyInput';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';
import { UserContext } from '../../contexts/UserContext';

const useInitial = (id, getData) => {
  const [_user, setUser] = useState(undefined);
  useEffect(() => {
    if (id) {
      (async function() {
        const user = await getData({ source: `/users/${id}/show` });
        id && user.status === 200 && setUser(user.data);
      })();
    }
  }, [id, getData]);

  return { _user };
};

export const Ajuste = ({ id }) => {
  const title = 'Ajustes de cuenta';
  const { user } = useContext(UserContext);
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { _user } = useInitial(user.value.id, getData);

  // Controladores para los campos de formulario
  const email = useInputValue('');
  const password = useInputValue('');

  // Funciones y Callbacks
  useEffect(() => {
    _user && email.setValue(_user.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_user]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    setError(undefined);
    if (!email.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }
    const body = {
      email: email.value,
      password: password.value,
    };

    let response = await getData({ source: `/users/${user && user.value.id}/ajustes`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Cuenta actualizada con exito.', { icon: 'success' });
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput
              name='email'
              label='Usuario'
              type='email'
              controller={email}
              required={true}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput name='password' label='Clave' type='password' controller={password} disabled={loading} />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
