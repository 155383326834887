import React, { useState, Fragment, useEffect, useCallback, useRef, useContext } from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import moment from 'moment';
import TableToExcel from '@linways/table-to-excel';

import { TableContainer, StyledTable, Legend, ExportExcel } from './styles';

import { MyDivider } from '../../components/MyDivider';
import { MyPaper } from '../../components/MyPaper';
import { MyDatePicker } from '../../components/MyDatePicker';
import { MyButtton } from '../../components/MyButton';
import { MyInput } from '../../components/MyInput';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

import { UserContext } from '../../contexts/UserContext';
import Pdf from "react-to-pdf";
// import { StyledButton } from './styles'


export const Asistencia = () => {
  const { user } = useContext(UserContext);
  const gridWidth = useRef(null);
  const title = 'Consultar Asistencias y Trabajo Remoto';
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const [open, setOpen] = useState(false);
  const [observacion, setObservacion] = useState(undefined);
  const [openExtra, setOpenExtra] = useState(false);
  const [extra, setExtra] = useState([]);

  const fechaInicio = useInputValue(null);
  const fechaFin = useInputValue(null);
  const buscar = useInputValue('');
  const [tipoServidor, setTipoServidor] = useState('');

  const [head, setHead] = useState(undefined);
  const [tableBody, setTableBody] = useState(undefined);

  const [backup, setBackup] = useState(undefined);
  const [tardanza, setTardanza] = useState(false);
  const [today, setToday] = useState(false);

  const [misAsistencias, setMisAsistencias] = useState(false);
  const rootElement = document.getElementById("root")
  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
  };




  const filtrar = (filtro, valor) => {
    let tmp = [];
    switch (filtro) {
      case 'tardanza':
        setTardanza(valor);
        if (valor) {
          tmp = backup.filter(item => Object.values(item.fechas).filter(fecha => !isNaN(fecha.valor) && fecha.origen === 'M').length);
          setTableBody(tmp);
        } else {
          setTableBody(backup);
        }
        break;
      case 'tipoServidor':
        if (valor) {
          tmp = backup.filter(item => item.tipo_servidor === valor);
          setTableBody(tmp);
        } else {
          setTableBody(backup);
        }
        break;
      case 'busqueda':
        if (valor) {
          tmp = backup.filter(
            item => (item.dni && item.dni.search(valor) !== -1) || (item.apellidos_nombres && item.apellidos_nombres.search(valor) !== -1),
          );
          setTableBody(tmp);
        } else {
          setTableBody(backup);
        }
        break;
      case 'asistencia':
        if (valor) {
          tmp = backup.filter(
            item => (item.dni && item.dni.search(valor) !== -1) || (item.apellidos_nombres && item.apellidos_nombres.search(valor) !== -1),
          );
          setTableBody(tmp);
        } else {
          setTableBody(backup);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Handles
   */

  useEffect(() => {
    filtrar('busqueda', buscar.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscar.value]);

  useEffect(() => {
    if (fechaInicio.value) {
      fechaInicio.setError('');
      if (fechaFin.value) {
        const start = moment(fechaInicio.value);
        const end = moment(fechaFin.value);
        const diff = end.diff(start, 'days');
        if (diff > 30) {
          fechaInicio.setError('El rango de fechas no puede superar los 31 dias');
          fechaInicio.setValue(null);
        }
        if (diff < 0) {
          fechaInicio.setError('La fecha inicial no puede ser mayor que la final');
          fechaInicio.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio.value]);

  useEffect(() => {
    if (fechaFin.value) {
      fechaFin.setError('');
      if (fechaInicio.value) {
        const start = moment(fechaInicio.value);
        const end = moment(fechaFin.value);
        const diff = end.diff(start, 'days');
        if (diff > 30) {
          fechaFin.setError('El rango de fechas no puede superar los 31 dias');
          fechaFin.setValue(null);
        }
        if (diff < 0) {
          fechaFin.setError('La fecha final no puede ser menor que la inicial');
          fechaFin.setValue(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaFin.value]);

  const handleSubmit = async event => {
    event.preventDefault();

    let isValid = true;

    if (!fechaInicio.value) {
      fechaInicio.setError('Campo requerido.');
      isValid = false;
    }
    if (!fechaFin.value) {
      fechaFin.setError('Campo requerido.');
      isValid = false;
    }

    if (isValid) {
      setTableBody(undefined);
      setError('');
      setTardanza(false);

      const tmp = getHead(moment(fechaInicio.value), moment(fechaFin.value));
      setHead(tmp);
      let source = ''
      if (misAsistencias) {
        source = `/asistencias/jefe/${moment(fechaInicio.value).format('Y-MM-DD')}/${moment(fechaFin.value).format('Y-MM-DD')}`
      } else {
        source = `/asistencias/${moment(fechaInicio.value).format('Y-MM-DD')}/${moment(fechaFin.value).format('Y-MM-DD')}`
      }


      const response = await getData({
        source: source,
      });
      if (response.status === 200) {
        let res = Object.values(response.data);
        res = res.map(item => {
          let newFec = {};
          Object.keys(item.fechas).map(fecha => {
            if (item.fechas[fecha].hasOwnProperty('origen') && item.fechas[fecha].hasOwnProperty('valor')) {
              newFec[fecha] = item.fechas[fecha];
              return item.fechas[fecha];
            }
          });
          if (newFec) {
            item.fechas = null;
            item.fechas = newFec;
            // console.log(fechas);
          }
          return item;
        });
        // console.log(res);
        setTableBody(res);
        setBackup(res);
        setError(response.message);
        //
      }
    }
  };

  const actualizarTabla = data => {
    const tmp = tableBody.map(item => {
      if (item.dni === data.dni) {
        item.fechas[data.fecha].origen = 'O';
        item.fechas[data.fecha].valor = data.abreviatura;
        item.fechas[data.fecha].descripcion = data.descripcion;
      }
      return item;
    });
    setTableBody(tmp);
  };

  return (
    <MyPaper title={title}>
      <Modal open={open} setOpen={setOpen} observacion={observacion} actualizarTabla={actualizarTabla} getData={getData} />
      <ModalExtra open={openExtra} setOpen={setOpenExtra} extra={extra} />

      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2} ref={gridWidth}>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              label='Asistencias de Hoy'
              control={
                <Switch checked={today}
                  onChange={() => {
                    setToday(!today)
                    if (!today) {
                      fechaInicio.setValue(Date());
                      fechaFin.setValue(Date())
                    }
                  }
                  } />}
            />
          </Grid>
          {user.value.role === 3 && (
            <Grid item xs={12} md={2}>
              <FormControlLabel
                label='Mis Asistencias'
                control={
                  <Switch checked={misAsistencias}
                    onChange={() => { setMisAsistencias(!misAsistencias) }}
                  />}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <MyDatePicker
              name='fechaInicio'
              label='Fecha Inicial'
              controller={fechaInicio}
              required={true}
              disabled={loading || today}
              minDate={user.value.empleado ? moment('2020-02-12') : false}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <MyDatePicker name='fechaFin' label='Fecha Final' controller={fechaFin} required={true} disabled={loading || today} />
          </Grid>

          <Grid item xs={12} md={2}>
            <MyButtton title='Consultar' loading={loading} />
          </Grid>
        </Grid>

        <MyDivider />

        {loading && (
          <Box mt={2}>
            <Grid container item justify='center' alignItems='center' xs={12}>
              <Fragment>
                <CircularProgress />
                <Box ml={2}>
                  <Typography variant='h6' color='textSecondary'>
                    Procesando...
                  </Typography>
                </Box>
              </Fragment>

              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>
          </Box>
        )}

        {tableBody && (
          <Grid container item xs={12} justify='center' alignItems='center'>
            <Legend>
              <div className='ok'>A tiempo</div>
              <div className='f'>Falta</div>
              <div className='t'>Tardanza</div>
              <div className='wk'>Fin de semana</div>
              <div className='o'>Observacion</div>
              <div className='d'>Comision</div>
              <div className='nl'>Feriado</div>
              <div className='tr'>Remoto</div>
            </Legend>
            <MyDivider />
          </Grid>
        )}

        {tableBody && !user.value.empleado && (
          <Box mt={3}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  label='Solo tardanzas'
                  control={<Switch checked={tardanza} onChange={() => filtrar('tardanza', !tardanza)} />}
                />
              </Grid>


              <Grid item xs={12} md={3}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Tipo de Servidor</FormLabel>
                  <RadioGroup
                    aria-label='position'
                    name='tipoServidor'
                    value={tipoServidor}
                    onChange={e => {
                      setTipoServidor(e.target.value);
                      filtrar('tipoServidor', e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel value='' control={<Radio color='primary' />} label='Todos' labelPlacement='end' />
                    <FormControlLabel value='CAS' control={<Radio color='primary' />} label='CAS' labelPlacement='end' />
                    <FormControlLabel value='CAP' control={<Radio color='primary' />} label='CAP' labelPlacement='end' />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}

        {tableBody && (
          <Fragment>
            <Grid container spacing={2} justify='space-between'>
              <Grid item xs={12} md={3}>
                <MyInput name='filtro' label='DNI/Nombre' controller={buscar} toCase='upper' />
              </Grid>
              <Grid item xs={12} md={3}>
                <ExportExcel
                  href='#'
                  onClick={() => {
                    TableToExcel.convert(document.getElementById('asistencias'), {
                      name: `asistencias_${moment(fechaInicio.value).format('DD-MM-Y')}_${moment(fechaFin.value).format('DD-MM-Y')}.xlsx`,
                      sheet: {
                        name: 'asistencias',
                      },
                    });
                    return false;
                  }}
                >
                  EXPORTAR COMO EXCEL
                </ExportExcel>
              </Grid>
              <Grid item xs={12} md={3}>
                <Pdf targetRef={ref} filename="Asistencias.pdf" options={options} scale={0.80}>
                  {({ toPdf }) => <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={toPdf}>Exportar PDF</Button>
                  }
                </Pdf>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {tableBody && (
          <TableContainer maxWidth={gridWidth.current.offsetWidth - 20}>
            <StyledTable id='asistencias' ref={ref}>
              <TableHead head={head} />
              <tbody>
                {tableBody.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.dni}</td>
                      <td className='align-left'>{item.apellidos_nombres}</td>
                      <td>{item.tipo_servidor}</td>
                      {Object.keys(item.fechas).map(fecha => {
                        const isWeeken = moment(fecha, 'D-M-YYYY').isoWeekday() === 6 || moment(fecha, 'D-M-YYYY').isoWeekday() === 7;
                        let classes = isWeeken ? 'wk' : '';
                        if (!isWeeken) {
                          classes += item.fechas[fecha].origen === 'M' && item.fechas[fecha].valor === 'OK' ? ' ok' : '';
                          classes += item.fechas[fecha].origen === 'M' && item.fechas[fecha].valor !== 'OK' ? ' t' : '';
                          classes += item.fechas[fecha].origen === 'M' && item.fechas[fecha].valor === 'TR' ? ' tr' : '';;
                          classes += item.fechas[fecha].origen === null && item.fechas[fecha].valor === 'SCI' ? ' f' : '';
                          classes += item.fechas[fecha].origen === 'O' ? ' o' : '';
                          classes += item.fechas[fecha].origen === 'D' ? ' d' : '';
                          classes += item.fechas[fecha].origen === 'NL' ? ' nl' : '';


                        }
                        return (
                          <td
                            key={fecha}
                            onClick={() => {
                              if (
                                user.value &&
                                !user.value.empleado &&
                                !isWeeken &&
                                !item.fechas[fecha].extra &&
                                (item.fechas[fecha].origen === null || item.fechas[fecha].origen === 'O')
                              ) {
                                setObservacion({
                                  dni: item.dni,
                                  fecha: fecha,
                                  datos: item.fechas[fecha],
                                });
                                setOpen(true);
                              }
                            }}
                            className={classes}
                          >
                            {item.fechas[fecha].extra ? (
                              <span
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => {
                                  setExtra(item.fechas[fecha].extra);
                                  setOpenExtra(true);
                                }}
                              >
                                {item.fechas[fecha].valor === null ? '' : item.fechas[fecha].valor}
                              </span>
                            ) : (
                              <Fragment>{item.fechas[fecha].valor === null ? '' : item.fechas[fecha].valor}</Fragment>
                            )}
                          </td>
                        );
                      })}

                      <td>{item.tardanzas}</td>
                      <td>{item.dias}</td>
                      <td>{item.vacaciones}</td>
                      <td>{item.desplazamientos}</td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </TableContainer>
        )}
      </form>
    </MyPaper>
  );
};

const getHead = (a, b) => {
  const MONTH = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];
  const WEEKDAY = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

  // const diff = b.diff(a, 'days')

  const head = {
    month: [],
  };

  if (a.format('M') === b.format('M')) {
    head.month.push({
      name: MONTH[a.format('M') - 1],
      weekDay: [],
      day: [],
    });
    for (let index = parseInt(a.format('D')); index <= parseInt(b.format('D')); index++) {
      head.month[0].weekDay.push(WEEKDAY[moment(a.format(`YYYY-M-${index > 9 ? index : '0' + index}`)).isoWeekday() - 1]);
      head.month[0].day.push(index);
    }
  } else {
    head.month.push({
      name: MONTH[a.format('M') - 1],
      weekDay: [],
      day: [],
    });
    for (let index = parseInt(a.format('D')); index <= a.endOf('month').format('D'); index++) {
      head.month[0].weekDay.push(WEEKDAY[moment(a.format(`YYYY-M-${index > 9 ? index : '0' + index}`)).isoWeekday() - 1]);
      head.month[0].day.push(index);
    }

    head.month.push({
      name: MONTH[b.format('M') - 1],
      weekDay: [],
      day: [],
    });
    for (let index = 1; index <= parseInt(b.format('D')); index++) {
      head.month[1].weekDay.push(WEEKDAY[moment(b.format(`YYYY-M-${index > 9 ? index : '0' + index}`)).isoWeekday() - 1]);
      head.month[1].day.push(index);
    }
  }

  return head;
};

const TableHead = ({ head }) => {
  return !head ? (
    <Fragment></Fragment>
  ) : (
    <thead>
      <tr>
        <th rowSpan='3'>Nº</th>
        <th rowSpan='3'>DNI</th>
        <th rowSpan='3' className='align-left'>
          APELLIDOS Y NOMBRES
        </th>
        <th rowSpan='3'>TIPO DE SERVIDOR</th>

        {head.month.map((value, index) => (
          <th key={index} colSpan={value.day.length}>
            {value.name}
          </th>
        ))}

        <th rowSpan='3'>TOTAL TARDANZAS</th>
        <th rowSpan='3'>TOTAL FALTAS</th>
        <th rowSpan='3'>TOTAL VACACIONES</th>
        <th rowSpan='3'>TOTAL COMISIONES</th>
      </tr>

      <tr>
        {head.month.map(month =>
          month.weekDay.map((value, index) => {
            const isWeeken = value === 'S' || value === 'D';
            return (
              <th key={index} className={isWeeken ? 'wk' : ''}>
                {value}
              </th>
            );
          }),
        )}
      </tr>

      <tr>
        {head.month.map((month, index) =>
          month.day.map((value, index1) => {
            const isWeeken = head.month[index].weekDay[index1] === 'S' || head.month[index].weekDay[index1] === 'D';
            return (
              <th key={index1} className={isWeeken ? 'wk' : ''}>
                {value}
              </th>
            );
          }),
        )}
      </tr>
    </thead>
  );
};

const Modal = ({ open, setOpen, observacion, actualizarTabla, getData }) => {
  const loading = false;
  const [error, setError] = useState(undefined);
  const abreviatura = useInputValue('');
  const descripcion = useInputValue('');

  const setDataF = a => {
    abreviatura.setValue(a.datos.valor || '');
    descripcion.setValue(a.datos.descripcion || '');
  },
    setData = useCallback(setDataF, []);

  useEffect(() => {
    observacion && setData(observacion);
  }, [observacion, setData]);

  const handleSave = async () => {
    setError('');
    if (!abreviatura.value || !descripcion.value) {
      setError('Debe llenar los campos requeridos.');
      return;
    }

    const body = {
      dni: observacion.dni,
      fecha: observacion.fecha,
      abreviatura: abreviatura.value,
      descripcion: descripcion.value,
    };
    const response = await getData({ source: '/asistencias/observaciones', body: body, json: true, method: 'POST' });
    if (response.status === 200) {
      actualizarTabla(body);
      setOpen(false);
    } else {
      setError(response.message);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{'Observacion'}</DialogTitle>
      <DialogContent>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <MyInput name='abreviatura' label='Abreviatura' toCase='upper' required={true} controller={abreviatura} disabled={loading} />
          </Grid>

          <Grid item xs={12}>
            <MyInput name='descripcion' label='Descripcion' toCase='upper' required={true} controller={descripcion} disabled={loading} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item justify='flex-end' alignItems='center' spacing={2}>
          <Grid item xs={6}>
            <Button type='button' fullWidth variant='contained' color='secondary' disabled={loading} onClick={() => setOpen(false)}>
              Atras
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading} onClick={handleSave}>
              Guardar
            </Button>
          </Grid>

          <Grid container item justify='center' alignItems='center' xs={12}>
            {loading && (
              <Fragment>
                <CircularProgress />
                <Box ml={2}>
                  <Typography variant='h6' color='textSecondary'>
                    Procesando...
                  </Typography>
                </Box>
              </Fragment>
            )}
            {error && (
              <Typography variant='h6' color='error'>
                {error}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const ModalExtra = ({ open, setOpen, extra }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle id='alert-dialog-title'>Detalles</DialogTitle>
      <DialogContent>
        <table border='1' style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px' }}>Motivo de Salida</th>
              <th style={{ padding: '10px' }}>Valor</th>
            </tr>
          </thead>
          <tbody>
            {extra.map((e, i) => (
              <tr key={i}>
                <td style={{ padding: '10px' }}>{e.origen}</td>
                <td style={{ padding: '10px' }}>{e.valor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
};
