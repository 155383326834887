import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Button,
  Checkbox,
} from "@material-ui/core";
import swal from "sweetalert";

import { navigate } from "@reach/router";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MyPaper } from "../../../components/MyPaper";
import { MyInput } from "../../../components/MyInput";
import { MyAutocomplete } from "../../../components/MyAutocomplete";

import { useInputValue } from "../../../hooks/useInputValue";
import { useFetch } from "../../../hooks/useFetch";

import { UserContext } from "../../../contexts/UserContext";
import { set } from "date-fns";

const useInitial = (id, getData) => {
  const [title, setTitle] = useState("Crear Control de Vigilancia");
  const [vigilancia, setVigilancia] = useState(null);
  const [empleados, setEmpleados] = useState([]);


  useEffect(() => {
    id && setTitle("Editar Control");
    (async function () {
      const [empleados, _vigilancia] = await Promise.all([
        getData({ source: "/employees", bg: true }),
        id && getData({ source: `/vigilancia/${id}` }),
      ]);
      if (empleados) {
        let empleadosAux = [];
        empleados.data.empleados.map((empleado) => {
          let aux = {
            value: empleado.value,
            label: empleado.label,
            cargo: empleado.cargo,
            area_equipo: empleado.area_equipo,
          }
          empleadosAux.push(aux)
        })
        setEmpleados(empleadosAux, id);
      }

      if (_vigilancia && id) {
        setVigilancia(_vigilancia.data);
      }

    })();
  }, [id, getData]);
  return {
    title,
    vigilancia,
    empleados,
  };
};

export const VigilanciaRegister = ({ id }) => {
  const [editable, setEditable] = useState(0);
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { user } = useContext(UserContext);
  const [state, setState] = React.useState({
    mascarilla: true,
    protector_facial: true,
    gel_antibacterial: true,
    alcohol: true,
  });
  const {
    title,
    vigilancia,
    empleados,
  } = useInitial(id, getData);
  // Controladores para los campos de formulario

  const [empleado, setEmpleado] = useState(undefined);
  let _empleado = useInputValue("");
  let cargo = useInputValue("");
  let area = useInputValue("");
  let temperatura = useInputValue(0);
  let obs = useInputValue("");

  // Handles
  useEffect(() => {
    if (vigilancia) {
      if (user.value.empleado) {
        setEditable(vigilancia.estado);
      }
      let tmp = [];
      tmp = empleados.filter((item) => item.value === vigilancia.empleado_id);
      setState({
        ...state,
        protector_facial: vigilancia.protector_facial == 1 ? true : false,
        gel_antibacterial: vigilancia.gel_antibacterial == 1 ? true : false,
        alcohol: vigilancia.alcohol == 1 ? true : false,
        mascarilla: vigilancia.mascarilla == 1 ? true : false,
      });
      temperatura.setValue(vigilancia.temperatura);
      obs.setValue(vigilancia.obs!=null?vigilancia.obs:'');
      setEmpleado(tmp[0]);
    }
  }, [vigilancia]);

  useEffect(() => {
    if (user.value.empleado && empleados.length) {
      // console.log('user ', user, 'empleados ', empleados)
      // console.log('vigilancia', vigilancia)
      const tmp = empleados.filter(
        (item) => item.value === user.value.empleado
      );
      _empleado.setValue(tmp[0].label);
      setEmpleado(tmp[0]);
    }
  }, [empleados]);

  useEffect(() => {
    if (empleado) {
      cargo.setValue(empleado.cargo);
      area.setValue(empleado.area_equipo);
    }
  }, [empleado]);

  // Manejador de envio del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !empleado || !obs.value || !temperatura.value

    ) {
      swal("Debe llenar los campos requeridos.", { icon: "error" });
      return;
    }
    const body = {
      empleado_id: empleado.value,
      mascarilla: state.mascarilla,
      protector_facial: state.protector_facial,
      gel_antibacterial: state.gel_antibacterial,
      alcohol: state.alcohol,
      temperatura: parseInt(temperatura.value),
      obs: obs.value,
    };
    setError(undefined);
    let message = undefined;
    let response = undefined;
    if (id) {
      message = "Registro de Control actualizado con éxito.";
      response = await getData({
        source: `/vigilancia/${id}`,
        body: body,
        json: true,
        method: "PUT",
      });
    } else {
      message = "Registro de Control creado con éxito.";
      response = await getData({
        source: "/vigilancia",
        body: body,
        json: true,
        method: "POST",
      });
    }
    if (response.status === 200) {
      await swal(message, { icon: "success" });
      if (id) window.history.back();
      else navigate("/vigilancia");
    } else {
      setError(response.message);
    }
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            {!user.value.empleado && (
              <Grid item xs={12} md={4}>
                <MyAutocomplete
                  name="empleado"
                  label="Empleado"
                  data={empleado}
                  setData={setEmpleado}
                  options={empleados}
                  disabled={loading}
                />
              </Grid>
            )}

            {user.value.empleado && (
              <Grid item xs={12} md={4}>
                <MyInput
                  name="empleado"
                  label="Empleado"
                  controller={_empleado}
                  disabled={true}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <MyInput
                name="cargo"
                label="Cargo"
                controller={cargo}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MyInput
                name="area"
                label="Unidad/Area/Equipo"
                controller={area}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="mascarilla"
                    color="primary"
                    checked={state.mascarilla}
                    onChange={handleChange}
                  />
                }
                label="Mascarilla N95"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="protector_facial"
                    color="primary"
                    checked={state.protector_facial}
                    onChange={handleChange}
                  />
                }
                label="Protector Facial"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="gel_antibacterial"
                    color="primary"
                    checked={state.gel_antibacterial}
                    onChange={handleChange}
                  />
                }
                label="Gel Antibacterial"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="alcohol"
                    color="primary"
                    checked={state.alcohol}
                    onChange={handleChange}
                  />
                }
                label="Guantes Quirúgicos"
              />
            </Grid>
            <Grid item xs={12}>
              <MyInput
                name="temperatura"
                label="Temperatura ºC"
                toCase="upper"
                controller={temperatura}
                disabled={loading}
              /> 
            </Grid>
            <Grid item xs={12}>
              <MyInput
                name="obs"
                label="Detalle Observaciones"
                toCase="upper"
                controller={obs}
                disabled={loading}
                rows={5}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
                </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading || editable !== 0}
              >
                Guardar
                </Button>
            </Grid>

          </Grid>
        </form>
      </MyPaper>
    </Fragment>
  );
};

