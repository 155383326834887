import styled from 'styled-components'
import {
    Drawer
} from '@material-ui/core'

export const StyledDrawer = styled(Drawer)`
    width: ${props => props.width}px;
    z-index: 101 !important;
    transition: .25s ease;
    background: url('/assets/images/bg-1.png') center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    && .MuiPaper-root {
        position: relative;
        background: rgba(0,0,0,.75);
        transition: .25s ease;
        width: ${props => props.width}px;
        overflow-x: hidden;
    }
`