import React from 'react';
import {
    CircularProgress,
    Typography,
} from '@material-ui/core'

import {
    StyledPaper,
} from './styles'

export const Loading = () => {

    return (

        <StyledPaper>

            <CircularProgress />

            <Typography variant="h6" color="textSecondary" className="margin">Cargando...</Typography>

        </StyledPaper>

    )

}