import React from 'react'

import { MyDataTable } from '../../../components/MyDataTable'

export const FichaSintomatologicaMain = () => {

    const customColumns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                display: 'excluded',
            }
        },
        {
            name: 'pregunta',
            label: 'Contenido de la Pregunta',
            options: {
                filter: false,
            }
        },
        {
            name: 'valor',
            label: 'Valor Asignado',
            options: {
                filter: false,
            }
        },
    ]

    return (
        <MyDataTable title="Preguntas de la Ficha Sintomatológica" source="/preguntas" customColumns={customColumns} />
    )

}