import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { StyledTable, StyledFile } from "./styles";
import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyAutocomplete } from '../../../components/MyAutocomplete'
import { MyDatePicker, MyTimePicker } from "../../../components/MyDatePicker";
import moment from "moment";
import { navigate } from "@reach/router";

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id,asignacion, getData) => {
  const [title, setTitle] = useState('Registrar Tarea');
  const [empleados, setEmpleados] = useState([]);
  const [tareas, setTareas] = useState();
  const [desplazamientoData, setDesplazamiento] = useState();

  useEffect(() => {
    id && setTitle('Editar tarea Tabajo Remoto');
    (async function () {
      const [selects, _tarea, _asignacion] = await Promise.all([
        getData({ source: '/users/selects', bg: true }),
        id && getData({ source: `/tasks/${id}` }),
        asignacion && getData({ source: `/desplazamientos/${asignacion}/show` }),
      ]);
      if (selects.status === 200) {
        setEmpleados(
          selects.data.empleados.map(e => {
            return {
                id: e.id,
                name: e.apellido_paterno + " " + e.apellido_materno + " " + e.nombres,
                value: e.id,
                label: e.apellido_paterno + " " + e.apellido_materno + " " + e.nombres,
                cargo: e.cargo,
                area_equipo: e.area_equipo,
            };
          }),
        );
      }
      id &&
        _tarea.status === 200 &&
        setTareas(_tarea.data);
      asignacion &&
        _asignacion.status === 200 &&
        setDesplazamiento(_asignacion.data);
      
    })();
  }, [id, getData]);

  return { title, empleados, tareas, desplazamientoData };
};

export const NuevasTareasRegister = ({ asignacion, id }) => {
  const [empleado, setEmpleado] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, empleados, tareas, desplazamientoData } = useInitial(id,asignacion, getData);
  const [previewJ, setPreviewJ] = useState(null);
  // Controladores para los campos de formulario
  const accion = useInputValue('');
  const fec_inicio = useInputValue('');
  const fec_fin = useInputValue('');
  const descripcion = useInputValue('');
  const observaciones = useInputValue('');
  const empleado_id = useInputValue('');
  const editar = useInputValue(false);
  const [verificacion, setVerificacion] = useState(undefined);
  const desplazamiento_id = useInputValue('');

  // Handles
  useEffect(() => {
    if (tareas) {
      editar.setValue(true);
      accion.setValue(tareas.accion || '');
      fec_inicio.setValue(tareas.fec_inicio || '');
      fec_fin.setValue(tareas.fec_fin || '');
      descripcion.setValue(tareas.descripcion || '');
      observaciones.setValue(tareas.observaciones || '');
      setEmpleado(tareas.observaciones)
      empleado_id.setValue(tareas.empleado || undefined);
      desplazamiento_id.setValue(tareas.desplazamiento_id || undefined);
      if (tareas.verificacion) {
        setPreviewJ(
          `data:application/pdf;base64,${tareas.verificacion}`
        );
      }
    }
  }, [tareas]);

  useEffect(() => {
    if (desplazamientoData) {
      desplazamiento_id.setValue(desplazamientoData.id);
      let tmp = [];
      tmp = empleados.filter((item) => item.value === desplazamientoData.empleado);
      setEmpleado(tmp[0]);
    }
  }, [desplazamientoData]);

  const fileToBlob = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    setError(undefined);

    if (!accion.value || !descripcion.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      accion: accion.value,
      fec_inicio: fec_inicio.value ? moment(fec_inicio.value).format("YYYY-MM-DD") : moment(Date()).format("YYYY-MM-DD"),
      fec_fin: fec_fin.value ? moment(fec_fin.value).format("YYYY-MM-DD") : moment(Date()).format("YYYY-MM-DD"),
      descripcion: descripcion.value,
      observaciones: observaciones.value,
      empleado: empleado.value,
      verificacion: verificacion ? await fileToBlob(verificacion) : null,
      desplazamiento_id: desplazamiento_id.value,
    }
    let message = undefined;
    let response = null;
    if (id) {
      message = "Tare actualizada con éxito.";
      response = await getData({
        source: `/tasks/${id}`,
        body: body,
        json: true,
        method: "PUT",
      });
    } else {
      message = "Tarea creada con éxito.";
      response = await getData({
        source: "/tasks",
        body: body,
        json: true,
        method: "POST",
      });
    }
    if (response.status === 200) {
      await swal(message, { icon: "success" });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
            <MyInput
              name='desplazamiento'
              label='Id de la Asignación Remota'
              type='text'
              toCase='lower'
              autoFocus={true}
              controller={desplazamiento_id}
              required={false}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput
              name='accion'
              label='Accion'
              type='text'
              toCase='lower'
              autoFocus={true}
              controller={accion}
              required={true}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
                <MyAutocomplete
                  name="empleado"
                  label="Empleado"
                  data={empleado}
                  setData={setEmpleado}
                  options={empleados}
                  disabled={true}
                />
              </Grid>
          <Grid item xs={12} md={3}>
            <MyDatePicker
              name="fec_inicio"
              label="Fecha de Inicio"
              controller={fec_inicio}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <MyDatePicker
              name="fec_fin"
              label="Fecha Fin"
              controller={fec_fin}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='descripcion'
              label='Descripción'
              controller={descripcion}
              disabled={loading}
              rows={5} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='observaciones'
              label='Actividades Realizadas'
              controller={observaciones}
              disabled={loading}
              rows={5} />
          </Grid>
          {previewJ && (
            <Grid item xs={12}>
              <object width="100%" height="500" data={previewJ}>
                <p>Error al mostrar el documento.</p>
              </object>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <StyledFile>
              <label>Documento</label>
              <input
                type="file"
                onChange={async (e) => {
                  setVerificacion(e.target.files[0]);
                  // setPreviewJ(await fileToBlob(e.target.files[0]));
                }}
              />
            </StyledFile>
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
