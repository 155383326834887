import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';
import MUIDataTable from 'mui-datatables';
import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';
import { UserContext } from '../../../contexts/UserContext';

const useInitial = (id, getData) => {
  const { user } = useContext(UserContext);
  const [title, setTitle] = useState('Registrar declaracion jurada');
  const [declaracionJurada, setDeclaracionJurada] = useState(undefined);
  const [empleado, setEmpleado] = useState(undefined);
  const [desplazamientos, setDesplazamientos] = useState([]);

  useEffect(() => {
    id && setTitle('Editar declaracion jurada');
    (async function() {
      const [_empleado, _desplazamientos, declaracion] = await Promise.all([
        getData({ source: `/empleados/${user.value.empleado}/show` }),
        !id && getData({ source: `/declaraciones-juradas/desplazamientos` }),
        id && getData({ source: `/declaraciones-juradas/${id}/show` }),
      ]);
      _empleado.status === 200 && setEmpleado(_empleado.data);
      _desplazamientos.status === 200 && setDesplazamientos(_desplazamientos.data);
      if (id && declaracion.status === 200) {
        setDeclaracionJurada(declaracion.data.declaracion);
        setDesplazamientos(declaracion.data.justificacion);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { title, declaracionJurada, empleado, desplazamientos };
};

export const DeclaracionJuradaRegister = ({ id }) => {
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, declaracionJurada, empleado, desplazamientos } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const _empleado = useInputValue('');
  const cargo = useInputValue('');
  const dni = useInputValue('');
  const unidadGestionEducativa = useInputValue('');
  const area = useInputValue('');
  const equipo = useInputValue('');
  const monto = useInputValue('');
  const motivoComision = useInputValue('');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (empleado) {
      _empleado.setValue(`${empleado.apellido_paterno} ${empleado.apellido_materno} ${empleado.nombres}` || '');
      dni.setValue(empleado.dni || '');
      cargo.setValue(empleado.cargo || '');
      area.setValue(empleado.area || '');
    }
    if (declaracionJurada) {
      monto.setValue(declaracionJurada.monto || '');
      motivoComision.setValue(declaracionJurada.motivo_comision || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleado, declaracionJurada]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    if (!desplazamientos.length) {
      swal('No hay comisiones que justificar.', { icon: 'error' });
      return;
    }
    const body = {
      monto: monto.value,
      motivoComision: motivoComision.value,
      desplazamientos: selected.map(row => desplazamientos[row].id),
    };
    setError(undefined);
    let message = undefined;
    let response = null;
    if (id) {
      message = 'Declaracion jurada actualizada con exito.';
      response = await getData({ source: `/declaraciones-juradas/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Declaracion jurada creada con exito.';
      response = await getData({ source: `/declaraciones-juradas`, body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput name='empleado' label='Empleado' controller={_empleado} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='cargo' label='Cargo' controller={cargo} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='dni' label='DNI' controller={dni} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='unidadGestionEducativa'
              label='Unidad de Gestion Educativa'
              controller={unidadGestionEducativa}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='area' label='Area' controller={area} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='equipo' label='Equipo' controller={equipo} disabled={true} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput name='monto' label='Monto' controller={monto} type='number' disabled={loading} />
          </Grid>

          <Grid item xs={12}>
            <MyInput
              name='motivoComision'
              label='Motivo Comision'
              toCase='upper'
              controller={motivoComision}
              disabled={loading}
              rows={5}
            />
          </Grid>

          <Grid item xs={12}>
            <DataTable title='Itinerario - Justificacion de gastos' data={desplazamientos} selected={selected} setSelected={setSelected} />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};

const DataTable = ({ title, data, selected, setSelected }) => {
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Numero de Papeleta',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_salida',
      label: 'Fecha',
      options: {
        filter: true,
      },
    },
    {
      name: 'motivo_salida',
      label: 'Motivo de Salida',
      options: {
        filter: true,
      },
    },
    {
      name: 'detalle_motivo',
      label: 'Detalle del Motivo',
      options: {
        filter: true,
      },
    },
    {
      name: 'importe',
      label: 'IMPORTE',
      options: {
        filter: true,
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'stacked',
    rowsSelected: selected,
    onRowsSelect: (rowsSelected, allRows) => {
      setSelected(allRows.map(row => row.dataIndex));
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => <React.Fragment></React.Fragment>,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => <React.Fragment></React.Fragment>,
  };

  return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
};
