import React from 'react';
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker, DateTimePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';

export const MyDatePicker = ({ name, label, required = false, controller, disabled, disablePast = false, minDate = false }) => {
  const handleChange = date => controller.setValue(date);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker
        name={name}
        id={name}
        label={label}
        value={controller.value}
        onChange={handleChange}
        error={controller.error !== ''}
        helperText={controller.error}
        required={required}
        disabled={disabled}
        format='dd-MM-yyyy'
        inputVariant='outlined'
        margin='normal'
        fullWidth
        disablePast={disablePast}
        minDate={minDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MyTimePicker = ({ name, label, required = false, controller, disabled, fecha = undefined, disablePast = true }) => {
  const handleChange = date => {
    controller.setValue(date);
    validarHora(date);
  };

  const validarHora = hora => {
    let _hora = moment(hora);
    if (fecha) {
      _hora = moment(moment(fecha).format('Y-MM-DD') + ' ' + _hora.format('HH:mm:ss'), 'Y-MM-DD HH:mm:ss');
    }
    if (disablePast && _hora.isValid()) {
      const now = moment();
      const diff = now.diff(_hora, 'minutes');
      if (diff > 0) {
        controller.setValue(null);
        controller.setError('La hora no puede ser anterior a la actual');
      } else {
        controller.setError('');
      }
    }
  };

  const handleBlur = () => {
    const hora = moment(controller.value);
    if (!hora.isValid()) {
      controller.setValue(null);
      controller.setError('Formato de hora invalido');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardTimePicker
        name={name}
        id={name}
        label={label}
        value={controller.value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        error={controller.error !== ''}
        helperText={controller.error}
        inputVariant='outlined'
        margin='normal'
        fullWidth
        mask='__:__ _M'
        placeholder='08:00 AM'
        format='hh:mm a'
        ampm={true}
        onBlur={handleBlur}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MyDateTimePicker = ({ name, label, required = false, controller, disabled }) => {
  const handleChange = date => controller.setValue(date);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DateTimePicker
        name={name}
        id={name}
        label={label}
        value={controller.value}
        onChange={handleChange}
        error={controller.error !== ''}
        helperText={controller.error}
        required={required}
        disabled={disabled}
        format='dd-MM-yyyy hh:mm a'
        inputVariant='outlined'
        margin='normal'
        fullWidth
        ampm={true}
        // autoOk
        variant='inline'
      />
    </MuiPickersUtilsProvider>
  );
};
