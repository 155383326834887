import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  && th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 10px;
  }

  && th {
    background-color: #1271de;
    color: snow;
  }

  && td {
    text-align: center;
  }
`;

export const InputBox = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 16px;
  padding: 7px 20px;
`;
