import styled from 'styled-components'

export const StyledDiv = styled.div`
   max-width: 100vw;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   display: flex;
   flex-direction: column;
`

export const StyledContainer = styled.div`
    display: flex;
    flex: 1;
`

export const StyledMain = styled.main`
    padding: 2rem;
    overflow: auto;
    flex: 1;
    max-height: calc(100vh - ${props => props.appHeight}px);
    height: 100%;
`
