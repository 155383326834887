import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Link } from '@reach/router';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import {
  StyledList,
  StyledListItem,
  StyledListItemExpand,
  StyledCollapse,
  // StyledCardMedia
} from './styles';

import { MyAvatar } from '../MyAvatar';

import { UserContext } from '../../contexts/UserContext';
import { useCheckRole } from '../../hooks/useCheckRole';
import { LayoutContext } from '../../contexts/LayoutContext';

export const MyList = () => {
  const { user } = useContext(UserContext);
  const { menu } = useContext(LayoutContext);
  const { can } = useCheckRole();

  const [name, setName] = useState('');
  const [expand, setExpand] = useState(menu.value);

  useEffect(() => {
    user.value && setName(user.value.name);
  }, [user.value]);

  useEffect(() => {
    menu.setItem(expand);
  }, [expand, menu]);

  return (
    <StyledList component='nav'>
      <MyListItemExpand text={name} expand={expand} setExpand={setExpand}>
        <MyAvatar
          title='Avatar'
          source={user.value && user.value.photo ? user.value.photo : '/assets/images/profile.png'}
          size={40}
          margin={-10}
        />
        <StyledList component='div' disablePadding>
          <Link to='/perfil'>
            <MyListItem text='Perfil'>
              <SupervisedUserCircleIcon />
            </MyListItem>
          </Link>
          <Link to={`/ajustes`}>
            <MyListItem text='Ajustes de cuenta'>
              <SupervisedUserCircleIcon />
            </MyListItem>
          </Link>
        </StyledList>
      </MyListItemExpand>

      {can(['USUARIOS', 'ROLES', 'EMPLEADOS', 'INSTITUCIONES EDUCATIVAS', 'CARGAR VACACIONES', 'CONFIGURACIONES']) && (
        <MyListItemExpand text='ADMINISTRACION' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['USUARIOS']) && (
              <Link to='/users'>
                <MyListItem text='Usuarios'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['ROLES']) && (
              <Link to='/roles'>
                <MyListItem text='Roles'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['EMPLEADOS']) && (
              <Link to='/empleados'>
                <MyListItem text='Empleados'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['INSTITUCIONES EDUCATIVAS']) && (
              <Link to='/instituciones-educativas'>
                <MyListItem text='Instituciones Educativas'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['FERIADOS']) && (
              <Link to='/feriados'>
                <MyListItem text='Feriados'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['CARGAR VACACIONES']) && (
              <Link to='/vacaciones'>
                <MyListItem text='Cargar vacaciones'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['CARGAR VACACIONES']) && (
              <Link to='/vacaciones-personal'>
                <MyListItem text='Vacaciones del personal'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['ROLES']) && (
              <Link to='/crud'>
                <MyListItem text='Configuracion Empleados'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['EMPLEADOS']) && (
              <Link to='/tareas'>
                <MyListItem text='Tareas'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['CONFIGURACIONES']) && (
              <Link to='/configuraciones'>
                <MyListItem text='Configuracion'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
            {can(['CONFIGURACIONES']) && (
              <Link to='/preguntas'>
                <MyListItem text='Ficha Sintomatológica'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['MONITOREO TRABAJADOR']) && (
              <Link to='/monitoreo-trabajador'>
                <MyListItem text='Monitoreo del Trabajador'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
            {can(['MONITOREO TRABAJADOR']) && (
              <Link to='/personalRiesgo'>
                <MyListItem text='Personal con Sintomatología'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['DESPLAZAMIENTOS', 'REPORTES']) && (
        <MyListItemExpand text='PAPELETAS' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['DESPLAZAMIENTOS']) && user.value.empleado != null &&(
              <Link to='/desplazamientos/register'>
                <MyListItem text='Crear papeleta'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            
            {can(['DESPLAZAMIENTOS']) && user.value.empleado != null &&(
              <Link to='/desplazamientos'>
                <MyListItem text='Mis Papeletas'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['DESPLAZAMIENTOS']) && !can(['CARGAR VACACIONES']) && (
              <Link to='/vacaciones-personal'>
                <MyListItem text='Vacaciones del personal'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}

            {can(['REPORTES']) && (
              <Link to='/reportes'>
                <MyListItem text='Cerrar papeletas'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['SOLICITUDES','RRHH']) && (
        <MyListItemExpand text='SOLICITUDES' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['RRHH']) && (
              <Link to='/solicitudes/rrhh'>
                <MyListItem text='Aprobar Papeleta como RRHH'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
            {can(['SOLICITUDES']) && (user.value.role === 3||user.value.role === 1) && (
              <Link to='/desplazamientos/assigned'>
                <MyListItem text='Papeletas Personal a Cargo'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
            {/*
              can(['SOLICITUDES']) && (
              <Link to='/solicitudes'>
                <MyListItem text='Aprobar Papeleta'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )*/}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['SOLICITUDES']) && (
        <MyListItemExpand text='GEOREFERENCIA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            {can(['SOLICITUDES']) && (
              <Link to='/georeferencia'>
                <MyListItem text='Georeferencia'>
                  <SupervisedUserCircleIcon />
                </MyListItem>
              </Link>
            )}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['ASISTENCIAS']) && (
        <MyListItemExpand text='ASISTENCIAS' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/asistencias'>
              <MyListItem text='Control de asistencia'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>

            {/* <Link to="/asistencias/guardar">
                        <MyListItem text="Guardar en base de datos">
                            <SupervisedUserCircleIcon />
                        </MyListItem>
                    </Link> */}
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['ACTA DE VERIFICACION']) && (
        <MyListItemExpand text='ACTA DE VERIFICACION' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/actas-verificacion'>
              <MyListItem text='Acta de Verificacion'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}

      {can(['DECLARACION JURADA']) && (
        <MyListItemExpand text='DECLARACION JURADA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/declaraciones-juradas'>
              <MyListItem text='Gastos'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}
      {can(['VIGILANCIA']) && (
        <MyListItemExpand text='CONTROL VIGILANCIA' expand={expand} setExpand={setExpand}>
          <SettingsIcon />
          <StyledList component='div' disablePadding>
            <Link to='/vigilancia'>
              <MyListItem text='Lista de Control'>
                <SupervisedUserCircleIcon />
              </MyListItem>
            </Link>
          </StyledList>
        </MyListItemExpand>
      )}
      {/* <MyListItemExpand text='TAREAS' expand={expand} setExpand={setExpand}>
        <SettingsIcon />
        <StyledList component='div' disablePadding>
          <Link to='/tareas'>
            <MyListItem text='Tareas'>
              <SupervisedUserCircleIcon />
            </MyListItem>
          </Link>
        </StyledList>
      </MyListItemExpand> */}

    </StyledList>
  );
};

const MyListItem = ({ children, text }) => {
  return (
    <StyledListItem button>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={text} />
    </StyledListItem>
  );
};

const MyListItemExpand = ({ children, text, expand, setExpand }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (expand === text) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [text, expand]);

  const handleClick = value => {
    setOpen(value);
    value ? setExpand(text) : setExpand(null);
  };

  return (
    <Fragment>
      <StyledListItemExpand text='Inbox' onClick={() => handleClick(!open)}>
        <ListItemIcon>{children[0]}</ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItemExpand>
      <StyledCollapse in={open} timeout='auto' unmountOnExit>
        {children[1]}
      </StyledCollapse>
    </Fragment>
  );
};
