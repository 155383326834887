import React, { useState, useContext, useEffect } from 'react';
import { CssBaseline, Grid } from '@material-ui/core';

import { StyledDiv, StyledMain, StyledContainer } from './styles';

import { MyAppBar } from '../MyAppBar';
import { MyDrawer } from '../MyDrawer';

import { LayoutContext } from '../../contexts/LayoutContext';

export const Layout = ({ children }) => {
  const { drawer } = useContext(LayoutContext);
  const [openDrawer, setOpenDrawer] = useState(drawer.value);
  const [appHeight, setAppHeight] = useState(0);

  useEffect(() => {
    drawer.setItem(openDrawer);
  }, [openDrawer, drawer]);

  return (
    <StyledDiv>
      <CssBaseline />

      <MyAppBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} setAppHeight={setAppHeight} />
      <StyledContainer>
        <MyDrawer openDrawer={openDrawer} />
        <StyledMain appHeight={appHeight}>
          <Grid container direction='column' justify='center' alignItems='stretch' spacing={2}>
            {children}
          </Grid>
        </StyledMain>
      </StyledContainer>
    </StyledDiv>
  );
};
