import React, { useRef, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
} from '@material-ui/core'

export const MySelect = ({ name, label, required = false, controller, disabled, data = [] }) => {

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            required={required}
            disabled={disabled}
            variant="outlined"
            fullWidth
            margin="normal"
        >
            <InputLabel ref={inputLabel} htmlFor={name}>{label}</InputLabel>
            <Select
                inputProps={{
                    name: name,
                    id: name,
                }}
                value={controller.value}
                onChange={controller.onChange}
                labelWidth={labelWidth}
                native
            >
                <option value="" />
                {data.map(item => <option key={ item.id || item.name} value={item.id}>{item.name}</option>)}
            </Select>
        </FormControl>
    )

}
