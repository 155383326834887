import React, { useState, useEffect } from 'react'

import {
    StyledSelect,
} from './styles'

export const MyAutocomplete = ({ name, label, data, setData, options = [], autoFocus = false, disabled}) => {

    const [value, setValue] = useState(undefined)

    useEffect(() => {
        if(data) {
            const tmp = options.filter(item => data.value === item.value)
            setValue(tmp ? tmp : undefined)
        }
    }, [data, options])

    const handleChange = (selected) => {
        setValue(selected)
        setData(selected)
    }

    return (
        <StyledSelect
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={label}
            autoFocus={autoFocus}
            isDisabled={disabled}
            options={options}
            classNamePrefix ="autocomplete"
        />
    )
}