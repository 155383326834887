import React from 'react';

import {
    StyledButton,
} from './styles'

export const MyButtton = ({ title, loading}) => {

    return (

        <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
        >
            {title}
        </StyledButton>

    )

}