import React from 'react';
import { Tooltip, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const CustomToolbar = ({ tooltip, handleClick }) => {
  return (
    <Tooltip title={tooltip}>
      <Fab variant='extended' size='small' color='primary' onClick={handleClick}>
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};
