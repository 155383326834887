import React, { useContext, useState } from 'react';
import { Grid, Hidden, Paper, Avatar, Typography, TextField, Button, Link, Box, CircularProgress } from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { StyledGrid } from './styles';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

import { UserContext } from '../../contexts/UserContext';


export const Login = () => {
  const email = useInputValue('');
  const password = useInputValue('');
  const { loading, getData } = useFetch();
  const { auth, user, setRole } = useContext(UserContext);
  const [error, setError] = useState(undefined);

  const handleSubmit = async event => {
    event.preventDefault();
    let validation = 0;
    if (!email.value) {
      email.setError('Campo requerido');
      validation++;
    }
    if (!password.value) {
      password.setError('Campo requerido');
      validation++;
    }
    if (!validation) {
      setError(undefined);
      const formData = new FormData(event.target);
      const response = await getData({ source: '/users/signin', body: formData, method: 'POST' });
      if (response.status === 200) {
        auth.setItem(response.data.token);
        user.setItem(response.data.user);
        setRole(response.data.role);
      } else {
        setError(response.message);
      }
    }
  };

  return (
    <StyledGrid container>
      <Hidden smDown>
        <Grid item md={6} lg={8} className='bg'></Grid>
      </Hidden>

      <Grid item xs={12} md={6} lg={4} component={Paper} elevation={6} square className='login'>
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component='h1' variant='h5'>
          Inicio de Sesion
        </Typography>

        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            name='email'
            label='Usuario/DNI'
            type='email'
            autoComplete='off'
            autoFocus
            onChange={email.onChange}
            value={email.value}
            error={email.error !== ''}
            helperText={email.error}
            disabled={loading}
          />

          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            name='password'
            label='Contraseña'
            type='password'
            onChange={password.onChange}
            value={password.value}
            error={password.error !== ''}
            helperText={password.error}
            disabled={loading}
          />

          <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
            Entrar
          </Button>

          {loading && (
            <Box mt={5}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Box>
          )}

          {error && (
            <Box mt={5}>
              <Typography variant='body1' color='error' align='center'>
                {error}
              </Typography>
            </Box>
          )}

          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </Grid>
    </StyledGrid>
  );
};

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        UGEL
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
