import React, { Fragment, useState, useContext } from 'react';
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core';
import swal from 'sweetalert';

import { StyledInput, Export } from './styles';

import { MyPaper } from '../../../components/MyPaper';
import { MyButtton } from '../../../components/MyButton';
import { useFetch } from '../../../hooks/useFetch';
import { MyDataTable } from '../../../components/MyDataTable';

import { UserContext } from '../../../contexts/UserContext';

export const EmpleadoMain = () => {
  const title = 'Empleados';
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const [data, setData] = useState(undefined);
  const { BASE_URL } = useContext(UserContext);

  const [excel, setExcel] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    if (!excel) {
      swal('Debe seleccionar un archivo.', { icon: 'error' });
      return;
    } else {
      const accept = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.ms-excel',
        'text/csv',
      ];
      if (accept.indexOf(excel.type) === -1) {
        swal('El archivo debe ser | .xls | .xlsx | .csv | .ods |', { icon: 'error' });
        return;
      }
    }
    const formData = new FormData(event.target);
    const response = await getData({ source: '/empleados/import', body: formData, method: 'POST' });
    if (response.status === 200) {
      setError(undefined);
      setData(response.data);
      swal('Archivo cargado con exito.', { icon: 'success' });
    } else {
      swal('Ocurrio un error al intentar cargar el archivo.', { icon: 'error' });
    }
  };

  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'area_equipo',
      label: 'Area/Equipo',
      options: {
        filter: true,
      },
    },
    {
      name: 'area',
      label: 'Area',
      options: {
        filter: true,
      },
    },
    {
      name: 'cargo',
      label: 'Cargo',
      options: {
        filter: true,
      },
    },
    {
      name: 'depende',
      label: 'Depende',
      options: {
        filter: true,
      },
    },
    {
      name: 'apellido_paterno',
      label: 'Apellido Paterno',
      options: {
        filter: true,
      },
    },
    {
      name: 'apellido_materno',
      label: 'Apellido Materno',
      options: {
        filter: true,
      },
    },
    {
      name: 'nombres',
      label: 'Nombres',
      options: {
        filter: true,
      },
    },
    {
      name: 'sexo',
      label: 'Sexo',
      options: {
        filter: true,
      },
    },
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
      },
    },
    {
      name: 'tipo_servidor',
      label: 'Tipo de Servidor',
      options: {
        filter: true,
      },
    },
    {
      name: 'trabajo_presencial_boolean',
      label: 'Asiste Presencial',
      options: {
        filter: true,
      },
    },
    {
      name: 'en_riesgo_boolean',
      label: 'Comorbilidades',
      options: {
        filter: true,
      },
    },
    {
      name: 'vacaciones',
      label: 'Vacaciones',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledInput type='file' name='excel' onChange={e => setExcel(e.target.files[0])} />
            </Grid>

            <Grid item xs={12} md={3}>
              <MyButtton title='IMPORTAR' loading={loading} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Export href={`${BASE_URL}/api/empleados/export`}>EXPORTAR COMO EXCEL</Export>
            </Grid>
          </Grid>

          {loading && (
            <Box mt={2}>
              <Grid container item justify='center' alignItems='center' xs={12}>
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>

                {error && (
                  <Typography variant='h6' color='error'>
                    {error}
                  </Typography>
                )}
              </Grid>
            </Box>
          )}
        </form>
      </MyPaper>
      <Box mt={2}>
        <MyDataTable title='Empleados' source='/empleados' customColumns={customColumns} data={data} />
      </Box>
    </Fragment>
  );
};
