import React, { Fragment, useContext, useState, useEffect, } from 'react';

import { MyDataTable } from '../../../components/MyDataTable';
import { Grid, Box, Button, Typography, CircularProgress } from '@material-ui/core';

import { MySelect } from "../../../components/MySelect";
import { MyPaper } from "../../../components/MyPaper";
import { MyButtton } from '../../../components/MyButton'
import { useInputValue } from "../../../hooks/useInputValue";
import swal from 'sweetalert';


import { UserContext } from '../../../contexts/UserContext';
import { useFetch } from '../../../hooks/useFetch';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { Loading } from '../../../components/Loading/index';
import { StyledInput } from './styles';

const useInitial = (getData, setAllData, setTableData, setDistritosIE, setNivelesIE, setRedesIE) => {
  const [title, setTitle] = useState('Instituciones Educativas');
  const [IEs, setIE] = useState([]);
  useEffect(() => {
    (async function () {
      const [IEs,] = await Promise.all([
        getData({ source: "/instituciones-educativas", bg: false }),
      ]);
      if (IEs) {
        setAllData(IEs.data);
        setTableData(IEs.data)
        let auxNiveles = [];
        let auxRedes = [];
        let auxDistritos = [];
        IEs.data.map((ie) => {
          if (auxNiveles.find((value) => value.name === ie.nivel) === undefined) {
            auxNiveles.push({ name: ie.nivel })
          }
          if (auxRedes.find((value) => value.name === ie.red) === undefined) {
            auxRedes.push({ name: ie.red })
          }
          if (auxDistritos.find((value) => value.name === ie.distrito) === undefined) {
            auxDistritos.push({ name: ie.distrito })
          }
        });
        setDistritosIE(auxDistritos);
        setNivelesIE(auxNiveles);
        setRedesIE(auxRedes);


      }
    })();
  }, [getData]);
  return {
    title,
    IEs,
  };
};






export const InstitucionEducativaMain = () => {
  const { BASE_URL, user } = useContext(UserContext);
  const { loading, getData } = useFetch();
  const [allData, setAllData] = useState([])
  const [tableData, setTableData] = useState([])
  const [reload, setReload] = useState(null);
  const [search, setSearch] = useState(false)
  const complement = '';
  const [error, setError] = useState(undefined);
  
  const [nivelesIE, setNivelesIE] = useState([]);
  const [redesIE, setRedesIE] = useState([]);
  const [distritosIE, setDistritosIE] = useState([]);

  const distritoSelected = useInputValue("");
  const redSelected = useInputValue("");
  const nivelSelected = useInputValue("");

  const [excel, setExcel] = useState('')

  const [complementSource, setComplementSource] = useState(complement)
  const {
    title,
    IEs,
  } = useInitial(getData, setAllData, setTableData, setDistritosIE, setNivelesIE, setRedesIE);
  const customColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'codigo_local',
      label: 'Codigo Local',
      options: {
        filter: true,
      }
    },
    {
      name: 'codigo_modular',
      label: 'Codigo Modular',
      options: {
        filter: true,
      }
    },
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: true,
      }
    },
    {
      name: 'modalidad',
      label: 'Modalidad',
      options: {
        filter: true,
      }
    },
    {
      name: 'nivel',
      label: 'Nivel',
      options: {
        filter: true,
      }
    },
    {
      name: 'red',
      label: 'Red',
      options: {
        filter: true,
      }
    },
    {
      name: 'distrito',
      label: 'Distrito',
      options: {
        filter: true,
      }
    },
    {
      name: 'direccion',
      label: 'Direccion',
      options: {
        filter: true,
      }
    },
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
      }
    },
    {
      name: 'director',
      label: 'Director',
      options: {
        filter: true,
      }
    },
  ];



  // useEffect(() => {
  //   console.log('efFect', search)
  //   if (search) {
  //     if (reload === 'All' && search) {
  //       setTableData(allData)
  //     } else if (reload === 'filter' && search) {
  //       let dataFilter = []
  //       if (nivelSelected != '') {
  //         dataFilter = allData.filter(item => item.nivel == nivelSelected)
  //       }
  //       setTableData(dataFilter)
  //     }
  //     setSearch(false)
  //   }

  // }, []);

  const getAll = () => {
    console.log('all')
    setTableData(allData);
    nivelSelected.setValue('');
    redSelected.setValue('');
    distritoSelected.setValue('')
  }

  const getFilter = () => {
    let filterTable = allData
    if (nivelSelected.value.length > 0) {
      filterTable = filterTable.filter(item => item.nivel == nivelSelected.value)
      console.log(nivelSelected, filterTable)
    }
    if (redSelected.value.length > 0) {
      filterTable = filterTable.filter(item => item.red == redSelected.value)
      console.log(redSelected, filterTable)
    }
    if (distritoSelected.value.length > 0) {
      filterTable = filterTable.filter(item => item.distrito == distritoSelected.value)
      console.log(distritoSelected)
    }
    setTableData(filterTable)

  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!excel) {
      swal('Debe seleccionar un archivo.', { icon: 'error' })
      return;
    } else {
      const accept = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.ms-excel',
        'text/csv',
      ]
      if (accept.indexOf(excel.type) === -1) {
        swal('El archivo debe ser | .xls | .xlsx | .csv | .ods |', { icon: 'error' })
        return;
      }
    }

    const formData = new FormData(event.target)
    const response = await getData({ source: '/instituciones-educativas', body: formData, method: 'POST' })
    if (response.status === 200) {
      setTableData(response.data)
      setError(response.message)
      swal('Archivo cargado con exito.', { icon: 'success' })
    } else {
      swal('Ocurrio un error al intentar cargar el archivo.', { icon: 'error' })
    }
  }


  function RenderList(props) {
    console.log(complementSource)
    return (
      <MyDataTable
        title={props.title}
        source={props.source}
        data={props.source}
        complementSource={props.complementSource}
        customColumns={props.customColumns}
        pdf={props.pdf}
        document={props.document}
        canRegister={props.canRegister}
      />
    )
  }
  if (loading) {
    return (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }
  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>{
          <Grid container item spacing={2}>
            <Grid item xs={12} md={8}>
              <StyledInput type="file" name="excel" onChange={(e) => setExcel(e.target.files[0])} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MyButtton title="Guardar" loading={loading} />
            </Grid>
          </Grid>
        }
          {loading && <Box mt={2}>
            <Grid container item justify="center" alignItems="center" xs={12}>
              <Fragment>
                <CircularProgress />
                <Box ml={2}>
                  <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                </Box>
              </Fragment>
              {error && <Typography variant="h6" color="error">{error}</Typography>}
            </Grid>
          </Box>}
        </form>
      </MyPaper>
      <form noValidate>
        <Grid container item justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={3}>
            <MySelect
              name="nivelSelected"
              label="Nivel"
              controller={nivelSelected}
              data={nivelesIE}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MySelect
              name="redSelected"
              label="Red"
              controller={redSelected}
              data={redesIE}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MySelect
              name="distritoSelected"
              label="Distrito"
              controller={distritoSelected}
              data={distritosIE}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              onClick={getFilter}
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              size="medium"
              disabled={loading}> Buscar </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              onClick={getAll}
              fullWidth
              variant="contained"
              color="primary"
              startIcon={< RotateLeftIcon />}
              size="medium"
              disabled={loading}> Reset </Button>
          </Grid>
          {error && (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          )}
        </Grid>
      </form>


      <MyDataTable
        title='Instituciones Educativas'
        data={tableData}
        localData={true}
        source='/instituciones-educativas'
        complementSource=''
        customColumns={customColumns}
        pdf={false}
        document='instituciones'
        canRegister={true}
        reload={reload}
      />
    </Fragment >
  );
};