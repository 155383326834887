import React, { useState, useEffect, useCallback, Fragment } from 'react'
import {
    Grid,
    Box,
    Button,
    CircularProgress,
    Typography,
    Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert'
import { navigate } from "@reach/router";
import { MyPaper } from '../../components/MyPaper'
import { MyInput } from '../../components/MyInput'
import { MyTransferList } from '../../components/MyTransferList'
import { useInputValue } from '../../hooks/useInputValue'
import { useFetch } from '../../hooks/useFetch'
import { Label } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const useInitial = (getData) => {
    const [title, setTitle] = useState('Personal Riesgo Sintomatológico');
    const [empleados, setEmpleados] = useState([]);
    const [empleadosRiesgo, setEmpleadosRiesgo] = useState([]);


    useEffect(() => {
        // setTitle('Editar Empleados de Riesgo');
        (async function () {
            const [_empleados] = await Promise.all([
                getData({ source: '/employees', bg: true }),
            ])
            let auxRiesgo = []
            let auxSinRiesgo = []
            let auxTodos = []
            console.log(_empleados)
            _empleados.data.empleados.map((empleado) => {
                let item = {
                    id: empleado.value,
                    name: empleado.label,
                    trabajoPresencial: empleado.trabajo_presencial_boolean=="Si"?1:0
                }
                auxTodos.push(item)
                if (empleado.trabajo_presencial_boolean=="Si") {
                    console.log('si')
                    auxRiesgo.push(item)
                } else {
                    auxSinRiesgo.push(item)
                }
            })
            setEmpleados(auxTodos)
            setEmpleadosRiesgo(auxRiesgo);

        })()
    }, [getData])

    return { title, empleados, empleadosRiesgo }
}


async function move(data, method, getData) {
    if (data.length > 0) {
        let employees = []
        data.map((toMove) => {
            let auxTrabajoPresencial = (method == 'activar' ? true : false);
            let employee = {
                "id": toMove.id,
                "trabajoPresencial": auxTrabajoPresencial,
            }
            employees.push(employee)
        })
        const body = {
            "employees": employees,
        };
        let message = undefined;
        let response = undefined;
        message = "Empleados de Riesgo Actualizado con Éxito";
        response = await getData({
            source: `/employees/multi-update`,
            body: body,
            json: true,
            method: "PUT",
        });
        if (response.status === 200) {
            await swal(message, { icon: "success" });
            navigate("/personalRiesgo");
        } else {
            this.setError(response.message);
        }

    }
};


export const PersonaRiesgo = () => {
    const classes = useStyles();
    const [error, setError] = useState(undefined)
    const { loading, getData } = useFetch()
    const {
        title,
        empleados,
        empleadosRiesgo,
    } = useInitial(getData);

    return (

        <MyPaper title={title}>

            <form noValidate>
                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item xs={6} >
                        <Paper className={classes.paper}> <h3>Personal Activo</h3> </Paper>
                    </Grid>
                    <Grid item xs={6} >
                    <Paper className={classes.paper}> <h3>Personal con Sintomatología</h3></Paper>
                    </Grid>
                </Grid>

                <Grid container item spacing={2}>

                    <MyTransferList data={empleados} register={empleadosRiesgo} setRegister={() => { }}
                        setToLeft={(e) => move(e, 'desactivar', getData)}
                        setToRight={(e) => move(e, 'activar', getData)} />

                    <Grid container item justify="flex-end" alignItems="center" spacing={2}>

                        <Grid container item justify="flex-start" alignItems="center" xs={12} md={6}>
                            {loading &&
                                <Fragment>
                                    <CircularProgress />
                                    <Box ml={2}>
                                        <Typography variant="h6" color="textSecondary">Procesando...</Typography>
                                    </Box>
                                </Fragment>
                            }
                            {error && <Typography variant="h6" color="error">{error}</Typography>}
                        </Grid>

                    </Grid>

                </Grid>

            </form>

        </MyPaper>

    )

}