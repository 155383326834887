import React, { useContext, Suspense, useEffect } from 'react';
import { Router, Redirect } from '@reach/router';

import swal from "sweetalert";

import { UserContext } from './contexts/UserContext';
import { Layout } from './components/Layout';
import { GlobalStyles } from './GlobalStyles';

import { Login } from './pages/Login';

import { User } from './pages/User';
import { UserMain } from './pages/User/Main';
import { UserRegister } from './pages/User/Register';

import { Reloj } from './pages/Asistencia/Reloj';
import { Ajuste } from './pages/Ajuste';
import { Perfil } from './pages/Perfil';

import { Role } from './pages/Role';
import { RoleMain } from './pages/Role/Main';
import { RoleRegister } from './pages/Role/Register';

import { Feriado } from './pages/Feriado';
import { FeriadoMain } from './pages/Feriado/Main';
import { FeriadoRegister } from './pages/Feriado/Register';

import { Asistencia } from './pages/Asistencia';
import { AsistenciaGuardar } from './pages/AsistenciaGuardar';

import { Desplazamiento } from './pages/Desplazamiento';
import { DesplazamientoMain } from './pages/Desplazamiento/Main';
import { DesplazamientoRegister } from './pages/Desplazamiento/Register';

import { Reporte } from './pages/Reporte';
import { ReporteMain } from './pages/Reporte/Main';
import { ReporteRegister } from './pages/Reporte/Register';

import { RRHHSolicitud } from './pages/RRHHSolicitud';
import { RRHHSolicitudMain } from './pages/RRHHSolicitud/Main';
import { RRHHSolicitudRegister } from './pages/RRHHSolicitud/Register';

import { Solicitud } from './pages/Solicitud';
import { SolicitudMain } from './pages/Solicitud/Main';
import { SolicitudRegister } from './pages/Solicitud/Register';

import { ActaVerificacion } from './pages/ActaVerificacion';
import { ActaVerificacionMain } from './pages/ActaVerificacion/Main';
import { ActaVerificacionRegister } from './pages/ActaVerificacion/Register';

import { DeclaracionJurada } from './pages/DeclaracionJurada';
import { DeclaracionJuradaMain } from './pages/DeclaracionJurada/Main';
import { DeclaracionJuradaRegister } from './pages/DeclaracionJurada/Register';

import { Tareas } from './pages/Tareas';
import { TareasMain } from './pages/Tareas/Main';
import { TareasRegister } from './pages/Tareas/Register';

import { NuevasTareas } from './pages/NuevasTareas';
import { NuevasTareasMain } from './pages/NuevasTareas/Main';
import { NuevasTareasRegister } from './pages/NuevasTareas/Register';


import { Vigilancia } from './pages/Vigilancia';
import { VigilanciaMain } from './pages/Vigilancia/Main';
import { VigilanciaRegister } from './pages/Vigilancia/Register';

import { PersonaRiesgo } from './pages/PersonaRiesgo';


import { Empleado } from './pages/Empleado';
import { EmpleadoMain } from './pages/Empleado/Main';
import { EmpleadoRegister } from './pages/Empleado/Register';

import { InstitucionEducativa } from './pages/InstitucionEducativa';
import { InstitucionEducativaRegister } from './pages/InstitucionEducativa/Register';
import { InstitucionEducativaMain } from './pages/InstitucionEducativa/Main';

import { Configuracion } from './pages/Configuracion';
import { Vacacion } from './pages/Vacacion';
import { VacionesPersonal } from './pages/vacacionesPersonal';
import { Georeferencia } from './pages/Georeferencia';
import { CrudMain } from './pages/crud'

import { useFetch } from './hooks/useFetch';
import { useCheckRole } from './hooks/useCheckRole';
import { MonitoreoTrabajador } from './pages/MonitoreoTrabajador';
import { DesplazamientoAssigned } from './pages/Desplazamiento/Assigned';


import { FichaSintomatologica } from './pages/FichaSintomatologica'
import { FichaSintomatologicaMain } from './pages/FichaSintomatologica/Main'
import { FichaSintomatologicaRegister } from './pages/FichaSintomatologica/Register'

export const App = () => {
  const { getData } = useFetch();
  const { auth, user, role, setRole } = useContext(UserContext);
  const { can } = useCheckRole();

  useEffect(() => {
    if (auth.value && !role.length) {
      (async function () {
        const response = await getData({ source: `/roles/${user.value.role}/can` });
        response.status === 200 && setRole(response.data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);



  return (
    <Suspense fallback={<div />}>
      <GlobalStyles />

      {!auth.value && (
        <Router>
          <Login path='/login' />
          <Redirect from='*' to='/login' noThrow />
        </Router>
      )}

      {auth.value && (
        <Layout>
          <Router>
            <Redirect from='/login' to='/perfil' noThrow />
            <Redirect from='/' to='/perfil' noThrow />

            <Perfil path='/perfil' />
            <Ajuste path='/ajustes' />
            <MonitoreoTrabajador path='/monitoreo-trabajador' />
            <Reloj path='/reloj' />


            {can(['CARGAR VACACIONES']) && <Vacacion path='/vacaciones' />}
            {can(['DESPLAZAMIENTOS']) && <VacionesPersonal path='/vacaciones-personal' />}
            {can(['CONFIGURACIONES']) && <Configuracion path='/configuraciones' />}
            {can(['CONFIGURACIONES']) &&
              <FichaSintomatologica path='/preguntas'>
                <FichaSintomatologicaMain path='/' />
                <FichaSintomatologicaRegister path='register' />
                <FichaSintomatologicaRegister path=':id/edit' />
              </FichaSintomatologica>
            }
            {can(['ASISTENCIAS']) && <Asistencia path='/asistencias' />}
            {can(['ASISTENCIAS']) && <AsistenciaGuardar path='/asistencias/guardar' />}
            {can(['SOLICITUDES']) && <Georeferencia path='/georeferencia' />}
            {can(['ROLES']) && <CrudMain path='/crud' />}

            {can(['INSTITUCIONES EDUCATIVAS']) &&
              <InstitucionEducativa path='instituciones-educativas'>
                <InstitucionEducativaMain path='/' />
                <InstitucionEducativaRegister path='register' />
                <InstitucionEducativaRegister path=':id/edit' />
              </InstitucionEducativa>
            }

            {can(['USUARIOS']) && (
              <User path='users'>
                <UserMain path='/' />
                <UserRegister path='register' />
                <UserRegister path=':id/edit' />
              </User>
            )}
            {can(['TAREAS']) &&
              <Tareas path='tareas'>
                <TareasMain path='/' />
                <TareasRegister path='register' />
                <TareasRegister path=':id/edit' />
              </Tareas>
            }
            {can(['ROLES']) && (
              <Role path='roles'>
                <RoleMain path='/' />
                <RoleRegister path='register' />
                <RoleRegister path=':id/edit' />
              </Role>
            )}

            {can(['FERIADOS']) && (
              <Feriado path='/feriados'>
                <FeriadoMain path='/' />
                <FeriadoRegister path='register' />
                <FeriadoRegister path=':id/edit' />
              </Feriado>
            )}

            {can(['EMPLEADOS']) && (
              <Empleado path='/empleados'>
                <EmpleadoMain path='/' />
                <EmpleadoRegister path='register' />
                <EmpleadoRegister path=':id/edit' />
              </Empleado>
            )}

            {can(['DESPLAZAMIENTOS']) && (
              <Desplazamiento path='/desplazamientos'>
                <DesplazamientoMain path='/' />
                <DesplazamientoRegister path='register' />
                <DesplazamientoRegister path=':id/edit' />
                <DesplazamientoAssigned path='assigned' />
              </Desplazamiento>
            )}

            {can(['RRHH']) && (
              <RRHHSolicitud path='/solicitudes/rrhh'>
                <RRHHSolicitudMain path='/' />
                <RRHHSolicitudRegister path=':id/edit' />
              </RRHHSolicitud>
            )}

            {can(['SOLICITUDES']) && (
              <Solicitud path='/solicitudes'>
                <SolicitudMain path='/' />
                <SolicitudRegister path=':id/edit' />
              </Solicitud>
            )}

            {can(['REPORTES']) && (
              <Reporte path='/reportes'>
                <ReporteMain path='/' />
                <ReporteRegister path=':id/edit' />
              </Reporte>
            )}

            {can(['ACTA DE VERIFICACION']) && (
              <ActaVerificacion path='/actas-verificacion'>
                <ActaVerificacionMain path='/' />
                <ActaVerificacionRegister path='register' />
                <ActaVerificacionRegister path=':id/edit' />
              </ActaVerificacion>
            )}
            {can(['DECLARACION JURADA']) && (
              <DeclaracionJurada path='/declaraciones-juradas'>
                <DeclaracionJuradaMain path='/' />
                <DeclaracionJuradaRegister path='register' />
                <DeclaracionJuradaRegister path=':id/edit' />
              </DeclaracionJurada>
            )}
            {can(['VIGILANCIA']) && (
              <Vigilancia path='/vigilancia'>
                <VigilanciaMain path='/' />
                <VigilanciaRegister path='register' />
                <VigilanciaRegister path=':id/edit' />
              </Vigilancia>
            )}
            {can(['TAREAS']) && (
              <NuevasTareas path='/tasks'>
                <NuevasTareasMain path='/:asignacion' />
                <NuevasTareasRegister path='/:asignacion/register' />
                <NuevasTareasRegister path='/:asignacion/:id/edit' />
              </NuevasTareas>
            )}

            {can(['PERSONALRIESGO']) && (
              <PersonaRiesgo path='/personalRiesgo' />
            )}

          </Router>
        </Layout>
      )}
    </Suspense>
  );
};

