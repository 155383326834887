import styled from 'styled-components'
import {
    Grid,
} from '@material-ui/core'

export const StyledGrid = styled(Grid)`
    height: 100vh;

    && .bg {
        background: url('/assets/images/bg-1.png') center center no-repeat;
        background-size: cover;
    }

    && .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 2rem;
    }
`