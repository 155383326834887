import { useState, useContext, useCallback } from 'react';
import swal from 'sweetalert';
import { UserContext } from '../contexts/UserContext';

export const useFetch = () => {
    const [loading, setLoading] = useState(false);
    const { auth, user, setRole, BASE_URL } = useContext(UserContext);
    const fetchData = async ({ source, body = undefined, method = 'GET', bg = false, json = false }) => {
        !bg && setLoading(true);
        const options = {
            method: method,
            credentials: 'same-origin',
            body: json ? JSON.stringify(body) : body,
            headers: {
                Authorization: `Bearer ${auth.value}`,
            },
            // mode: 'no-cors'
        };
        json && (options.headers['content-type'] = 'application/json');
        try {
            const response = await fetch(`${BASE_URL}/api${source}`, options);
            const json = await response.json();
            !bg && setLoading(false);
            if (response.status === 401) {
                // console.log(response.message);
                await swal('Su sesión a expirado, por favor vuelva a ingresar.', { icon: 'error' });
                user.removeItem();
                setRole([]);
                auth.removeItem();
                window.location = '/';
            }
            return { ...json, status: response.status };

        } catch (error) {
            console.log('Error: ', error);
            !bg && setLoading(false);
            return { status: 500, messaje: 'Error de comunicacion con el servidor.' };
        }
    },
        getData = useCallback(fetchData, []);

    return { loading, getData };
};