import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

export const FichaSintomatologicaRegister = ({ id }) => {
    const title = 'Editar Pregunta';
    const { loading, getData } = useFetch();
    const [error, setError] = useState(undefined);

    // const [pregunta, setPregunta] = useState('');

    const pregunta = useInputValue('Pregunta');
    const valor = useInputValue(0);
    const ID = useInputValue(id);

    useEffect(() => {
        (async () => {
            console.log(id)
            
            const response = await getData({ source: '/preguntas' });
            if (response.status === 200) {
                console.log(response)
                response.data.map((question) => {
                    if (question.id == parseInt(id)) {
                        console.log(question)
                        pregunta.setValue(question.pregunta);
                        valor.setValue(question.valor);
                    }
                })
                // id.setValue(response.data.id);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData]);

    // Manejador de envio del formulario
    const handleSubmit = async event => {
        event.preventDefault();

        if (!pregunta.value) {
            swal('Debe llenar los campos requeridos.', { icon: 'error' });
            return;
        }

        const body = {
            pregunta: pregunta.value,
            valor: valor.value,
            //   declaracionJurada: declaracionJurada.value,
        };

        setError(undefined);
        let response = await getData({ source: `/preguntas` + `/` + id, body: body, json: true, method: 'POST' });
        if (response.status === 200) {
            await swal('Configuracion actualizada con exito.', { icon: 'success' });
            if (id) window.history.back()
        } else {
            setError(response.message);
        }
    };

    return (
        <MyPaper title={title}>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} md={1}>
                        <MyInput name='ID' 
                                label='ID' 
                                type="number" 
                                controller={ID} 
                                disabled={true} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MyInput name='pregunta'
                            label='Contenido de la Pregunta'
                            type="text"
                            controller={pregunta}
                            disabled={loading||id=="1"}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MyInput name='valor' 
                                type="number" 
                                label='Valor a asignar' 
                                controller={valor} 
                                disabled={loading} />
                    </Grid>
                    <Grid container item justify='flex-end' alignItems='center' spacing={2}>
                        <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
                            {loading && (
                                <Fragment>
                                    <CircularProgress />
                                    <Box ml={2}>
                                        <Typography variant='h6' color='textSecondary'>
                                            Procesando...
                    </Typography>
                                    </Box>
                                </Fragment>
                            )}
                            {error && (
                                <Typography variant='h6' color='error'>
                                    {error}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <Button
                                type='button'
                                fullWidth
                                variant='contained'
                                color='secondary'
                                disabled={loading}
                                onClick={() => window.history.back()}
                            >
                                Atras
              </Button>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                                Guardar
              </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </MyPaper>
    );
};
