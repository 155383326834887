import styled from 'styled-components'
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// export const ExportExcel = styled(ReactHTMLTableToExcel)`
//     width: 100%;
//     margin-top: 18px;
//     padding: 1.2rem 0;
//     color: white;
//     background-color: #4CAF50;
//     border: none; 
//     outline: none;
//     border-radius: 5px;
//     font-size: 1rem;
//     box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
// `

export const ExportExcel = styled.a`
    display: block;
    background-color: green;
    margin-top: 16px;
    text-align: center;
    font-size: 1.1rem;
    padding: 1.1rem 0;
    border-radius: 5px;
    color: white;
`

export const StyledTable = styled.table`
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 12px;

    && tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    && th {
        outline: 1px solid #4CAF50;
        border: 1px solid #4CAF50;
        background-color: #4CAF50;
        color: white;
        padding: 5px;

        position: sticky;
        top: 0;
    }

    && thead tr:nth-child(2) th {
        top: 28px;
    }

    && thead tr:nth-child(3) th {
        top: 56px;
    }

    && td {
        padding: 5px;
        border: 1px solid rgba(0,0,0,0.2);
    }

    && .align-left {
        text-align: left;
    }

    && th.wk {
        outline: 1px solid rgb(255, 238, 128);
        border: 1px solid rgb(255, 238, 128);
    }

    && .wk {
        background-color: rgb(255, 238, 128);
    }

    && .o {
        background-color: rgb(247, 193, 0);
    }

    && .d {
        background-color: rgb(32, 199, 214);
    }

    && .f {
        background-color: rgb(235, 101, 101);
    }

    && .ok {
        background-color: rgb(115, 255, 145);
    }

    && .t {
        background-color: rgb(247, 227, 0);
    }

    && .nl {
        background-color: rgb(235, 52, 232);
    }

    && .tr {
        background-color: rgb(100,200,50);
    }
`

export const TableContainer = styled.div`
    position: relative;
    max-width: ${props => props.maxWidth}px;;
    max-height: 70vh;
    overflow: auto;
`

export const Legend = styled.div`
    display: flex;
    margin: 10px 0;

    && div {
        padding: 10px;
    }

    && .wk {
        background-color: rgb(255, 238, 128);
    }

    && .o {
        background-color: rgb(247, 193, 0);
    }

    && .d {
        background-color: rgb(32, 199, 214);
    }

    && .f {
        background-color: rgb(235, 101, 101);
    }

    && .ok {
        background-color: rgb(115, 255, 145);
    }

    && .t {
        background-color: rgb(247, 227, 0);
    }

    && .nl {
        background-color: rgb(235, 52, 232);
    }
    && .tr {
        background-color: rgb(100,200,50);
    }
`