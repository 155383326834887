import React, { useContext, useRef, useEffect } from 'react';
import { Fab, IconButton, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

import { StyledAppBar, StyledToolbar, StyledTypography } from './styles';
import { MyMenu } from '../MyMenu';
import { UserContext } from '../../contexts/UserContext';
import { Fragment } from 'react';
import { MyButtton } from '../MyButton';

export const MyAppBar = ({ openDrawer, setOpenDrawer, setAppHeight }) => {
  const { auth, user, setRole } = useContext(UserContext);
  const ref = useRef();

  useEffect(() => {
    setAppHeight(ref.current.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = () => {
    user.removeItem();
    setRole([]);
    auth.removeItem();
  };

  return (
    <StyledAppBar position='static' ref={ref}>
      <StyledToolbar>
        <IconButton
          edge='start'
          aria-label='menu'
          className='menu'
          onClick={() => {
            setOpenDrawer(!openDrawer);
          }}
        >
          <MenuIcon />
        </IconButton>
        <StyledTypography>Sistema de Monitoreo y Asistencia (MONASIS)</StyledTypography>
          
        {/*
          <a href="/reloj">
          <div className="MuiButtonBase-root MuiListItem-root sc-dnqmqq kJDeEa MuiListItem-gutters MuiListItem-button" tabIndex="0" role="button" aria-disabled="false">
            <div className="MuiListItemText-root">
              <span className="Mu iTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">Reloj del Sistema</span>
            </div>
          </div>
        </a>*/
        }  
        
          
         
        <MyMenu handleSignOut={handleSignOut}>
          <PersonIcon />
          {user.value && user.value.name}
        </MyMenu>
      </StyledToolbar>
    </StyledAppBar>
  );
};
