import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { StyledTable, StyledFile } from "./styles";
import { MyPaper } from '../../../components/MyPaper';
import { MyInput } from '../../../components/MyInput';
import { MySelect } from '../../../components/MySelect';
import { MyDatePicker, MyTimePicker } from "../../../components/MyDatePicker";

import { useInputValue } from '../../../hooks/useInputValue';
import { useFetch } from '../../../hooks/useFetch';

const useInitial = (id, getData) => {
  const [title, setTitle] = useState('Registrar Tarea');
  const [empleados, setEmpleados] = useState([]);
  const [tareas, setTareas] = useState();

  useEffect(() => {
    id && setTitle('Editar tarea');

    (async function() {
      const [selects, _tarea] = await Promise.all([
        getData({ source: '/users/selects', bg: true }),
        id && getData({ source: `/tareas/${id}/show` }),
      ]);
      if (selects.status === 200) {
        setEmpleados(
          selects.data.empleados.map(e => {
            return {
              id: e.id,
              name: `${e.dni} ${e.apellido_paterno} ${e.apellido_materno} ${e.nombres}`,
            };
          }),
        );
      }
      id &&
        _tarea.status === 200 &&
        setTareas(_tarea.data);
    })();
  }, [id, getData]);

  return { title, empleados, tareas };
};

export const TareasRegister = ({ id }) => {
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();
  const { title, empleados, tareas } = useInitial(id, getData);

  // Controladores para los campos de formulario
  const accion = useInputValue('');
  const fec_inicio = useInputValue('');
  const fec_fin = useInputValue('');
  const descripcion = useInputValue('');
  const observaciones = useInputValue('');
  const empleado = useInputValue('');
  const editar = useInputValue(false);

  const [verificacion, setVerificacion] = useState(undefined);

  // Handles
  useEffect(() => {
    // setAsuntos(asuntos);
    // console.log(asuntos);
    if (tareas) {
      editar.setValue(true);
      accion.setValue(tareas.accion || '');
      fec_inicio.setValue(tareas.fec_inicio || '');
      fec_fin.setValue(tareas.fec_fin || '');
      descripcion.setValue(tareas.descripcion || '');
      observaciones.setValue(tareas.observaciones || '');
      empleado.setValue(tareas.empleado || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tareas]);

  const fileToBlob = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();
    setError(undefined);

    if (!accion.value || !descripcion.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      accion: accion.value,
      fec_inicio: fec_inicio.value,
      fec_fin: fec_fin.value,
      descripcion: descripcion.value,
      observaciones: observaciones.value,
      empleado: empleado.value,
      verificacion: verificacion || null
    }

    let message = undefined;
    let response = null;
    if (id) {
      message = 'Tarea actualizada con exito.';
      response = await getData({ source: `/tareas/${id}`, body: body, json: true, method: 'PUT' });
    } else {
      message = 'Tarea registrada con exito.';
      response = await getData({ source: '/tareas/', body: body, json: true, method: 'POST' });
    }
    if (response.status === 200) {
      await swal(message, { icon: 'success' });
      window.history.back();
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput
              name='accion'
              label='Accion'
              type='text'
              toCase='lower'
              autoFocus={true}
              controller={accion}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MySelect
              name='empleado'
              label='Empleado'
              controller={empleado}
              required={true}
              disabled={loading || editar.value}
              data={empleados}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyDatePicker
              name="fec_inicio"
              label="Fecha de Inicio"
              controller={fec_inicio}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyDatePicker
              name="fec_fin"
              label="Fecha Fin"
              controller={fec_fin}
              required={true}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='descripcion'
              label='Descripción'
              controller={descripcion}
              disabled={loading}
              rows={5} />
          </Grid>

          <Grid item xs={12} md={6}>
            <MyInput
              name='observaciones'
              label='Actividades Realizadas'
              controller={observaciones}
              disabled={loading}
              rows={5} />
          </Grid>

          {/*<Grid item xs={12}>
            <StyledFile>
              <label>Documento</label>
              <input
                disabled={true}
                type="file"
                onChange={async (e) => {
                  setVerificacion(e.target.files[0]);
                  // setPreviewJ(await fileToBlob(e.target.files[0]));
                }}
              />
            </StyledFile>
          </Grid>*/}

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
