import React from 'react'

import { MyDataTable } from '../../../components/MyDataTable'

export const FeriadoMain = () => {

    const customColumns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                display: 'excluded',
            }
        },
        {
            name: 'fecha',
            label: 'Fecha',
            options: {
                filter: true,
            }
        },
        {
            name: 'descripcion',
            label: 'Descripcion',
            options: {
                filter: true,
            }
        },
    ]

    return (
        <MyDataTable title="Feriados" source="/feriados" customColumns={customColumns} />
    )

}