import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import swal from 'sweetalert';

import { MyPaper } from '../../components/MyPaper';
import { MyInput } from '../../components/MyInput';

import { useInputValue } from '../../hooks/useInputValue';
import { useFetch } from '../../hooks/useFetch';

export const Configuracion = ({ id }) => {
  const title = 'Editar contadores';
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);

  const desplazamiento = useInputValue('');
  const actaVerificacion = useInputValue('');
  const declaracionJurada = useInputValue('');
  const fichaSintomatologica = useInputValue('');
  const limiteEmpleado = useInputValue('');

  useEffect(() => {
    (async () => {
      const response = await getData({ source: '/configuraciones' });
      if (response.status === 200) {
        desplazamiento.setValue(response.data.desplazamiento);
        actaVerificacion.setValue(response.data.acta_verificacion);
        declaracionJurada.setValue(response.data.declaracion_jurada);
        fichaSintomatologica.setValue(response.data.ficha_sintomatologica_consecutivo);
        limiteEmpleado.setValue(response.data.limite_empleado);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  // Manejador de envio del formulario
  const handleSubmit = async event => {
    event.preventDefault();

    if (!desplazamiento.value) {
      swal('Debe llenar los campos requeridos.', { icon: 'error' });
      return;
    }

    const body = {
      desplazamiento: desplazamiento.value,
      actaVerificacion: actaVerificacion.value,
      declaracionJurada: declaracionJurada.value,
      ficha_sintomatologica_consecutivo: fichaSintomatologica.value,
      limite_empleado: limiteEmpleado.value
    };

    setError(undefined);
    let response = await getData({ source: `/configuraciones`, body: body, json: true, method: 'PUT' });
    if (response.status === 200) {
      await swal('Configuracion actualizada con exito.', { icon: 'success' });
    } else {
      setError(response.message);
    }
  };

  return (
    <MyPaper title={title}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <MyInput name='desplazamiento' label='Numero de desplazamiento' type='number' controller={desplazamiento} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput name='actaVerificacion' label='Numero de acta de verificacion' type='number' controller={actaVerificacion} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput name='declaracionJurada' label='Numero de declaracion jurada' type='number' controller={declaracionJurada} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput name='fichaSintomatologica' label='Numero de ficha sintomatológica' type='number' controller={fichaSintomatologica} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyInput name='limiteEmpleado' label='Límite de aforo empleados' type='number' controller={limiteEmpleado} disabled={loading} />
          </Grid>

          <Grid container item justify='flex-end' alignItems='center' spacing={2}>
            <Grid container item justify='flex-start' alignItems='center' xs={12} md={6}>
              {loading && (
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {error && (
                <Typography variant='h6' color='error'>
                  {error}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='secondary'
                disabled={loading}
                onClick={() => window.history.back()}
              >
                Atras
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={loading}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MyPaper>
  );
};
