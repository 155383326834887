import { Grid } from '@material-ui/core';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { InputLabel, IconButton, Fab, Button, Tooltip, Typography } from '@material-ui/core';
import Clock from 'react-clock';
import './styles.css';
import { UserContext } from '../../../contexts/UserContext';
import { useFetch } from '../../../hooks/useFetch';
import moment from 'moment';
import TimerOff from '@material-ui/icons/TimerOff';
import { useInputValue } from '../../../hooks/useInputValue';
import swal from "sweetalert";
import { useDialog } from '../../../hooks/useDialog';

const useInitial = (getData, user, stop, setStop, empleado, setEmpleado) => {
    const [title, setTitle] = useState('Clock');
    useEffect(() => {
        if (!stop) {
            setTitle("Reloj de Control");
            (async function () {
                const [_empleado] = await Promise.all([
                    getData({ source: "/empleados/" + user.value.empleado + "/show", bg: true }),
                ]);
                if (_empleado) {
                    setEmpleado(_empleado.data);
                }
            })();
        }
        setStop(true)
    }, [getData, user, empleado]);
    return {
        title,
        empleado
    };
};



export const Reloj = ({ children }) => {
    
    const [value, setValue] = useState(new Date());
    const [stop, setStop] = useState(false)
    const { loading, getData } = useFetch();
    const { user } = useContext(UserContext);
    const [empleado, setEmpleado] = useState({ nombres: '', apellido_paterno: '', apellido_materno: '', dni: '' })
    const [error, setError] = useState(undefined);
    const { confirm } = useDialog();

    const {
        title,
    } = useInitial(getData, user, stop, setStop, empleado, setEmpleado);

    useEffect(() => {
        const interval = setInterval(
            () => setValue(new Date()),
            1000
        );
        return () => {
            clearInterval(interval);
        }
    }, []);


    {/**
    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid container justify='center' alignItems='center' item xs={12} md={12}>
                <div >
                    <Clock className="react-clock"
                        size={250}
                        value={value} />
                </div>
            </Grid>
            <hr></hr>
            <Grid container justify='center' alignItems='center' item xs={12} md={12}>
                <h2><InputLabel variant='filled'>
                    {'Fecha: ' + moment(value).format('dddd').toUpperCase() + ' ' +
                        moment(value).format('DD') + ' ' +
                        ' de ' + moment(value).format('MMMM') + ' ' +
                        ' de ' + moment(value).format('YYYY') + ' - Hora: ' +
                        moment(value).format('HH:mm:ss')
                    }
                </InputLabel></h2>
            </Grid>
            <hr></hr>
            {empleado && (
                <Grid container justify='center' alignItems='center' item xs={12} md={12}>
                    <h2><InputLabel variant='filled'>
                        {'Usuario: ' + empleado.nombres + ' '
                            + empleado.apellido_paterno + ' '
                            + empleado.apellido_materno}
                    </InputLabel></h2>
                </Grid>
            )

            }

            <hr></hr>
            <Grid container justify='center' alignItems='center' item xs={12} md={12}>
                <Tooltip title='Marcar mi salida!!!'>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        startIcon={<TimerOff />}
                        disabled={loading}
                        onClick={async () => {
                            confirm({ title: 'Mensaje', message: 'Vas a registrar Salida en este momento?' })
                                .then(async result => {
                                    if (result) {
                                        const body = {
                                            tipo: 1,
                                            empleado: empleado.id,
                                            rut: empleado.dni ? empleado.dni.toString(): "0",
                                        }
                                        let response = await getData({
                                            source: "/asistencias/add",
                                            body: body,
                                            json: true,
                                            method: "POST",
                                        });
                                        if (response.status === 200) {
                                            let message = "Ha marcado su salida correctamente";
                                            await swal(message, { icon: "success" });
                                        } else {
                                            let message = "Hay un error, al parecer ya has marcado salida, confirma con RRHH";
                                            await swal(message, { icon: "warning" });
                                        }
                                    }
                                })
                        }}
                    > Marcar Salida </Button>
                </Tooltip>
            </Grid>
            {error!==null && (
                <Typography variant='h6' color='error'>
                    {error}
                </Typography>
            )}
        </Grid >
    )
    */
    }
    return(
        <div ></div>
    )
};
