import React, { useState, useEffect, Fragment } from 'react';

import { useFetch } from '../../hooks/useFetch';
import { Grid, IconButton, Fab, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { CustomToolbar } from '../../components/MyDataTable/CustomToolbar';
import { MyPaper } from '../../components/MyPaper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDialog } from '../../hooks/useDialog';
import { MySelect } from '../../components/MySelect';
import { useInputValue } from '../../hooks/useInputValue';
import swal from 'sweetalert';

const useInitial = (getData) => {
  const [selects, setSelects] = useState(undefined);
  useEffect( () => {
    (async function() {
      const selectsData = await getData({ source: '/empleados/selects', bg: true });
      setSelects(selectsData.data);
    } )();
  }, [getData] );
  return { selects };
};

export const CrudMain = () => {

  const { loading, getData } = useFetch();
  const { selects } = useInitial(getData);
  const { confirm } = useDialog();
  const controller = useInputValue('areas');
  const title = useInputValue('');

  // controller.setValue('areas');

  const options = {
    filter: true,
    print: false,
    selectableRows: 'none',
    rowsPerPage: 15,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',
    customToolbar: () =>
      true ? <CustomToolbar tooltip='Registrar' handleClick={handleClick} /> : <React.Fragment></React.Fragment>,
  };

  const handleClick = () => {
    swal("Nuevo", {
      content: "input",
    })
    .then( async (value) => {
      if (value) {
        const nuevo = {
          name: value.toUpperCase()
        };
        selects[controller.value] = [...selects[controller.value], nuevo];
        const body = {
          name: controller.value,
          data: selects[controller.value]
        };
        let message = undefined;
        let response = null;
        message = `${title.value} creado con exito.`;
        response = await getData({ source: `/empleados/updatejson`, body, json: true, method: 'POST' });
        if (response.status === 200) {
          await swal(message, { icon: 'success' });
        } else {
          await swal('Algo salio mal', { icon: 'error' });
        }
      }
    });
  };

  const actions = [
    {
      name: 'Acciones',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const id = tableMeta.rowData[1];
          return (
            <Fragment key={id}>
              <Tooltip title='Editar'>
                <IconButton
                  onClick={() => {
                    let input = document.createElement("input");
                    input.value = id;
                    input.type = 'text';
                    input.className = 'swal-content__input';
                    swal("Editar", {
                      content: input
                    })
                    .then( async (value) => {
                      if (value) {
                        selects[controller.value] = selects[controller.value].map( data => {
                          if ( data.name === id ) {
                            data.name = input.value.toUpperCase();
                          }
                          return data;
                        } );
                        const body = {
                          name: controller.value,
                          data: selects[controller.value]
                        };
                        let message = undefined;
                        let response = null;
                        message = `${title.value} creado con exito.`;
                        response = await getData({ source: `/empleados/updatejson`, body, json: true, method: 'POST' });
                        if (response.status === 200) {
                          await swal(message, { icon: 'success' });
                        } else {
                          await swal( ' Algo salio mal ', { icon: 'error' });
                        }
                      }
                    });
                  }}
                >
                  <EditIcon color={'primary'} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Eliminar'>
                <IconButton
                  onClick={() => {
                    confirm({ title: 'Mensaje', message: '¿Estas seguro de querer eliminar este registro?' }).then(async result => {
                      if (result) {
                        selects[controller.value] = selects[controller.value].filter( res => res.name.toString() !== id.toString());
                        const body = {
                          name: controller.value,
                          data: selects[controller.value]
                        };
                        let message = undefined;
                        let response = null;
                        message = `${title.value} borrado con exito.`;
                        response = await getData({ source: `/empleados/updatejson`, body: body, json: true, method: 'POST' });
                        if (response.status === 200) {
                          await swal(message, { icon: 'success' });
                          selects[controller.value] = response.data[controller.value];
                        } else {
                          await swal('Algo salio mal', { icon: 'error' });
                          controller.setValue('');
                          // controller.setValue(id);
                        }
                      }
                    });
                  }}
                >
                  <DeleteIcon color={'secondary'} />
                </IconButton>
              </Tooltip>
            </Fragment>
          );
        }
      }
    }
  ];

  const customColumns = [
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
      },
    }
  ]

    useEffect(() => {
      // console.log(controller.value);
      // console.log(controller);
      switch (controller.value) {
        case 'areas':
          title.setValue('Areas');
          break;
        case 'areasEquipos':
          title.setValue('Areas de Equipo');
          break;
        case 'cargos':
          title.setValue('Cargos');
          break;
        case 'gruposOcupacionales':
          title.setValue('Grupos Ocupacionales');
          break;
        case 'procesosCas':
          title.setValue('Procesos Cas');
          break;
        default:
      }
    }, [controller]);

  return (
    <Fragment>
      <MyPaper title="CRUD">
        <Grid item xs={12} md={12}>
          <MySelect
            name='crud'
            label='CRUD REQUERIDO'
            controller={controller}
            data={[{ id: 'areasEquipos', name: 'Areas de Equipo' }, { id: 'areas', name: 'Areas' }, { id: 'cargos', name: 'Cargos' }, { id: 'gruposOcupacionales', name: 'Grupos Ocupacionales' }, { id: 'procesosCas', name: 'Procesos Cas' }]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MUIDataTable title={title.value} options={options} columns={[ ...actions, ...customColumns]} data={ (selects) ? selects[controller.value] || [] : []}/>
        </Grid>
      </MyPaper>
    </Fragment>

  );
};
