import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Box, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import { StyledInput } from './styles';
import swal from 'sweetalert';

import { MyPaper } from '../../components/MyPaper';
import { MyButtton } from '../../components/MyButton';

import { useFetch } from '../../hooks/useFetch';

const useInitial = getData => {
  const [vacaciones, setVacaciones] = useState([]);
  useEffect(() => {
    (async function() {
      const res = await getData({ source: '/desplazamientos/vacaciones' });
      res.status === 200 && setVacaciones(res.data);
    })();
  }, [getData]);
  return { vacaciones, setVacaciones };
};

export const Vacacion = () => {
  const title = 'Carga de Vacaciones';
  const [error, setError] = useState(undefined);
  const { loading, getData } = useFetch();

  const { vacaciones, setVacaciones } = useInitial(getData);
  const [excel, setExcel] = useState('');
  const [dataModal, setDataModal] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!excel) {
      swal('Debe seleccionar un archivo.', { icon: 'error' });
      return;
    } else {
      const accept = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.ms-excel',
        'text/csv',
      ];
      if (accept.indexOf(excel.type) === -1) {
        swal('El archivo debe ser | .xls | .xlsx | .csv | .ods |', { icon: 'error' });
        return;
      }
    }

    const formData = new FormData(event.target);
    const response = await getData({ source: '/desplazamientos/vacaciones', body: formData, method: 'POST' });
    if (response.status === 200) {
      setVacaciones(response.data.vacaciones);
      setDataModal(response.data.resultado);
      setOpen(true);
      setError(response.message);
      swal('Archivo cargado con exito.', { icon: 'success' });
    } else {
      swal('Ocurrio un error al intentar cargar el archivo.', { icon: 'error' });
    }
  };

  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={8}>
              <StyledInput type='file' name='excel' onChange={e => setExcel(e.target.files[0])} />
            </Grid>

            <Grid item xs={12} md={4}>
              <MyButtton title='Guardar' loading={loading} />
            </Grid>
          </Grid>

          {loading && (
            <Box mt={2}>
              <Grid container item justify='center' alignItems='center' xs={12}>
                <Fragment>
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography variant='h6' color='textSecondary'>
                      Procesando...
                    </Typography>
                  </Box>
                </Fragment>

                {error && (
                  <Typography variant='h6' color='error'>
                    {error}
                  </Typography>
                )}
              </Grid>
            </Box>
          )}
        </form>
      </MyPaper>

      <Box mt={2}>
        <Table data={vacaciones} />
      </Box>

      <Modal open={open} setOpen={setOpen}>
        <Table
          data={dataModal}
          extra={[
            {
              name: 'estado',
              label: 'Estado',
              options: {
                filter: true,
              },
            },
          ]}
        />
      </Modal>
    </Fragment>
  );
};

const Table = ({ data, extra = [] }) => {
  const columns = [
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_salida',
      label: 'Fecha desde',
      options: {
        filter: true,
      },
    },
    {
      name: 'fecha_retorno',
      label: 'Fecha hasta',
      options: {
        filter: true,
      },
    },
    ...extra,
  ];

  const options = {
    filter: true,
    print: false,
    selectableRows: 'none',
    rowsPerPage: 15,
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return <MUIDataTable data={data} columns={columns} options={options} />;
};

const Modal = ({ children, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' maxWidth='md'>
      <DialogTitle id='form-dialog-title'>Resultado</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
