import styled from 'styled-components';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

export const StyledAppBar = styled(AppBar)`
    z-index: 10 !important;
    background-color: #424242 !important;
    /* background: none !important;
    box-shadow: none !important; */
    && .menu {
        color: white;
        background: rgba(255,255,255,.1);
        margin-right: 2rem;
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
        transition: 0.25s ease;
    }
    && .menu:hover {
        box-shadow: 0 5px 10px 5px rgba(0,0,0,0.1);
    }
`;

export const StyledToolbar = styled(Toolbar)`
  padding-right: 1rem !important;
  && .margin {
    margin: 0;
  }
  && .margin:hover {
    background: none !important;
  }
`;

export const StyledTypography = styled(Typography)`
  flex-grow: 1;
  color: white !important;
  font-size: 1.5rem !important;
  font-weight: 300 !important;
`;
