import React from 'react';

import {
    StyledAvatar,
} from './styles'

export const MyAvatar = ({ title, source, size, margin = 0}) => {

    return (

        <StyledAvatar alt={title} src={source} size={size} margin={margin} />

    )

}