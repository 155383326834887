import React, { createContext, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const UserContext = createContext();

const Provider = ({ children }) => {
  const auth = useLocalStorage('token');
  const user = useLocalStorage('user');
  const [role, setRole] = useState([]);
  // const BASE_URL = 'http://localhost:8000';
   const BASE_URL = 'https://api.ugel05.gob.pe';
  //const BASE_URL = 'http://54.157.172.191:8081';
  // const BASE_URL = 'https://cffc0e123d31.ngrok.io';  

  const value = {
    BASE_URL,
    auth,
    user,
    role,
    setRole,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default {
  Provider,
  Consumer: UserContext.Consumer,
};
