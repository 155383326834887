import styled from 'styled-components'
import {
    Paper,
} from '@material-ui/core'

export const StyledPaper = styled(Paper)`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    && .margin {
        margin-left: 2rem;
    } 
`