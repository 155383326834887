import React, { useState, Fragment, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import { IconButton, Tooltip, Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { MUITable } from '../../../components/MUITable';
import { useFetch } from '../../../hooks/useFetch';
import { UserContext } from '../../../contexts/UserContext';

export const DeclaracionJuradaMain = () => {
  const source = '/declaraciones-juradas';
  const { loading, getData } = useFetch();
  const [dataTable, setDataTable] = useState([]);
  const { BASE_URL } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const res = await getData({ source: source });
      res.status === 200 && setDataTable(res.data);
    })();
  }, [getData]);

  const actions = [
    {
      name: 'Acciones',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[1];
          return (
            <Fragment key={id}>
              <Tooltip title='Editar'>
                <IconButton
                  onClick={() => {
                    navigate(`${source}/${id}/edit`);
                  }}
                >
                  <EditIcon color={'primary'} />
                </IconButton>
              </Tooltip>
              {(tableMeta.rowData[11] === 'APROBADO' || tableMeta.rowData[11] === 'CERRADO' || document !== 'desplazamiento') && (
                <Fab
                  variant='extended'
                  size='small'
                  color='secondary'
                  onClick={async () => {
                    const response = await getData({ source: `${source}/${id}/pdf`, bg: 'true' });
                    if (response.status === 200) {
                      window.open(`${BASE_URL}/storage/pdf/declaracion_jurada.pdf`);
                    }
                  }}
                >
                  <PictureAsPdfIcon />
                  DJ
                </Fab>
              )}
            </Fragment>
          );
        },
      },
    },
  ];

  const columns = [
    ...actions,
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'numero',
      label: 'Numero',
      options: {
        filter: true,
      },
    },
    {
      name: 'empleado',
      label: 'Empleado',
      options: {
        filter: true,
      },
    },
    {
      name: 'monto',
      label: 'Monto',
      options: {
        filter: true,
      },
    },
    {
      name: 'motivo_comision',
      label: 'Motivo de Comision',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <MUITable
    title='Declaracion jurada'
      source={source}
      columns={columns}
      data={dataTable}
      loading={loading}
    />
  );
};
