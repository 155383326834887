import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Grid, IconButton, Fab, Tooltip } from '@material-ui/core';
import { navigate } from '@reach/router';
import MUIDataTable from 'mui-datatables';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import BackspaceIcon from '@material-ui/icons/Backspace';


import { CustomToolbar } from './CustomToolbar';
import { CustomToolbarSelect } from './CustomToolbarSelect';
import { Loading } from '../Loading';
import { useFetch } from '../../hooks/useFetch';
import { useDialog } from '../../hooks/useDialog';
import { UserContext } from '../../contexts/UserContext';
import swal from 'sweetalert';

export const MyDataTable = ({
  title,
  source,
  customColumns,
  canDelete = false,
  canNull = false,
  canRegister = true,
  localData = false,
  pdf = false,
  document = '',
  canApproval = false,
  canRefuse = false,
  data = undefined,
  showModal = false,
  handleModal,
  complementSource = '',
  dataAsignacion = undefined,
  selectableRows = 'none',
  multipleActions = false,
}) => {
  const { loading, getData } = useFetch();
  const [dataTable, setDataTable] = useState([]);
  const { confirm, error } = useDialog();
  const { BASE_URL } = useContext(UserContext);
  const { complement, setComplement } = useState(complementSource)
  const handleTableChange = (action, tableState) => {
  };

  const handleClick = () => {
    if (dataAsignacion) {
      navigate(`${source}/${dataAsignacion}/register`);
    } else {
      navigate(`${source}/register`);
    }
  };
  const handleClickAction = async (action, selected) => {
    if (selected.data.length > 0) {
      let validated = true
      selected.data.map((selection) => {
        if (dataTable[selection.dataIndex].estado !== 'PENDIENTE') {
          validated = false
        }
      })
      if (validated) {
        let updates = []
        let msg = ''
        if (action == 1) {
          msg = '¿Estás seguro de APROBAR esta(s) solicitud(es)?'
        } else {
          msg = '¿Estás seguro de RECHAZAR esta(s) solicitud(es)?'
        }
        confirm({ title: 'Mensaje', message: msg }).then(async result => {
          if (result) {
            if (selected.data.length > 0) {
              selected.data.map((selection) => {
                updates.push(dataTable[selection.dataIndex].id)
              })
            }
            if (updates.length > 0) {
              const body = {
                ids: updates,
                estado: action,
              }
              let responseClock = await getData({
                source: "/solicitudes/multi-update",
                body: body,
                json: true,
                method: "PUT",
              });
              if (responseClock.status === 200) {
                let message = "Se han actualizado los registros!";
                await swal(message, { icon: "success" });
                if (responseClock.status === 200) {
                  let newState
                  if (action === 1) {
                    newState = 'APROBADO'
                  } else {
                    newState = 'RECHAZADO'
                  }
                  const tmp = [...dataTable];
                  selected.data.map((item) => {
                    tmp[item.index].estado = newState
                  })
                  setDataTable(tmp);
                }
              } else {
                await ({ title: 'Por favor Revise', message: 'Hay un error no se puede actualizar' })
              }
            }
          }
        })

      } else {
        await ({ title: 'Por favor Revise', message: 'Todas las selecciones deben estar PENDIENTES' })
      }
    }

  };


  const actions = [
    {
      name: 'Acciones',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const id = tableMeta.rowData[1];
          return (
            <Fragment key={id}>
              <Tooltip title='Editar'>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (dataAsignacion) {
                      navigate(`${source}/${dataAsignacion}/${id}/edit`);
                    } else {
                      navigate(`${source}/${id}/edit`);
                    };
                    // navigate(`${source}/${id}/edit`);
                  }}
                >
                  <EditIcon color={'primary'} />
                </IconButton>
              </Tooltip>
              {canDelete && tableMeta.rowData[16] === 'PENDIENTE' && (
                <Tooltip title='Eliminar'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      confirm({ title: 'Mensaje', message: '¿Estas seguro de querer eliminar este registro?' }).then(async result => {
                        if (result) {
                          const response = await getData({ source: `${source}/${id}`, method: 'DELETE', bg: 'true' });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp.splice(index, 1);
                            // tmp.shift();
                            setDataTable(tmp);
                          }
                        }
                      });
                    }}
                  >
                    <DeleteIcon color={'secondary'} />
                  </IconButton>
                </Tooltip>
              )}
              {canNull && tableMeta.rowData[16] === 'APROBADO' && (
                <Tooltip title='Anular'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      confirm({ title: 'Mensaje', message: '¿Estas seguro de querer ANULAR esta papeleta?' }).then(async result => {
                        if (result) {
                          const response = await getData({ source: `${source}/anular/${id}`, method: 'get', bg: 'true' });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp.splice(index, 1);
                            // tmp.shift();
                            setDataTable(tmp);
                          }
                        }
                      });
                    }}
                  >
                    <BackspaceIcon color={'error'} />
                  </IconButton>
                </Tooltip>
              )}
              {showModal && (
                <Tooltip title='Detalle'>
                  <IconButton
                    size="small"
                    onClick={() => handleModal(dataTable.filter(d => d.id === id)[0], "Data")}>
                    <VisibilityIcon style={{ color: '#ff6d00' }} />
                  </IconButton>
                </Tooltip>
              )}
              {showModal && tableMeta.rowData[6] == 'TAREAS' && (
                <Tooltip title='Acciones'>
                  <IconButton
                    size="small"
                    onClick={() => handleModal(dataTable.filter(d => d.id === id)[0], "Acciones")}>
                    <FormatListNumberedIcon style={{ color: '#1c7af6' }} />
                  </IconButton>
                </Tooltip>
              )}
              {pdf && (tableMeta.rowData[16] === 'APROBADO' || tableMeta.rowData[16] === 'CERRADO' || document !== 'desplazamiento') && (
                <Tooltip title="Papeleta pdf" aria-label="Descargar">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const response = await getData({ source: `${source}/${id}/pdf`, bg: 'true' });
                      if (response.status === 200) {
                        window.open(`${BASE_URL}/storage/pdf/${document}.pdf`);
                      }
                    }}
                  >
                    <PictureAsPdfIcon color='secondary' />
                  </IconButton>
                </Tooltip>
              )}
              {pdf && tableMeta.rowData[15] != null && (tableMeta.rowData[16] === 'CERRADO' || tableMeta.rowData[16] === 'APROBADO') && (
                <Tooltip title="Descargar Pase" aria-label="Descargar">
                  <IconButton
                    size="small"
                  // onClick={async () => {
                  //   window.open(tableMeta.rowData[15]);
                  // }}
                  >
                    <a href={tableMeta.rowData[15]} target="_blank" download="Pase de Autorización">
                      <GetAppIcon color='primary' /></a>
                  </IconButton>
                </Tooltip>

              )}
              {canApproval && dataTable[index].estado === 'PENDIENTE' && (
                <Tooltip title='Aprobar'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      confirm({ title: 'Mensaje', message: '¿Estas seguro de querer aprobar esta solicitud?' }).then(async result => {
                        if (result) {
                          const response = await getData({
                            source: '/solicitudes/multi-update',
                            body: { ids: [id], estado: 1 },
                            json: true,
                            method: 'PUT',
                            bg: 'true',
                          });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp[index].estado = 'APROBADO';
                            console.log(tmp[index])
                            setDataTable(tmp);
                          }
                        }
                      });
                    }}
                  >
                    <DoneOutlineIcon style={{ color: '#00e676' }} />
                  </IconButton>
                </Tooltip>
              )}
              {canRefuse && dataTable[index].estado === 'PENDIENTE' && (
                <Tooltip title='Rechazar'>
                  <IconButton
                    size="small"
                    onClick={() => {
                      confirm({ title: 'Mensaje', message: '¿Estas seguro de querer rechazar esta solicitud?' }).then(async result => {
                        if (result) {
                          const response = await getData({
                            source: '/solicitudes/multi-update',
                            body: { ids: [id], estado: 2 },
                            json: true,
                            method: 'PUT',
                            bg: 'true',
                          });
                          if (response.status === 200) {
                            const tmp = [...dataTable];
                            tmp[index].estado = 'RECHAZADO';
                            // tmp.shift();
                            setDataTable(tmp);
                            // window.location.reload(true)
                          }
                        }
                      });
                    }}
                  >
                    <NotInterestedIcon style={{ color: '#ff3d00' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          );
        },
      },
    },
  ];

  const labels = {
    body: {
      noMatch: "Lo sentimos, no se encontraron registros",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Sig. Pág.",
      previous: "Ant. Pág.",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver Columnas",
      filterTable: "Filtrar Tabla",
    },
    filter: {
      all: "Todos",
      title: "Filtrados",
      reset: "Reset",
    },
    viewColumns: {
      title: "Mostrar Columnas",
      titleAria: "Mostrar/Ocultar Columnas",
    },
    selectedRows: {
      text: "Fila(s) seleccionadas",
      delete: "Borrar",
      deleteAria: "Borrar seleccionadas",
    },
  }


  const columns = [...actions, ...customColumns];
  const options = {
    selectableRows: selectableRows,
    filter: false,
    print: false,
    filterType: 'checkbox',
    rowsPerPage: 15,
    responsive: 'scrollFullHeight',
    onTableChange: handleTableChange,
    customToolbar: () => canRegister ? <CustomToolbar tooltip='Registrar' handleClick={handleClick} /> : <React.Fragment></React.Fragment>,
    customToolbarSelect: multipleActions ? (allRowsSelected) => <CustomToolbarSelect handleClickAction={(action) => handleClickAction(action, allRowsSelected)} /> :
      <React.Fragment></React.Fragment>,
    onRowsSelect: (allRowsSelected) => { console.log('Seleccionadas', allRowsSelected) },
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      textLabels: labels
    },
    onChangePage: (currentPage) => { console.log('cambio la página', currentPage) }
  };


  useEffect(() => {
    if (localData) {
      if (data) { setDataTable(data) }
    }
    else {
      (async function () {
        const response = await getData({ source: source + complementSource });
        if (response.status === 200) {
          setDataTable(response.data)
        };
      })();

    };
  }, [data, getData, source]);

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }



  return (
    <Grid item xs={12}>
      <MUIDataTable title={title} data={dataTable} columns={columns} options={options} />
    </Grid>
  );
};
