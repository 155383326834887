import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Avatar, Typography } from '@material-ui/core'

import { MyDivider } from '../MyDivider'

import {
    StyledPaper,
} from './styles'

export const MyPaper = ({children, title}) => {

    return (

        <StyledPaper>

            <div className="title-container">

                <Avatar variant="rounded" className="avatar">
                    <AssignmentIcon />
                </Avatar>

                <Typography className="title">{title}</Typography>

            </div>

            <MyDivider />

            {children}

        </StyledPaper>

    )

}