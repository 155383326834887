import styled from 'styled-components'

export const StyledInput = styled.input`
    border: 1px solid rgba(0,0,0,.25);
    color: rgba(0,0,0,.5);
    font-size: 18px;
    margin-top: 17px;
    padding: 10px;
    width: 100%;
    border-radius: 3px;
`