import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import swal from "sweetalert";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import { navigate } from "@reach/router";

import { StyledTable, StyledFile } from "./styles";

import { MyPaper } from "../../../components/MyPaper";
import { MyDatePicker, MyTimePicker } from "../../../components/MyDatePicker";
import { MyInput } from "../../../components/MyInput";
import { MySelect } from "../../../components/MySelect";
import { MyAutocomplete } from "../../../components/MyAutocomplete";
import { NuevasTareasMain } from "../../NuevasTareas/Main"

import { useInputValue } from "../../../hooks/useInputValue";
import { useFetch } from "../../../hooks/useFetch";

import { UserContext } from "../../../contexts/UserContext";
import { MyAutocompleteMultiple } from "../../../components/MyAutocompleteMultiple";

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';


function getModalStyle() {

  return {
    top: `${15}%`,
    left: `${15}%`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: `70%`,
    heigth: `70%`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const useInitial = (id, getData) => {
  const [title, setTitle] = useState("Crear papeleta");
  const [desplazamiento, setDesplazamiento] = useState(undefined);
  const [empleados, setEmpleados] = useState([]);
  const [motivosSalida, setMotivosSalida] = useState([]);
  const [asuntos, setAsuntos] = useState([]);
  const [motivosComision, setMotivosComision] = useState([]);
  const [institucionesEducativas, setInstitucionesEducativas] = useState([]);
  const [poll, setPoll] = useState([])

  useEffect(() => {
    id && setTitle("Editar papeleta");
    (async function () {
      const [selects, _desplazamiento, _poll] = await Promise.all([
        getData({ source: "/desplazamientos/selects", bg: true }),
        id && getData({ source: `/desplazamientos/${id}/show` }),
        getData({ source: "/preguntas" })
      ]);
      if (selects.status === 200) {
        setEmpleados(selects.data.empleados);
        setMotivosSalida(selects.data.motivosSalida);
        setAsuntos(selects.data.asuntos);
        setMotivosComision(selects.data.motivosComision);
        setInstitucionesEducativas(selects.data.institucionesEducativas);
      }
      id &&
        _desplazamiento.status === 200 &&
        setDesplazamiento(_desplazamiento.data);
      if (_poll.status === 200) {
        let temp = []
        _poll.data.map((item, index) => {
          item = {
            ...item,
            key: index,
            points: 0,
            value: false,
          };
          temp.push(item);
        })
        setPoll(temp)
      }
    })();
  }, [id, getData]);
  return {
    title,
    desplazamiento,
    empleados,
    motivosSalida,
    asuntos,
    motivosComision,
    institucionesEducativas,
    poll,
    setPoll
  };
};

///////////////////

const MyDialog = ({ open, handleClose, title, children }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const useDialog = () => {
  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const props = {
    open,
    handleClose
  };
  return [openDialog, props];
};

//////////////////



export const DesplazamientoRegister = ({ id }) => {
  const [vacaciones, setVacaciones] = useState('NO');
  const [enRiesgo, setEnRiesgo] = useState('No');
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openRecord, setOpenRecord] = React.useState(false);
  const [editable, setEditable] = useState(0);
  const { loading, getData } = useFetch();
  const [error, setError] = useState(undefined);
  const { user } = useContext(UserContext);
  const [openDialog, dialogProps] = useDialog();

  const {
    title,
    desplazamiento,
    empleados,
    motivosSalida,
    asuntos,
    motivosComision,
    institucionesEducativas,
    poll,
    setPoll
  } = useInitial(id, getData);
  // Controladores para los campos de formulario
  const [empleado, setEmpleado] = useState('');
  const [_asuntos, setAsuntos] = useState(asuntos);
  const [_motivosComision, setMotivosComision] = useState([]);
  const [institucionEducativa, setInstitucionEducativa] = useState([]);
  const [dias, setDias] = useState(null);
  const [rango, setRango] = useState(null);
  const [justificativo, setJustificativo] = useState(null);
  const [previewJ, setPreviewJ] = useState(null);
  const _empleado = useInputValue("");
  const cargo = useInputValue("");
  const area = useInputValue("");
  const motivoSalida = useInputValue("");
  const asunto = useInputValue("");
  const motivoComision = useInputValue("");
  const fechaSalida = useInputValue(null);
  const horaSalida = useInputValue(null);
  const fechaRetorno = useInputValue(null);
  const horaRetorno = useInputValue(null);
  const detalleMotivo = useInputValue("");
  const fechaReserva = useInputValue(null);
  const fechaSolicitud = useInputValue(null);
  const [ficha_sintomatologica, setSintomatologica] = useState(null);
  const [declaracion_jurada_file, setDeclaracion] = useState(null);
  const [modeTask, setModeTask] = useState(false)
  const [previewFS, setPreviewFS] = useState(null);
  const [previewDoc, setPreviewDoc] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const fechaExamen = useInputValue(null);
  const [examenCovid, setExamenCovid] = useState(false);
  const [fichaSintomatologicaValor, setFichaSintomatologicaValor] = useState(0)
  const [approved, setApproved] = useState(false)
  // Handles
  useEffect(() => {
    // setAsuntos(asuntos);
    // console.log(asuntos);
    if (desplazamiento) {
      if (Number(desplazamiento.motivo_salida) === 7) {
        setModeTask(true)
      }
      if (user.value.empleado) {
        setEditable(desplazamiento.estado);
      }
      let tmp = [];
      tmp = empleados.filter((item) => item.value === desplazamiento.empleado);
      setEmpleado(tmp[0]);
      let IE = []
      if (desplazamiento.asunto === 28 && desplazamiento.instituciones_educativas_array_count > 0) {
        desplazamiento.instituciones_educativas_array.map((item) => {
          IE.push({ value: item.id, label: item.nombre })
        })
      }
      desplazamiento.asunto === 28 ? setInstitucionEducativa(IE) : setInstitucionEducativa([]);
      motivoSalida.setValue(desplazamiento.motivo_salida);
      asunto.setValue(desplazamiento.asunto);
      motivoComision.setValue(desplazamiento.motivo_comision || "");
      detalleMotivo.setValue(desplazamiento.detalle_motivo || "");
      const fs = moment(desplazamiento.fecha_salida);
      fechaSalida.setValue(fs);
      fechaSolicitud.setValue(fs)
      const hs =
        desplazamiento.hora_salida &&
        moment(desplazamiento.hora_salida, "H:mm:ss");
      horaSalida.setValue(hs);
      const fr =
        desplazamiento.fecha_retorno && moment(desplazamiento.fecha_retorno);
      fechaRetorno.setValue(fr);
      const hr =
        desplazamiento.hora_retorno &&
        moment(desplazamiento.hora_retorno, "H:mm:ss");
      horaRetorno.setValue(hr);
      if (Number(desplazamiento.motivo_salida) === 3) {
        calcular(fs, fr);
      }
      if (Number(desplazamiento.motivoSalida === 8) && Number(desplazamiento.asunto === 36)) {
        const fres = moment(desplazamiento.fecha_reserva);
        fechaReserva.setValue(fres);
      }


      if (desplazamiento.justificativo) {
        setPreviewJ(
          `data:application/pdf;base64,${desplazamiento.justificativo}`
        );
      }
      if (desplazamiento.ficha_sintomatologica_url
      ) {

        setFichaSintomatologicaValor(desplazamiento.ficha_sintomatologica_url)
        setApproved(desplazamiento.ficha_sintomatologica_approved == 1 ? true : false)
        // console.log(desplazamiento.ficha_sintomatologica_approved)
        // console.log(approved)
        setPreviewFS(
          `data:application/pdf;base64,${desplazamiento.ficha_sintomatologica_url}`
        );
      }
      if (desplazamiento.declaracion_jurada_file) {
        setPreviewDoc(
          `data:application/pdf;base64,${desplazamiento.declaracion_jurada_file}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desplazamiento]);

  useEffect(() => {
    if (user.value.empleado && empleados.length) {
      const tmp = empleados.filter(
        (item) => item.value === user.value.empleado
      );
      _empleado.setValue(tmp[0].label);
      setEmpleado(tmp[0]);
      // console.log(tmp[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleados]);

  useEffect(() => {
    if (empleado) {
      // console.log(empleado)
      cargo.setValue(empleado.cargo);
      area.setValue(empleado.area_equipo);
      getDias(empleado.value);
      setVacaciones(empleado.vacaciones)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleado]);

  useEffect(() => {
    if (motivoSalida.value) {
      const tmp = asuntos.filter(
        (item) => Number(motivoSalida.value) === item.motivo_salida
      );
      setAsuntos(tmp);
      if (empleado && Number(motivoSalida.value) === 3) {
        getDias(empleado.value);
      }
    } else {
      setAsuntos([]);
    }
  }, [motivoSalida.value]);

  useEffect(() => {
    if (asunto.value) {
      // console.log(asunto)
      if (Number(asunto.value) >= 28 && Number(asunto.value) <= 33) {
        const tmp = motivosComision.filter(
          (item) => Number(asunto.value) === item.asunto
        );
        setMotivosComision(tmp);
      }
      if (dias && fechaSalida.value && fechaRetorno) {
        if (Number(asunto.value) === 22) vacacionesNormales(fechaRetorno);
        if (Number(asunto.value) === 24) vacacionesFraccionadas(fechaRetorno);
      }
    } else {
      setMotivosComision([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asunto.value]);

  /**
   * Control de fechas
   */
  useEffect(() => {
    if (fechaSalida.value) {
      fechaSalida.setError("");
      if (fechaRetorno.value) {
        const start = moment(fechaSalida.value);
        const end = moment(fechaRetorno.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaSalida.setError(
            "La fecha salida no puede ser mayor que la de retorno"
          );
          fechaSalida.setValue(null);
        } else if (dias && Number(asunto.value) === 24)
          vacacionesFraccionadas(fechaRetorno);
        else if (dias && Number(asunto.value) === 22)
          vacacionesNormales(fechaRetorno);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaSalida.value]);

  useEffect(() => {
    if (fechaRetorno.value) {
      fechaRetorno.setError("");
      if (fechaSalida.value) {
        const start = moment(fechaSalida.value);
        const end = moment(fechaRetorno.value);
        const diff = end.diff(start, "minutes");
        if (diff < 0) {
          fechaRetorno.setError(
            "La fecha retorno no puede ser menor que la de salida"
          );
          fechaRetorno.setValue(null);
        } else if (dias && Number(asunto.value) === 24)
          vacacionesFraccionadas(fechaRetorno);
        else if (dias && Number(asunto.value) === 22)
          vacacionesNormales(fechaRetorno);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaRetorno.value]);




  const vacacionesFraccionadas = (fecha) => {
    const start = moment(moment(fechaSalida.value).format("YYYY-MM-DD"));
    let end = moment(fechaRetorno.value);
    if (end.isoWeekday() === 5) {
      end = end.add(2, "days");
      fechaRetorno.setValue(end);
    }
    end = moment(end.format("YYYY-MM-DD"));
    const diff = end.diff(start, "days") + 1;
    if (dias.fraccionados === 0) {
      fecha.setError("No posee dias fraccionados");
      fecha.setValue(null);
    } else if (diff > dias.fraccionados) {
      fecha.setError("No puede exceder los dias fraccionados disponibles");
      fecha.setValue(null);
    } else if (diff > 4 && start.isoWeekday() === 1) {
      fecha.setError("No puede solicitar mas de 4 dias habiles");
      fecha.setValue(null);
    } else {
      calcular(fechaSalida.value, fechaRetorno.value);
    }
  };

  const vacacionesNormales = (fecha) => {
    const start = moment(moment(fechaSalida.value).format("YYYY-MM-DD"));
    let end = moment(fechaRetorno.value);
    if (end.isoWeekday() === 5) {
      end = end.add(2, "days");
      fechaRetorno.setValue(end);
    }
    end = moment(end.format("YYYY-MM-DD"));
    const diff = end.diff(start, "days") + 1;
    if (dias.habiles + dias.noHabiles === 0) {
      fecha.setError("No posee dias de vacaciones");
      fecha.setValue(null);
    } else if (diff > dias.habiles + dias.noHabiles) {
      fecha.setError("No puede exceder los dias de vacaciones disponibles");
      fecha.setValue(null);
    } else {
      setRango({
        dias: diff,
        desde: start.format("DD-MM-Y"),
        hasta:
          end.isoWeekday() === 5
            ? end.add(2, "days").format("DD-MM-Y")
            : end.format("DD-MM-Y"),
      });
    }
  };

  const calcular = (salida, retorno) => {
    const start = moment(moment(salida).format("YYYY-MM-DD"));
    let end = moment(retorno);
    if (end.isoWeekday() === 5) {
      end = end.add(2, "days");
      fechaRetorno.setValue(end);
    }
    end = moment(end.format("YYYY-MM-DD"));
    const diff = end.diff(start, "days") + 1;
    setRango({
      dias: diff,
      desde: start.format("DD-MM-Y"),
      hasta:
        end.isoWeekday() === 5
          ? end.add(2, "days").format("DD-MM-Y")
          : end.format("DD-MM-Y"),
    });
  };

  const getDias = (empleado) => {
    (async () => {
      const response = await getData({
        source: `/desplazamientos/vacaciones-disponibles/${empleado}`,
        bg: true,
      });
      response.status === 200 && setDias(response.data);
    })();
  };

  const fileToBlob = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve(null);
    });
  };

  //
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenRecord = async () => {
    setOpenRecord(true);
  };

  const handleCloseRecord = () => {
    setOpenRecord(false);
  };

  const handleChangeRecord = e => {

    if (e) {
      const index = parseInt(e.target.id);
      let temp = poll;
      temp[index].value = !poll[index].value;
      setPoll(temp)
    }
  }
  const handleAcceptTerms = e => {
    let auxValue = !acceptTerms
    setAcceptTerms(auxValue);
    // console.log(auxValue, acceptTerms)
  }
  // Manejador de envio del formulario
  const handleSubmit = async (event) => {
    let pollValue = 0
    // console.log(declaracion_jurada_file)
    event.preventDefault();
    if (  Number(motivoSalida.value) === 8 
          && Number(asunto.value) === 36 
          && declaracion_jurada_file == null
          && empleado.en_riesgo_boolean != 'No') {
      swal("Tiene Comorbilidades Covid -  Debe subir la Declaración Jurada", { icon: "error" });
      console.log('Declaración Necesaria')
      return;
    }
    if (Number(motivoSalida.value) === 3) {
      let month = new Date(fechaSalida.value).getMonth()
      // console.log(month)
      if (month === 0) {
        swal("No puede asignar Vacaciones en Enero", { icon: "error" });
        return;
      }
    }
    if (vacaciones === 'SI') {
      swal("Ud se encuentra de vacaciones, no puede generar solicitudes", { icon: "error" });
      navigate("/desplazamientos");
    } else {
      if (fechaSolicitud.value && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36) {

        if (acceptTerms) {
          poll.map((question) => {
            if (question.value) {
              pollValue = pollValue + question.valor
              // console.log(pollValue)
            }
          })
          // console.log(acceptTerms, pollValue,)
          setFichaSintomatologicaValor(pollValue)
          setApproved(pollValue < poll[0].valor ? true : false)
        } else {
          swal("Debe Validar el pase llenando Ficha Sintomatológica y ACEPTAR explícitamente los términos de la misma para poder hacer la solicitud", { icon: "error" });
          return;
        }
      }
      if (
        !empleado.value ||
        !motivoSalida.value ||
        !asunto.value ||
        (detalleMotivo.value.length === 0) ||
        (!motivoComision.value && Number(motivoSalida.value) === 4) ||
        (institucionEducativa.length === 0 && Number(motivoSalida.value) === 4 && Number(asunto.value) === 28) ||
        (!fechaSalida.value && Number(motivoSalida.value) !== 8 && Number(motivoSalida.value) !== 7) ||
        (!horaSalida.value && Number(motivoSalida.value) !== 8 && Number(motivoSalida.value) !== 7) ||
        (!fechaSolicitud.value && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36) ||
        (!fechaReserva.value && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36) ||
        // (!fechaSalida.value && Number(motivoSalida.value) === 7 && Number(asunto.value) === 35) ||
        (!fechaRetorno.value && Number(motivoSalida.value) === 3)


      ) {
        swal("Debe llenar los campos requeridos.", { icon: "error" });
        return;
      }

      // console.log(fechaSalida, fechaReserva, fechaRetorno)
      let IE = []
      institucionEducativa.map((ie) => IE.push(ie.value))
      if (IE.length === 0) { id ? IE = [] : IE = null }
      const body = {
        empleado: empleado.value,
        motivoSalida: motivoSalida.value,
        asunto: asunto.value,
        motivoComision:
          Number(motivoSalida.value) === 4 ? motivoComision.value || null : null,

        institucionEducativa: IE,
        fechaSalida: fechaSalida.value ? moment(fechaSalida.value).format("YYYY-MM-DD") :
          moment(fechaSolicitud.value).format("YYYY-MM-DD"),
        horaSalida: horaSalida.value
          ? moment(horaSalida.value).format("H:mm")
          : null,
        fechaRetorno:
          Number(motivoSalida.value) === 3 && fechaRetorno.value
            ? moment(fechaRetorno.value).format("YYYY-MM-DD")
            : null,
        horaRetorno: null,
        // Number(motivoSalida.value) !== 4 && horaRetorno.value
        //   ? moment(horaRetorno.value).format("H:mm")
        //   : null,
        detalleMotivo: detalleMotivo.value,
        justificativo: justificativo ? await fileToBlob(justificativo) : null,
        //
        fecha_reserva: fechaReserva.value ? moment(fechaReserva.value).format("YYYY-MM-DD") : null,
        ficha_sintomatologica: ficha_sintomatologica ? await fileToBlob(ficha_sintomatologica) : null,
        declaracion_jurada_file: declaracion_jurada_file ? await fileToBlob(declaracion_jurada_file) : null,
        //

        ficha_sintomatologica_approved: pollValue < poll[0].valor ? true : false,
        ficha_sintomatologica_valor: pollValue

      };
      setError(undefined);
      let message = undefined;
      let response = undefined;
      if (id) {
        message = "Papeleta actualizada con exito.";
        response = await getData({
          source: `/desplazamientos/${id}`,
          body: body,
          json: true,
          method: "PUT",
        });
      } else {
        message = "Papeleta creada con exito.";
        response = await getData({
          source: "/desplazamientos",
          body: body,
          json: true,
          method: "POST",
        });
      }
      if (response.status === 200) {
        await swal(message, { icon: "success" });
        if (id) {
          window.history.back()
        } else {
          if (body.motivoSalida === "7") {
            let routerEdit = "/desplazamientos/" + response.data.id.toString() + "/edit"
            navigate(routerEdit);
          } else {
            navigate("/desplazamientos");
          }
        }
      } else {
        setError(response.message);
      }

    }

  };


  return (
    <Fragment>
      <MyPaper title={title}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container item spacing={2}>
            {!user.value.empleado && (
              <Grid item xs={12} md={4}>
                <MyAutocomplete
                  name="empleado"
                  label="Empleado"
                  data={empleado}
                  setData={setEmpleado}
                  options={empleados}
                  disabled={loading}
                />
              </Grid>
            )}

            {user.value.empleado && (
              <Grid item xs={12} md={4}>
                <MyInput
                  name="empleado"
                  label="Empleado"
                  controller={_empleado}
                  disabled={true}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <MyInput
                name="cargo"
                label="Cargo"
                controller={cargo}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <MyInput
                name="area"
                label="Unidad/Area/Equipo"
                controller={area}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MySelect
                name="motivoSalida"
                label="Motivo de Salida"
                controller={motivoSalida}
                required={true}
                disabled={loading}
                data={motivosSalida}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MySelect
                name="asunto"
                label="Asunto"
                controller={asunto}
                required={true}
                disabled={loading}
                data={_asuntos}
              />
            </Grid>

            {Number(motivoSalida.value) === 3 && <VacationTable dias={dias} />}

            {Number(motivoSalida.value) === 4 && (
              <Grid item xs={12} md={6}>
                <MySelect
                  name="motivoComision"
                  label="Motivo de Comision"
                  controller={motivoComision}
                  data={_motivosComision}
                  disabled={loading}
                />
              </Grid>
            )}

            {Number(motivoSalida.value) === 4 && Number(asunto.value) === 28 && (
              <Grid item xs={12} md={6}>
                <MyAutocompleteMultiple
                  name="institucionEducativa"
                  title="Instituciones Educativas"
                  label="Buscar..."
                  options={institucionesEducativas}
                  data={institucionEducativa}
                  setData={(data) => setInstitucionEducativa(data)}
                  disabled={loading}
                />
              </Grid>
            )}
            {Number(motivoSalida.value) !== 7 && (
              <Grid item xs={12}>
                <MyInput
                  name="detalleMotivo"
                  label="Detalle de Motivo"
                  toCase="upper"
                  required={true}
                  controller={detalleMotivo}
                  disabled={loading}
                  rows={5}
                />
              </Grid>
            )}
            {Number(motivoSalida.value) === 7 && (
              <Grid item xs={12}>
                <MyInput
                  name="detalleMotivo"
                  label="Detalle de Actividades"
                  toCase="upper"
                  required={true}
                  controller={detalleMotivo}
                  disabled={loading}
                  rows={5}
                />
              </Grid>
            )}

            {Number(motivoSalida.value) !== 8 && Number(motivoSalida.value) !== 7 && (
              <Grid item xs={12} md={3}>
                <MyDatePicker
                  name="fechaSalida"
                  label="Fecha de Salida"
                  controller={fechaSalida}
                  required={true}
                  disabled={loading}

                />
              </Grid>
            )}
            {
              // disablePast={user && !user.value.empleado ? false : false}
            }
            {Number(motivoSalida.value) !== 8 && Number(motivoSalida.value) !== 7 && (
              <Grid item xs={12} md={3}>
                <MyTimePicker
                  name="horaSalida"
                  label="Hora de Salida"
                  controller={horaSalida}
                  required={true}
                  disabled={loading}
                  fecha={fechaSalida.value}
                  disablePast={false}
                />
              </Grid>
            )}
            {
              //disablePast={!(user && !user.value.empleado)}
            }
            {/*********************************************************************/}
            {Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
              <Grid item xs={12} md={3}>
                <MyDatePicker
                  name="fechaSolicitud"
                  label="Fecha de Solicitud"
                  controller={fechaSolicitud}
                  required={true}
                  disabled={loading}
                  disablePast={user && !user.value.empleado ? false : true}
                />
              </Grid>
            )}
            {Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
              <Grid item xs={12} md={3}>
                <MyDatePicker
                  name="fechaReserva"
                  label="Fecha Reserva"
                  controller={fechaReserva}
                  required={true}
                  disabled={loading}
                  disablePast={user && !user.value.empleado ? false : true}
                />
              </Grid>
            )}
            {
              /* Number(motivoSalida.value) === 7 && Number(asunto.value) === 35 && (
               <Grid item xs={12} md={3}>
                 <MyDatePicker
                   name="fechaSalida"
                   label="Fecha de Inicio"
                   controller={fechaSalida}
                   required={true}
                   disabled={loading}
                 />
               </Grid>
             )*/
            }
            {//disablePast={user && !user.value.empleado ? false : true}
            }
            {Number(motivoSalida.value) === 3 && (
              <Grid item xs={12} md={3}>
                <MyDatePicker
                  name="fechaRetorno"
                  label="Fecha de Retorno"
                  controller={fechaRetorno}
                  disabled={loading}
                  disablePast={user && !user.value.empleado ? false : true}
                />
              </Grid>
            )
            }
            {
              // {Number(motivoSalida.value) === 7 && Number(asunto.value) === 35 && (
              //   <Grid item xs={12} md={3}>
              //     <MyDatePicker
              //       name="fechaRetorno"
              //       label="Fecha de Final"
              //       controller={fechaRetorno}
              //       disabled={loading}
              //       disablePast={user && !user.value.empleado ? false : true}
              //     />
              //   </Grid>
              // )}
              // {Number(motivoSalida.value) !== 4 && Number(motivoSalida.value) !== 8 && Number(motivoSalida.value) !== 7 && (
              //     <Grid item xs={12} md={3}>
              //       <MyTimePicker
              //         name="horaSalida"
              //         label="d:de Retorno"
              //         controller={horaRetorno}
              //         required={true}
              //         disabled={loading}
              //         fecha={fechaRetorno.value}
              //         disablePast={!(user && !user.value.empleado)}
              //       />
              //     </Grid>
              // )}
            }
            {Number(motivoSalida.value) === 3 && <RangeTable rango={rango} />}
            {Number(motivoSalida.value) !== 8 && (
              <Grid item xs={12} md={6}>
                <StyledFile>
                  <label>Documento</label>
                  <input
                    type="file"
                    onChange={async (e) => {
                      setJustificativo(e.target.files[0]);
                      // setPreviewJ(await fileToBlob(e.target.files[0]));
                    }}
                  />
                </StyledFile>
              </Grid>
            )}
            {
              //Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
              //   <Grid item xs={12} md={6}>
              //     <StyledFile>
              //       <label>Ficha Sintomatológica</label>
              //       <input
              //         type="file"
              //         onChange={async (e) => {
              //           setSintomatologica(e.target.files[0]);
              //         }}
              //       />
              //     </StyledFile>
              //   </Grid>
              // )
            }

            {Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
              <Grid item xs={12} md={6}>
                <StyledFile>
                  <label>Declaración Jurada</label>
                  <input
                    type="file"
                    onChange={async (e) => {
                      setDeclaracion(e.target.files[0]);
                    }}
                  />
                </StyledFile>
              </Grid>
            )}

            {previewJ && Number(motivoSalida.value) !== 8 && (
              <Grid item xs={12}>
                <object width="100%" height="500" data={previewJ}>
                  <p>Error al mostrar el documento.</p>
                </object>
              </Grid>
            )}

            {previewFS && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && approved && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<GetAppIcon />}
              >
                <a href={desplazamiento.ficha_sintomatologica_url} target="_blank" download="Pase de Autorización">Descargar Pase</a>
              </Button>
              // <Grid item xs={12}>
              //   <object width="100%" height="500" data={previewFS}>
              //     <p>Error al mostrar el documento.</p>
              //   </object>
              // </Grid>
            )}
            {previewDoc && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
              <Grid item xs={12}>
                <object width="100%" height="500" data={previewDoc}>
                  <p>Error al mostrar el documento.</p>
                </object>
              </Grid>
            )}

            <Grid
              container
              item
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid
                container
                item
                justify="flex-start"
                alignItems="center"
                xs={12}
                md={6}
              >
                {loading && (
                  <Fragment>
                    <CircularProgress />
                    <Box ml={2}>
                      <Typography variant="h6" color="textSecondary">
                        Procesando...
                      </Typography>
                    </Box>
                  </Fragment>
                )}
                {error && (
                  <Typography variant="h6" color="error">
                    {error}
                  </Typography>
                )}
              </Grid>
              {!id && Number(motivoSalida.value) === 8 && Number(asunto.value) === 36 && (
                <Grid item xs={6} md={3}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    startIcon={<PlaylistAddCheckIcon />}
                    disabled={loading}
                    onClick={openDialog}
                  >
                    Validar Pase
                  </Button>
                </Grid>
              )}
              {modeTask && (
                <Grid item xs={6} md={3}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={loading}
                    onClick={handleOpen}
                  >
                    Asignar Tareas
                </Button>
                </Grid>
              )}
              <Grid item xs={6} md={3}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={() => window.history.back()}
                >
                  Atras
                </Button>
              </Grid>

              <Grid item xs={6} md={3}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading || editable !== 0}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MyPaper>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <NuevasTareasMain
            asignacion={desplazamiento}
            empleado={empleado}
          ></NuevasTareasMain>
        </div>
      </Modal>


      <MyDialog {...dialogProps} title='Ficha de sintomatología COVID-19 Declaración Jurada (2 Locadores)'>
        <FormControl component="fieldset">
          <FormHelperText>He recibido explicación del objetivo de esta evaluación y me comprometo a responder con la verdad.</FormHelperText>
          <FormGroup>
            <FormLabel component="legend"> <h4>En los últimos 14 días calendario ha tenido alguno de los síntomas siguientes: </h4></FormLabel>
            {
              poll.map((question, index) => {
                if (question.pregunta != "APPROVED") {
                  return (
                    <FormControlLabel
                      control={<Switch
                        defaultChecked={question.value}
                        onChange={handleChangeRecord}
                        id={index.toString()}
                        name={index.toString()}
                      />}
                      labelPlacement="end"
                      label={question.pregunta}
                      key={index}
                    />)
                }
              })
            }
            <FormControlLabel
              control={<Switch
                checked={examenCovid}
                onChange={(e) => { setExamenCovid(e.target.checked) }} />}
              labelPlacement="end"
              label='Se ha realizado alguna prueba para descartar COVID-19'

            />
            <FormControlLabel
              control={<MyDatePicker
                name="fechaExamen"
                label="Fecha de la prueba..."
                controller={fechaExamen}
                required={true}
                disabled={!examenCovid}
              ></MyDatePicker>}
              labelPlacement="top"
              label='Indicar la fecha en la que se realizó la prueba para descartar COVID-19, de lo contrario indicar NINGUNA
                Escriba su respuesta'

            />
            <Grid>
              <FormHelperText> Todos los datos expresados en esta ficha constituyen declaración
              jurada de mi parte, sujetos al principio de veracidad de información
              y tengo conocimiento que en caso de omitir o falsear información puedo
              perjudicar la salud de mis compañeros y la mía propia, lo cual,
              constituye una falta grave a la salud pública, y asumo sus consecuencias.
              </FormHelperText>
              <FormControlLabel
                control={<Switch
                  checked={acceptTerms}
                  onChange={(e) => { setAcceptTerms(e.target.checked) }} />}
                labelPlacement="end"
                label='Acepto'
              />
            </Grid>

          </FormGroup>
          <FormHelperText>Debe aceptar los términos y condiciones para validar su Solicitud</FormHelperText>
        </FormControl>
      </MyDialog>
    </Fragment>
  );
};

const VacationTable = ({ dias }) => {
  return (
    <Grid item xs={12}>
      <StyledTable>
        <thead>
          <tr>
            <th colSpan={3}>DIAS DISPONIBLES</th>
          </tr>
          <tr>
            <th width="33.3%">HABILES</th>
            <th>NO HABILES</th>
            <th width="33.3%">FRACCIONADOS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dias ? dias.habiles : 0}</td>
            <td>{dias ? dias.noHabiles : 0}</td>
            <td>{dias ? dias.fraccionados : 0}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Grid>
  );
};

const RangeTable = ({ rango }) => {
  return (
    <Grid item xs={12}>
      <StyledTable>
        <thead>
          <tr>
            <th colSpan={3}>RANGO</th>
          </tr>
          <tr>
            <th width="33.3%">DIAS</th>
            <th>DESDE</th>
            <th width="33.3%">HASTA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{rango ? rango.dias : 0}</td>
            <td>{rango ? rango.desde : 0}</td>
            <td>{rango ? rango.hasta : 0}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Grid>
  );
};

