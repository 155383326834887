import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import UserContext from './contexts/UserContext'
import LayoutContext from './contexts/LayoutContext'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <UserContext.Provider>
        <LayoutContext.Provider>
            <App />
        </LayoutContext.Provider>
    </UserContext.Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
