/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "padding-top": "15px",
    '& > * + *': {
      marginTop: theme.spacing(3),
      
    },
  },
}));
const handleChange = (selected, setData) => {
  console.log(selected)
  setData(selected)
  }

export const MyAutocompleteMultiple = ({ name,title, label,data, setData, options = [], disabled}) => {
  const classes = useStyles();
  

  return (
    <div className={classes.root}>
      <Autocomplete
        disabled={disabled}
        multiple
        id={name}
        options={options}
        getOptionLabel={(option) => option.label}
        defaultValue={data}
        onChange={(event, value)=>handleChange(value, setData)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={title}
            placeholder={label}
          />
        )}
      />      
    </div>
  );
}